import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DefaultLayoutComponent } from './layout/default-layout.component';
import { RoutingGuard } from './routing.guard';
import { AccessComponent } from './access/access.component';
import { RetailerComponent } from './access/retailer/retailer.component';
import { LayoutOppBuyComponent } from './layout-opp-buy/layout-opp-buy.component';
import { HelpComponent } from './help/help.component';
import { NewsletterComponent } from './newsletter/newsletter.component';
import { TradeShowComponent } from './tradeshow/tradeshow.component';

//

const routes: Routes = [
  {
    path: '',
    component: DefaultLayoutComponent,
    canActivateChild: [RoutingGuard],
    children: [
      { path: '', redirectTo: 'home', pathMatch: 'full' },
      {
        path: 'home',
        loadChildren: () =>
          import('./home/home.module').then((h) => h.HomeModule),
      },
      {
        path: 'customer/:id',
        loadChildren: () => import('./customer/customer.module').then((h) => h.CustomerModule),
      },
      {
        path: 'quickorder',
        loadChildren: () =>
          import('./quick-order/quick-order.module').then(
            (h) => h.QuickOrderModule
          ),
      },
      {
        path: 'catalog',
        loadChildren: () =>
          import('./catalog/catalog.module').then((h) => h.CatalogModule),
      },
      {
        path: 'catalog/:category',
        loadChildren: () =>
          import('./catalog/catalog.module').then((h) => h.CatalogModule),
      },
      {
        path: 'basket',
        loadChildren: () =>
          import('./basket/basket.module').then((h) => h.BasketModule),
      },
      {
        path: 'order/:id',
        loadChildren: () =>
          import('./basket/basket.module').then((h) => h.BasketModule),
      },
      {
        path: 'manage',
        loadChildren: () =>
          import('./manage/manage.module').then((h) => h.ManageModule),
      },
      {
        path: 'offers',
        loadChildren: () =>
          import('./offers/offers.module').then((h) => h.OffersModule),
        canLoad: [RoutingGuard],
      },
      {
        path: 'offers2',
        loadChildren: () =>
          import('./opp-buy/opp-buy.module').then((h) => h.OppBuyModule),
      },
      {
        path: 'showroom',
        loadChildren: () =>
          import('./virtualshowroom/virtualshowroom.module').then(
            (h) => h.VirtualshowroomModule
          ),
      },
      {
        path: 'tradeshow',
        component: TradeShowComponent,
        children: [{ path: '', component: TradeShowComponent }],
      },
    ],
  },
  {
    path: 'access',
    component: LayoutOppBuyComponent,
    children: [{ path: '', component: AccessComponent }],
  },
  {
    path: 'retailer',
    component: LayoutOppBuyComponent,
    children: [{ path: '', component: RetailerComponent }],
  },
  {
    path: 'help',
    component: LayoutOppBuyComponent,
    children: [{ path: '', component: HelpComponent }],
  },
  {
    path: 'newsletter',
    component: NewsletterComponent,
    children: [{ path: '', component: NewsletterComponent }],
  },
  // {
  //   path: '',
  //   component: DefaultLayoutComponent,
  //   children: [
  //     { path: '', redirectTo: 'customer/selectcustomer', pathMatch: 'full' },
  //     {
  //       path: 'customer/:id',
  //       loadChildren: () =>
  //         import('./customer/customer.module').then((h) => h.CustomerModule),
  //     },
  //   ],
  // },
  {
    path: 'OppBuy',
    component: LayoutOppBuyComponent,
    children: [
      { path: '', redirectTo: 'opp-buy', pathMatch: 'full' },
      {
        path: 'OppBuy',
        loadChildren: () =>
          import('./opp-buy/opp-buy.module').then((h) => h.OppBuyModule),
      },
    ],
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      onSameUrlNavigation: 'reload',
      scrollOffset: [0, 64], // [x, y],
    }),
  ],
  exports: [RouterModule],
  providers: [RoutingGuard],
})
export class AppRoutingModule {}
