import { Component, OnInit, OnDestroy } from '@angular/core';
import { SecurityService } from '../core/services/security.service';
import { ErrorService } from '../core/services/error.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { CatalogService } from 'src/app/catalog/services/catalog.service';
import { BasketService } from 'src/app/core/services/basket.service';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LocaleService } from 'src/app/core/services/locale.service';
import { IConfigurations } from '../core/models/settings.model';
import { ImageConfig } from '../model/api-config';
import { ApiConfigService } from '../core/services/api-config.service';

@Component({
  selector: 'app-access',
  templateUrl: './access.component.html',
  styleUrls: ['./access.component.scss']
})
export class AccessComponent implements OnInit, OnDestroy {
  @BlockUI() blockUI!: NgBlockUI;

  public timer:number=5;
  public pageView="";

  //Image slider
  public imagesUrl:string[] = [];
  public code!:String;
  //Slideshow
  // options = Immutable.Map({
  //   showDots: true,         // Shows a dot navigation component
  //   height: 450,            // The initial slideshow height
  //   showThumbnails: true,   // Optionally include thumbnails a navigation option
  //   thumbnailWidth: 150,     // Thumbnail individual width for the thumbnail navigation component
  //   items: 1,
  //   dots: false,
  //   navigation: false
  // });
  mainSlideOptions={
    items: 1,
    dots: false,
    navigation: false
  }
  subSlideOptions={
    items: 3,
    dots: false,
    navigation: false
  }

  footerBgCss = 'footer';
  destroy$ = new Subject();

  configurations:IConfigurations = {} as IConfigurations;
  bgImageUrl = "";

  constructor(private securityService: SecurityService,public catalogService:CatalogService,
    private error: ErrorService,public basketService:BasketService,public router:Router,
    public modalService:NgbModal,public localeService:LocaleService,
    private apiSvc: ApiConfigService) {

      this.configurations = this.localeService.GetConfigFromStore();
      this.bgImageUrl = this.apiSvc.apiConfig.image.highResUri + this.configurations.login.access.bgImg + ".jpg";
    }

  ngOnInit() {
    this.code=this.localeService.code;
    this.imagesUrl=[
    'assets/img/banner1.jpg',
    'assets/img/banner2.jpg',
    'assets/img/banner1.jpg',
    'assets/img/banner2.jpg',
    'assets/img/banner1.jpg',
    'assets/img/banner2.jpg',
  ];
    setInterval(()=>{
      if (this.timer==0) {
        if (this.pageView != 'retailer') {
          // this.blockUI.start('Redirecting to login ...');
          // this.securityService.startAuthentication();
        }
        setTimeout(() => {
        }, 2000);
      }
      else{
        this.timer--;
      }
    },1000);



  }

  onLogin() {
    this.securityService.startAuthentication();

  }

  async onRegister() {
    this.securityService.register().pipe(
      takeUntil(this.destroy$))
      .subscribe(data => window.location.href = data,
        error => this.error.handleErrorWithAlert(error));
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
  onRetailRequest(){
    this.router.navigate(['/retailer']);
  }
  onHome(){
    this.pageView='';
  }
  onOppBuy(){
    this.router.navigate(['/OppBuy']);
  }
}
