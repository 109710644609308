<div [formGroup]="myform">
  <mat-form-field>
      <mat-label>Contact Name</mat-label>
      <input matInput [formControl]="ReturnFormControl('contactName')">
      <mat-error> You must include contact Name.</mat-error>
  </mat-form-field>
  <!-- <div class="form-group">
    <label>Contact Name *</label>
    <div>
      <input class="form-control" type="text" [formControl]="myform.controls['contactName']" />
      <span *ngIf="myform.controls['contactName'].hasError('required')
                            && myform.controls['contactName'].touched"
        class="text-danger">
        You must include contact Name.
      </span>
    </div>
  </div> -->
  <mat-form-field>
      <mat-label>{{('retailer.TELEPHONE'|translate)}}</mat-label>
      <input matInput [formControl]="ReturnFormControl('primaryPhone')">
      <mat-error> You must include Primary Phone No.</mat-error>
  </mat-form-field>
  <!-- <div class="form-group">
    <label>Primary Phone *</label>
    <div>
      <input class="form-control" type="text" [formControl]="myform.controls['primaryPhone']" />
      <span *ngIf="myform.controls['primaryPhone'].hasError('required')
                              && myform.controls['primaryPhone'].touched"
        class="text-danger">
        You must include Primary Phone No.
      </span>
    </div>
  </div> -->
  <mat-form-field>
      <mat-label>{{('retailer.FAX'|translate)}}</mat-label>
      <input matInput [formControl]="ReturnFormControl('fax')">
      <mat-error>  You must include Fax.</mat-error>
  </mat-form-field>
  <!-- <div class="form-group">
    <label>Fax</label>
    <div>
      <input class="form-control" type="text" [formControl]="myform.controls['fax']" />
      <span *ngIf="myform.controls['fax'].hasError('required')
                                && myform.controls['fax'].touched"
        class="text-danger">
        You must include Fax.
      </span>
    </div>
  </div> -->
  <mat-form-field>
      <mat-label>{{('retailer.MOBILE'|translate)}}</mat-label>
      <input matInput [formControl]="ReturnFormControl('mobile')">
      <mat-error>You must include Mobile No.</mat-error>
  </mat-form-field>
  <!-- <div class="form-group">
    <label>Mobile</label>
    <div>
      <input class="form-control" type="text" [formControl]="myform.controls['mobile']" />
      <span *ngIf="myform.controls['mobile'].hasError('required')
                                  && myform.controls['mobile'].touched"
        class="text-danger">
        You must include Mobile No.
      </span>
    </div>
  </div> -->
  <mat-form-field>
      <mat-label>{{('retailer.MAIL'|translate)}}</mat-label>
      <input matInput [formControl]="ReturnFormControl('email')">
      <mat-error *ngIf="ReturnFormControl('email').hasError('required')">You must include Email.</mat-error>
      <mat-error *ngIf="ReturnFormControl('email').hasError('pattern')">Enter a Valid Email.</mat-error>
  </mat-form-field>
  <!-- <div class="form-group">
    <label>Email *</label>
    <div>
      <input class="form-control" type="text" [formControl]="myform.controls['email']" />
      <span *ngIf="myform.controls['email'].hasError('required')
                                    && myform.controls['email'].touched"
        class="text-danger">
        You must include Email.
      </span>
      <span *ngIf="myform.controls['email'].hasError('pattern')" class="text-danger">
        Enter a Valid Email.
      </span>
    </div>
  </div> -->
</div>
<app-validation-summary [form]="myform"></app-validation-summary>