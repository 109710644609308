

<div [formGroup]="myform">
    <mat-form-field>
      <mat-label>{{('retailer.ADDRESS'|translate)}}</mat-label>
      <input matInput [formControl]="ReturnFormControl('address1')">
      <mat-error> You must include {{('retailer.ADDRESS'|translate)}}</mat-error>
    </mat-form-field>
    <!-- <div class="form-group">
        <label>Address1 *</label>
        <div>
            <input class="form-control" type="text" [formControl]="myform.controls['address1']" />
            <span *ngIf="myform.controls['address1'].hasError('required')
                          && myform.controls['address1'].touched"
                   class="text-danger">
                You must include Address1.
            </span>
        </div>
    </div> -->
    <mat-form-field>
      <mat-label>{{('retailer.CITY'|translate)}}</mat-label>
      <input matInput [formControl]="ReturnFormControl('city')">
      <mat-error> You must include {{('retailer.CITY'|translate)}}</mat-error>
    </mat-form-field>
    <!-- <div class="form-group">
        <label>City</label>
        <div>
            <input class="form-control" type="text" [formControl]="myform.controls['city']" />
            <span *ngIf="myform.controls['city'].hasError('required')
                          && myform.controls['city'].touched"
                   class="text-danger">
                You must include City
            </span>
        </div>
    </div> -->
    <mat-form-field>
      <mat-label>{{('retailer.STATE'|translate)}}</mat-label>
      <input matInput [formControl]="ReturnFormControl('state')">
      <mat-error> You must include {{('retailer.STATE'|translate)}}</mat-error>
    </mat-form-field>
    <!-- <div class="form-group">
        <label>State *</label>
        <div>
          <input class="form-control" type="text" [formControl]="myform.controls['state']" />
          <span *ngIf="myform.controls['state'].hasError('required')
                        && myform.controls['state'].touched"
                 class="text-danger">
              You must include State
          </span>
        </div>
    </div> -->
    <mat-form-field>
      <mat-label>{{('retailer.ZIPCODE'|translate)}}</mat-label>
      <input matInput [formControl]="ReturnFormControl('zipCode')">
      <mat-error> You must include {{('retailer.ZIPCODE'|translate)}}</mat-error>
    </mat-form-field>
    <!-- <div class="form-group">
        <label>ZIP Code *</label>
        <div>
            <input class="form-control" type="text" [formControl]="myform.controls['zipCode']" />
            <span *ngIf="myform.controls['zipCode'].hasError('required')
                          && myform.controls['zipCode'].touched"
                   class="text-danger">
                You must include Zipcode
            </span>
        </div>
    </div> -->
   
  </div>
  <!-- <app-validation-summary [form]="myform"></app-validation-summary> -->
  


<!-- <div [formGroup]="myform">
    <div class="form-group">
        <label>Address *</label>
        <div>
            <input class="form-control" type="text" [formControl]="myform.controls['address']" />
            <span *ngIf="myform.controls['address'].hasError('required')
                        && myform.controls['address'].touched"
                class="text-danger">
                You must include Address.
            </span>
        </div>
    </div>
     <div class="form-group">
      <label>City</label>
      <div>
          <input class="form-control" type="text" [formControl]="myform.controls['city']" />
          <span *ngIf="myform.controls['city'].hasError('required')
                        && myform.controls['city'].touched"
                 class="text-danger">
              You must include City
          </span>
      </div>
  </div>
    <div class="form-group">
        <label>ZIP Code *</label>
        <div>
            <input class="form-control" type="text" [formControl]="myform.controls['zipCode']" />
            <span *ngIf="myform.controls['zipCode'].hasError('required')
                          && myform.controls['zipCode'].touched"
                class="text-danger">
                You must include ZIP Code
            </span>
        </div>
    </div>
    <div class="form-group">
        <label>State *</label>
        <div>
            <input class="form-control" type="text" [formControl]="myform.controls['state']" />
            <span *ngIf="myform.controls['state'].hasError('required')
                      && myform.controls['state'].touched"
                class="text-danger">
                You must include State
            </span>
        </div>
    </div>
    <div class="form-group">
        <label>Country</label>
        <div>
            <input class="form-control" type="text" [formControl]="myform.controls['country']" />
            <span *ngIf="myform.controls['country'].hasError('required')
                        && myform.controls['country'].touched"
                class="text-danger">
                You must include Country
            </span>
        </div>
    </div>
    <div class="form-group">
        <label>Primary Phone *</label>
        <div>
            <input type="text" class="form-control" [formControl]="myform.controls['primaryPhone']" />
            <span *ngIf="myform.controls['primaryPhone'].hasError('required') 
          && myform.controls['primaryPhone'].touched"
                class="text-danger">
                You must include Primary Phone No
            </span>
        </div>
    </div>
    <div class="form-group">
        <label>Fax</label>
        <div>
            <input type="text" class="form-control" [formControl]="myform.controls['fax']" />
            <span *ngIf="myform.controls['fax'].hasError('required') 
            && myform.controls['fax'].touched"
                class="text-danger">
                You must include Fax
            </span>
        </div>
    </div>
    <div class="form-group">
            <label>Mobile *</label>
            <div>
                <input type="text" class="form-control" [formControl]="myform.controls['mobile']"/>
                <span *ngIf="myform.controls['mobile'].hasError('required') 
                && myform.controls['mobile'].touched" 
                class="text-danger">
                You must include Mobile No
                </span>
            </div>
        </div>

</div>
<app-validation-summary [form]="myform"></app-validation-summary> --> 