import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormControl, Validators  } from '@angular/forms';
import {debounceTime,distinctUntilChanged, takeUntil} from'rxjs/operators';
import { Subject } from 'rxjs';

 
@Component({
  selector: 'app-numeric',
  templateUrl: './numeric.component.html',
  styleUrls: ['./numeric.component.scss']
})
export class NumericComponent implements OnInit, OnDestroy {
  destroy$ = new Subject();
  
  @Input() qty!:number;
  @Output() qtyChange: EventEmitter<number>;
  @Output() qtyBlur: EventEmitter<number>;
  quantity = new FormControl('',[
    Validators.required,
    Validators.min(1)
  ]); 
  
  
  
  constructor() {
    this.qtyChange = new EventEmitter<number>();    
    this.qtyBlur = new EventEmitter<number>();    
   }

  ngOnInit() {     
    this.quantity.valueChanges
    .pipe(debounceTime(500),
    distinctUntilChanged(),
    takeUntil(this.destroy$)) 
    .subscribe(value => {
      this.qtyChange.emit(value);
    });    
    
    this.quantity.setValue(this.qty);
  }
  onBlur()
  {
    this.qtyBlur.emit(this.qty);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
