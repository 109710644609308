import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiConfigService } from './api-config.service';
import { SecurityService } from './security.service';
import { ErrorService } from './error.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as _ from 'lodash';

@Injectable()
export class ImageDownloadService {

  //httpHeader: HttpHeaders;
  private subject = new BehaviorSubject<string[]>([]);
  //imageList$:Observable<string[]>=this.subject.asObservable();

  constructor(private http: HttpClient,
    private apiSvc: ApiConfigService,
    private securityService: SecurityService,
    private error: ErrorService) {

  }

  public clear() {
    this.subject.next([]);
  }

  public getImageListCount(): Observable<number> {
    return this.subject.pipe(map((items: string[]) => {
      return items.length;
    }));
  }


  public downloadImage(fileName:any) {

    if (fileName != null && fileName != ' ') {
      fileName = fileName + ".jpg";
      let url = this.apiSvc.formatUrl(this.apiSvc.apiConfig.download.downloadImageApi);
      //let url = this.apiSvc.apiConfig.image.highResUri+fileName;

      this.http.get(url.replace("$FILENAME", fileName), {
        headers: this.getHeader(),
        responseType: 'blob'
      })
        .subscribe((res: any) => {
          var link = document.createElement('a');
          link.href = window.URL.createObjectURL(res);
          link.download = fileName;
          link.click();
        },
        error => this.error.handleErrorWithAlert(error));

    }
  }


  public downloadImages() {
    let imageList = this.subject.getValue();
    debugger
    if (imageList != null && imageList.length > 0) {
      let url = this.apiSvc.formatUrl(this.apiSvc.apiConfig.download.downloadImagesApi);
      this.http.post(url, JSON.stringify(imageList), {
        headers: this.getHeader(),
        responseType: 'blob'
      })
        .subscribe((res: any) => {
          console.log(res);
          const blob = new Blob([res], { type: 'application/octet-stream' });
          var link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = "images.zip";
          link.click();
          this.subject.next([]);
        },
          error => this.error.handleErrorWithAlert(error));

    }
  }

  public addImageToDownloadList(fileName: string) {
    if (fileName != null && fileName != ' ') {
      fileName += ".jpg";
      let index = -1;
      let imageList:string[] = this.subject.getValue();
      let fileNameExist = imageList.find((p:string) => p === fileName);
      if (fileNameExist === undefined) {
        imageList.push(fileName);
        this.subject.next(imageList);
      }
    }
  }

  private getHeader(): HttpHeaders {
    return this.securityService.formatRequestHeader();

  }

}
