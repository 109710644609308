import { Component, OnInit, ElementRef } from '@angular/core';
import { Observable } from 'rxjs';
import { BasketService } from 'src/app/core/services/basket.service';
import { SecurityService } from 'src/app/core/services/security.service';
import { ErrorService } from 'src/app/core/services/error.service';
import { ImageDownloadService } from 'src/app/core/services/image-download.service';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { LocaleService } from 'src/app/core/services/locale.service';
import { OrderService } from 'src/app/core/services/order.service';
import { OffersService } from 'src/app/core/services/offers.service';
import { SettingsService } from 'src/app/core/services/settings.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalPopupComponent } from 'src/app/shared/component/modal-popup/modal-popup.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AlertComponent } from 'src/app/shared/component/alert/alert.component';
import { IConfigurations } from 'src/app/core/models/settings.model';

@Component({
  selector: 'app-e-navbar',
  templateUrl: './e-navbar.component.html',
  styleUrls: ['./e-navbar.component.scss']
})
export class ENavbarComponent implements OnInit {

  private toggleButton: any;
  private sidebarVisible: boolean;
  private viewedAs:any;
  public adminMenuUser!:boolean;
  public showOffer!:boolean | any;
  public downloadImageCount:number=0;
  leadDialog!: MatDialogRef<AlertComponent>;
  configurations:IConfigurations = {} as IConfigurations;

  constructor(public location: Location,
      private element : ElementRef,
      private basketService:BasketService,
      private securityService:SecurityService,
      private error:ErrorService,
      public router:Router,
  private imageService:ImageDownloadService,
  public localeService:LocaleService,
  public orderService:OrderService,
  public offerService:OffersService,
  public settingsService:SettingsService,
  public modalService:NgbModal,
  public dialog:MatDialog ) {
      this.sidebarVisible = false;
      this.configurations = this.localeService.GetConfigFromStore();
  }

  ngOnInit() {
      const navbar: HTMLElement = this.element.nativeElement;
      this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];
      this.viewedAs=this.basketService.getCustomer().name;
      this.imageService.getImageListCount().subscribe(count=>{
            this.downloadImageCount=count
        })

      //-- NEW SETTINGS CODE
      let showOffer=false;
      this.showOffer=false;
      let rejecttablePriceGroup=[];
      let userPriceGroup= this.securityService.userPriceGrp;
      this.settingsService.settings.subscribe(setting=>{
          this.showOffer=setting.SHOWOFFER;
        //   setting.NOOFFERPRICEGROUP=setting.NOOFFERPRICEGROUP.toLowerCase();
        //             if (setting.NOOFFERPRICEGROUP.includes("|")) {
        //                 setting.NOOFFERPRICEGROUP=setting.NOOFFERPRICEGROUP.split("|");
        //             }
        //             rejecttablePriceGroup.push(setting.NOOFFERPRICEGROUP);
        // if (rejecttablePriceGroup.indexOf(userPriceGroup.toLowerCase())>0 && this.showOffer) {
        //     this.showOffer=false;
        //     let resetOffer={SHOWOFFER:false}
        //     this.settingsService.setSettings(resetOffer);
        //     }
      })
      //--

  }
  sidebarOpen() {
      const toggleButton = this.toggleButton;
      const html = document.getElementsByTagName('html')[0];
      setTimeout(function(){
          toggleButton.classList.add('toggled');
      }, 500);
      html.classList.add('nav-open');

      this.sidebarVisible = true;
  };
  sidebarClose() {
      const html = document.getElementsByTagName('html')[0];
      this.toggleButton.classList.remove('toggled');
      this.sidebarVisible = false;
      html.classList.remove('nav-open');
  };
  sidebarToggle() {
      // const toggleButton = this.toggleButton;
      // const body = document.getElementsByTagName('body')[0];
      if (this.sidebarVisible === false) {
          this.sidebarOpen();
      } else {
          this.sidebarClose();
      }
  };
  isHome() {
      var titlee = this.location.prepareExternalUrl(this.location.path());

      if( titlee === '/home' ) {
          return true;
      }
      else {
          return false;
      }
  }
  isDocumentation() {
      var titlee = this.location.prepareExternalUrl(this.location.path());
      if( titlee === '/documentation' ) {
          return true;
      }
      else {
          return false;
      }
  }

  getBasketCount():Observable<number>
  {
      return this.basketService.itemCount$;
  }

  isLoggedIn():boolean
  {
       return this.securityService.isloggedIn();
  }

  getUserName():string
  {

      return this.securityService.getClaims()?.name;

  }

  showAdminMenu():boolean
  {
      let flag=false;
      switch(this.securityService.userRole)
      {
          case 'admin':
          case 'appadmin':
          case 'appuser':
                          flag=true;
                          break;

      }
      return flag;
  }

  onLogout() {
      this.securityService.startSignoutMainWindow();
  }

  onManageUsers()
  {
       this.securityService.manageUsersUri()
       .subscribe(data => window.location.href = data,
          error=>this.error.handleErrorWithAlert(error));
  }

  onMyAccount()
  {
     this.securityService.myAccountUri()
     .subscribe(data=>window.location.href = data,
          error=>this.error.handleErrorWithAlert(error));
  }

  onDownloadImages()
  {
    if (this.downloadImageCount<=0) {
        const modalRef = this.modalService.open(ModalPopupComponent);
        modalRef.componentInstance.title = "No image found";
        modalRef.componentInstance.content = "There are no images in your download list.";
        return
    }

      this.imageService.downloadImages();
  }

  getImageListCount():Observable<number>
  {
     return this.imageService.getImageListCount();
  }

  onImageListClear()
  {
      this.imageService.clear();
  }
  onLeadSelection(){

    this.leadDialog=this.dialog.open(AlertComponent, {
    //   minWidth:'550px',
      minHeight:'550px',
      data: {leadSelection:true,type:'leadSelect'}
    })
    this.leadDialog.afterClosed().subscribe(data=>{
      this.basketService.setSelectedLead(data.data);
    })
 }

 onRestoreCartClick()
 {
    let aBasketData = [];
    this.basketService.basketData$.subscribe(BaketData => aBasketData = BaketData.items);
    if(aBasketData.length > 0)
    {
        const modalRef = this.modalService.open(ModalPopupComponent);
          modalRef.componentInstance.title = `Alert`;
          modalRef.componentInstance.content =`Cart is not empty`;
          this.router.navigate(['/basket']);
    }
    else{
        this.router.navigate(['/manage/cartitems']);
    }
 }

}
