import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-newsletter',
  templateUrl: './newsletter.component.html',
  styleUrls: ['./newsletter.component.scss']
})
export class NewsletterComponent implements OnInit {

  currentPage:string = "";
  constructor(private router:Router) { }

  ngOnInit() {
    this.currentPage=this.router.url;
  }

}
