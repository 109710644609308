import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LocaleService } from 'src/app/core/services/locale.service';

@Component({
  selector: 'app-modal-popup',
  templateUrl: './modal-popup.component.html',
  styleUrls: ['./modal-popup.component.scss']
})
export class ModalPopupComponent implements OnInit, OnChanges {

  @Input() title!: string;
  @Input() content!: string;
  @Input() deliveryLocation: any;
  @Input() Comments!: string;
  @Input() cartNote!: string;
  @Input() orderRefNo!: string;
  @Input() showOfferNote: boolean = false;
  @Input() okButton!: string;
  @Input() cancelButton!: string;
  @Input() importedItems!: [];

  constructor(private _modalRef: NgbActiveModal, public localeService: LocaleService) {
  }

  public ngOnInit(): void {
    this.showOfferNote;
    this.importedItems;
    document.getElementById('modal-body')!.innerHTML=this.content;
  }

  ngOnChanges(changes: SimpleChanges) {
    changes
  }

  public onConfirm(): void {
    this._modalRef.close(true);
  }

  public onCancel(): void {
    this._modalRef.close(false);
  }



}
