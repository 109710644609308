import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LocaleService } from 'src/app/core/services/locale.service';

@Component({
  selector: 'app-empty-content',
  templateUrl: './empty-content.component.html',
  styleUrls: ['./empty-content.component.scss']
})
export class EmptyContentComponent implements OnInit {

  public imageURL!:string;
  @Input('noImageUri') noImageUri:string = "";
  
  constructor(public localeService:LocaleService,public router:Router) { }

  ngOnInit() {
    let localeCode=this.localeService.code;
    this.imageURL='assets/img/empty-'+localeCode+'.jpg';
  }
  backtoShop(){
    this.router.navigate(['/catalog']);
  }

}
