import { Component, OnInit, OnDestroy } from '@angular/core';
import { SecurityService } from 'src/app/core/services/security.service';
import { ErrorService } from 'src/app/core/services/error.service';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LocaleService } from 'src/app/core/services/locale.service';
import { IConfigurations } from '../core/models/settings.model';

@Component({
  selector: 'app-layout-opp-buy',
  templateUrl: './layout-opp-buy.component.html',
  styleUrls: ['./layout-opp-buy.component.scss']
})
export class LayoutOppBuyComponent implements OnInit,OnDestroy {
  destroy$ = new Subject();
  localeCode!:string;
  currentPage!:string;
  configurations:IConfigurations = {} as IConfigurations;

  constructor(private securityService: SecurityService,
    private error: ErrorService,public router:Router,public localeService:LocaleService) {
      this.configurations = this.localeService.GetConfigFromStore();
     }

  ngOnInit() {
    this.localeCode=this.localeService.code;
    this.currentPage=this.router.url;
  }
  onLogin() {
    this.securityService.startAuthentication();
  }

  onRegister() {
    this.securityService.register().pipe(
      takeUntil(this.destroy$))
      .subscribe(data => window.location.href = data,
        error => this.error.handleErrorWithAlert(error));

  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
  onRetailRequest(){
    this.router.navigate(['/retailer']);
  }
  onHome(){
    this.router.navigate(['/access']);
  }
  onOppBuy(){
    this.router.navigate(['/OppBuy']);
  }
  onHelp(){
    this.router.navigate(['/help']);
  }
  onNews(){
    this.router.navigate(['/newsletter']);
  }
}
