import { Directive, OnInit, Input, ElementRef, OnDestroy, Renderer2, AfterViewInit, OnChanges, SimpleChanges, EventEmitter, Output, HostListener } from '@angular/core';
import { SecurityService } from 'src/app/core/services/security.service';

@Directive({
  selector: '[appHaspermissions]'
})
export class HaspermissionsDirective implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  public Permissions: any;
  public allow: boolean = false;

  @Input() permissions: any;
  @Input() quantity: any;
  @Input() pprice: any;
  @Input() dateAvail: any;
  @Input() allowedCategories!: [] | any;
  @Input() pCategory: any;

  today;
  constructor(
    public securityService: SecurityService,
    public elementRef: ElementRef,
    public renderer: Renderer2
  ) {
    this.Permissions;
    this.elementRef.nativeElement.disabled = false;
    this.today = new Date();
    let date2 = new Date("Dec 15, 2014 21:20:15");

    if (date2 < this.today) {
      this.today
    }
  }

  ngOnInit() {
    //@ts-ignore
    // var btn=$('.navbar-right')[0];
    // this.renderer.addClass(btn,'shake-slow');
    // this.renderer.addClass(btn,'shake-constant');
    // setTimeout(()=>{this.renderer.removeClass(btn,'shake-constant');},1000)
    this.renderer.listen(this.elementRef.nativeElement, 'click', () => {
      //this.renderer.setStyle(this.elementRef.nativeElement,'display','none');
      this.elementRef.nativeElement.disabled = true;
      setTimeout(() => {
        this.elementRef.nativeElement.disabled = false;
      }, 1000)
    })

    this.dateAvail = new Date(this.dateAvail);
    this.Permissions = this.securityService.permissions;
    //-- Zero value category
    let allowCat = true;
    //@ts-ignore
    // if (!this.allowedCategories.includes(this.pCategory)) {
    if (this.allowedCategories.indexOf(this.pCategory) < 0) {
      allowCat = false;
    }
    //--
    this.Permissions.forEach((element: any) => {
      // if (element.includes(this.permissions)) {
      if (element.indexOf(this.permissions) >= 0) {
        this.allow = true;
        if (this.quantity && this.quantity == "0") {
          this.allow = true;
        }
        if (parseFloat(this.pprice) <= 0) { //|| this.quantity.includes('-')
          this.allow = false;
          //--Zero value category
          if (allowCat) {
            this.allow = true;
          }
          //--
        }
      }
      else {
        //back Order
        // if (this.quantity && this.quantity!="0" && !this.quantity.includes('-') ) {
        if (this.quantity && this.quantity != "0" && !(this.quantity.indexOf('-') >= 0)) {
          this.allow = true;
          if (parseFloat(this.pprice) == 0) {
            this.allow = false;
            //--Zero value category
            if (allowCat) {
              this.allow = true;
            }
            //--
          }
        }
        else if (parseFloat(this.quantity) <= 0 && parseFloat(this.pprice) > 0) {
          this.allow = true;
        }
        //Pre Order
        if (this.dateAvail > this.today && parseFloat(this.pprice) > 0) {
          this.allow = true;
        }

      }
    });

    //--



  }
  ngAfterViewInit() {
    if (!this.allow) {
      this.elementRef.nativeElement.disabled = true;
    }
    else {
      this.elementRef.nativeElement.disabled = false;
    }
    //@ts-ignore
    $(this.elementRef.nativeElement).on('click', this.addToCartAnimation)
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes.allowedCategories) {
      this.ngOnInit();
    }

  }
  ngOnDestroy() {
  }

  addToCartAnimation() {
    //@ts-ignore
    var cart = $('.cart-animate');
    //var imgtodrag = $(this).parent('.product').find('img').eq(0);
    //@ts-ignore
    var imgtodrag = $(this).parent().parent().find('img')
    if (imgtodrag) {
      var imgclone = imgtodrag.clone()
        .offset({
          top: imgtodrag.offset().top,
          left: imgtodrag.offset().left
        })
        .css({
          'opacity': '0.5',
          'position': 'absolute',
          'height': '150px',
          'width': '150px',
          'z-index': '1001'
        })
        //@ts-ignore
        .appendTo($('body'))
        .animate({
          //@ts-ignore
          'top': cart.offset().top + 10,
          //@ts-ignore
          'left': cart.offset().left + 10,
          'width': 75,
          'height': 75
        }, 1000, 'easeInOutExpo');

      setTimeout(function () {
        //@ts-ignore
        cart.effect("shake-hard", {
          times: 2
        }, 100);
      }, 1500);

      imgclone.animate({
        'width': 0,
        'height': 0
      }, function () {
        //@ts-ignore
        $(this).detach()
      });
    }
    return false
  }

}
