import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { OrderService } from 'src/app/core/services/order.service';
import { SecurityService } from 'src/app/core/services/security.service';
import {MatChipInputEvent} from '@angular/material/chips';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
  selector: 'app-email-notify',
  templateUrl: './email-notify.component.html',
  styleUrls: ['./email-notify.component.scss']
})
export class EmailNotifyComponent implements OnInit {
  emailForm!: FormGroup;
  items!: FormArray;
  @Input() orderno:any;
  @Output() mailEvent = new EventEmitter<string>();

  //Chip list
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  fruits: any[] = [];
  emails:any[]=[];
  defaulUser:any;

  public message:string='';

  constructor(public formBuilder:FormBuilder,public morder:OrderService,public securityService:SecurityService,
    public snackbar:MatSnackBar) { }
  
  ngOnInit() {
    this.emailForm = this.formBuilder.group({
      emails: ''
    });
    let x=this.securityService.getClaims();
    this.defaulUser=x.email;
    this.emails.push(this.defaulUser);
    this.fruits.push(this.defaulUser);
  }
  
  //
  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let validEmail=re.test(String(value).toLowerCase());
    // Add our fruit
    if ((value || '').trim() && validEmail) {
      this.fruits.push(value.trim());
      this.emails.push(value.trim());
      // this.emailForm.controls['emails'].setValue(this.emails);
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  remove(fruit: any): void {
    const index = this.fruits.indexOf(fruit);
    const index2=this.emails.indexOf(fruit)
    if (index >= 0) {
      this.fruits.splice(index, 1);
      this.emails.splice(index2,1);
      // this.emailForm.controls['emails'].setValue(this.emails);

    }
  }

  sendMail(){
    console.log(this.emailForm.controls,'email array'+this.emails);
    let params={
      OrderNo:this.orderno,
      EmailList:this.emails.join()
    };
    //this.emailForm.controls['emails'].value.join()

    this.morder.sendEmail(params).subscribe(data=>{
        this.message='Notification email sent';      
        this.openSnackBar(this.message,'Okay');
        this.mailEvent.emit();
    })
  }
  openSnackBar(message: string, action: string) {
    this.snackbar.open(message, action, {
      duration: 200000000,
      panelClass:'snack-Success'
    });
  }
  
}
