import { Directive, OnInit, OnDestroy, Input, ViewContainerRef, TemplateRef } from '@angular/core';
import { SecurityService } from 'src/app/core/services/security.service';

@Directive({
    selector: '[appHasRole]'
  })
  export class HasRoleDirective implements OnInit, OnDestroy {    
    @Input() appHasRole!: string;
    isVisible = false;
    
    constructor(
      private viewContainerRef: ViewContainerRef,
      private templateRef: TemplateRef<any>,
      private securityService: SecurityService
    ) {}
  
    ngOnInit() {
      
        let userRole= this.securityService.userRole;

        if (!userRole) {
          this.viewContainerRef.clear();
        }
        
        let roles = this.appHasRole.split(',');
        
        //if (roles.includes(userRole)) { 
        if (roles.indexOf(userRole)>=0) {
          if (!this.isVisible) {
           
            this.isVisible = true;
            this.viewContainerRef.createEmbeddedView(this.templateRef);
          }
        } else {
         
          this.isVisible = false;
          // this.viewContainerRef.clear();
        }
      }
    
    
    ngOnDestroy() {
     
    }
  }