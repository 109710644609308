import { Injectable, OnInit } from '@angular/core';

import { ApiConfig, appConfig } from '../../model/api-config';
import { HttpClient } from '@angular/common/http';
import { LocaleService } from './locale.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class ApiConfigService implements OnInit {

  apiConfig!: ApiConfig;
  appConfig!:appConfig;
  constructor(private http: HttpClient,public localService:LocaleService) { }

  ngOnInit() {
    
   }

  loadConfig(): Promise<any> {
    let apiConfigFile = `/assets/config/${environment.envName}/api-config.json`;
    //@ts-ignore
    //let baseHost = document.baseURI.endsWith('/') ? document.baseURI : `${document.baseURI}/`;
    //-- New
    let baseHost=document.baseURI;
    if (document && document.baseURI && document.baseURI.lastIndexOf('/')==document.baseURI.length) {
      baseHost=document.baseURI+'/';
    }
    //-- End
    //if (environment.envName == 'mock') {
    //  apiConfigFile = '/assets/config/mock/api-config.json';
    //}
    return this.getJSONFile(apiConfigFile).then(data => {
      this.apiConfig = data;
      if (environment.envName == 'prod') {

        this.apiConfig.hostUri = baseHost;
      }
      
      this.apiConfig.security.manageUserURI = this.apiConfig.security.manageUserURI;
      this.apiConfig.security.myAccountURI = this.apiConfig.security.myAccountURI;
      this.apiConfig.security.register_uri = this.apiConfig.security.register_uri.replace('$BASE_HOST', this.apiConfig.hostUri);

      this.apiConfig.security.post_logout_redirect_uri = this.apiConfig.security.post_logout_redirect_uri.replace('$BASE_HOST/', this.apiConfig.hostUri);
      this.apiConfig.security.popup_redirect_uri = this.apiConfig.security.popup_redirect_uri!.replace('$BASE_HOST/', this.apiConfig.hostUri);
      this.apiConfig.security.redirect_uri = this.apiConfig.security.redirect_uri.replace('$BASE_HOST/', this.apiConfig.hostUri);
      this.apiConfig.security.silent_redirect_uri = this.apiConfig.security.silent_redirect_uri.replace('$BASE_HOST/', this.apiConfig.hostUri);
      this.apiConfig.security.client_id = this.apiConfig.hostUri;
    });
  }

  formatUrl(url: string): string {
    if (environment.envName == 'mock'
      || environment.envName == 'dev') {
      return this.apiConfig.apiUri + url;
    }
    else {
      return url;
    }
  }

 

  private getJSONFile(url: string): Promise<any> {
    return this.http.get(url, { responseType: 'json' })
      .toPromise()
      .then(response => response)
      .catch((error: any) => console.log(error));

  }

}
