<div class="page-au-bg">
  <mat-toolbar *ngIf="currentPage!='/access'" color="" class="landing-header">
    <mat-toolbar-row class="container">
            <!-- <img class="years" src="assets/img/Asset_1.png" alt="">
            <span class="example-spacer"></span> -->
            <img  class="img-fluid" src="assets/img/logo.png" />
            <span class="example-spacer"></span>
    </mat-toolbar-row>
  </mat-toolbar>

  <div class="row" style="min-height: 79.1vh; padding-bottom:1rem;">
    <div class="container">
        <mat-card class="list">
            <mat-card-header class="no-margin">
              <mat-card-title class="no-margin">

                  </mat-card-title>
              </mat-card-header>

              <mat-card-content>
                <img  src="assets/img/newsletter-graphic.jpg" alt="">
              </mat-card-content>
        </mat-card>

    </div>
  </div>
</div>
