<!-- <owl-carousel #owlElement *ngIf="items.length>0 && !hide"
        [options]="options"
        [items]="items"
        [carouselClasses]="['owl-theme', '', 'sliding']">
        <div class="item" *ngFor="let image of items;let i = index">
            <img *ngIf="image.OfferImage" style="width: 100%;" [src]="image.OfferImage" appImagePreloadDir promoCode="{{image.description}}" minValue="{{image.minOrderAmount}}" currencyCode="{{image.minOrderAmount |currency :format.currencyCode :format.symbol :format.numberFormat}}">
            <img *ngIf="!image.OfferImage" style="width: 100%;" [src]="image.link" alt="{{image}}">
        </div>
</owl-carousel> -->

<!--image.OfferImage-->
<!--image.link-->

<owl-carousel-o [options]="customOptions" *ngIf="items?.length>0 && !hide">
  <ng-container class="item" *ngFor="let image of items;let i = index">
    <ng-template carouselSlide [width]="100">
      <img *ngIf="image.OfferImage" style="width: 100%;" [src]="image.OfferImage" appImagePreloadDir promoCode="{{image.description}}" minValue="{{image.minOrderAmount}}" currencyCode="{{image.minOrderAmount |currency :format.currencyCode :format.symbol :format.numberFormat}}">
      <img *ngIf="!image.OfferImage" style="width: 100%;" [src]="image.link" alt="{{image}}">
    </ng-template>
  </ng-container>
</owl-carousel-o>
