import { Component, OnInit, EventEmitter, Input, Output, OnDestroy, OnChanges } from '@angular/core';
import { AbstractControl, ValidatorFn, FormGroup, FormControl, Validators } from '@angular/forms';
import { distinctUntilChanged, pairwise, debounce } from 'rxjs/operators';
import { Subject, timer } from 'rxjs';
import { BasketService } from 'src/app/core/services/basket.service';
import { CatalogService } from 'src/app/catalog/services/catalog.service';
import { SecurityService } from 'src/app/core/services/security.service';


function multiplesValidator(multiple: number): ValidatorFn {  
  return (c: AbstractControl): { [key: string]: boolean } | null => {
    if (!c.pristine && multiple > 0) {

      if ((Number(c.value) % Number(multiple)) !== 0) {
        return { 'multiples': true };
      }
    }
    return null;
  }
}

@Component({
  selector: 'app-order-quantity',
  templateUrl: './order-quantity.component.html',
  styleUrls: ['./order-quantity.component.scss']
})
export class OrderQuantityComponent implements OnInit, OnDestroy,OnChanges {
  destroy$ = new Subject();
  qtyDisable:boolean=true;
  @Input() qty: any;
  @Output() qtyChange: EventEmitter<number>;
  @Input() multiples!: number | any;
  @Output() qtyBlur: EventEmitter<number>;
  multipl!:any;
  @Input() sku:any;
  @Input() orderNum:any;
  @Input() getOrder!:boolean;
  @Input() stock!:number;

  @Input() isCatalog!:boolean;
  @Input() isQOrder!:boolean;
  @Input() byScan!:boolean;
  //Catalog quantity
  
  public oldVal!:any;
  dia=20;
  clr="warn"

  backOrderPermission:boolean=false;

  quantity!: FormControl;
  form!: FormGroup;

  public updatingField:boolean=false;

  @Output() enterEvent = new EventEmitter<string>();

  constructor(public basketService:BasketService,public catalogService:CatalogService,public securityService:SecurityService) {

    this.qtyChange = new EventEmitter<number>();
    this.qtyBlur = new EventEmitter<number>();

    
  }

  ngOnInit() {
    //
    if(this.getOrder) {

      this.getMultiples(this.sku);
    }
    //
    this.multipl=this.multiples
    if (this.qty>1) {
      this.qtyDisable=false;
    }
    
    let backorderPermission=this.securityService.hasPermission('allow_backorder');
    this.backOrderPermission=backorderPermission;

    
      this.quantity = new FormControl('', [
        Validators.required,
        Validators.min(1),
        multiplesValidator(this.multiples)
      ]);  

    this.quantity.valueChanges.pipe(
      pairwise(),
      debounce(value=>{
        
        let dTime=500;
        if (value[1]===null) {
          dTime=1000;
        }
        let x=value[1];
        if (this.multiples===0) {
          this.multiples=1
        }
        let m=x % this.multiples
        if (m===0 && value[1]!==null ) {
          dTime=0
        }
        else{
          dTime=1700;
          this.updatingField=true
        }
        return timer(dTime)}),
      //debounceTime(400),
      distinctUntilChanged(),
      )
      .subscribe(value => {
        this.stock
        if (value[0]!=value[1] && value[0]!='') {
          // this.qtyChange.emit(value[1]); /// -- RECENTLY COMMENTED
        if (this.quantity.valid) {
          this.updatingField=false
            this.qtyChange.emit(value[1]);  //-- RECENTLY ADDED
          this.onBlur();
        }
        else{
          if (this.multiples>0 ) { 
            //-- Apply nearest value
            let x=this.quantity.value;
            let m=x % this.multiples
            let sValue=x-m;
            
            if (this.multiples>x) { //(this.multiples>x || sValue>this.stock)
                
              if (this.multiples<this.stock) {
                this.qtyChange.emit(this.multiples); //-- RECENTLY ADDED
              this.quantity.setValue(this.multiples);
              }
              else{
                if (this.stock>0) {
                  this.qtyChange.emit(this.stock); //-- RECENTLY ADDED
                  this.quantity.setValue(this.stock);  
                }
                if (this.backOrderPermission) {
                  this.qtyChange.emit(this.multiples); //-- RECENTLY ADDED
                  this.quantity.setValue(this.multiples);
                }
              }
            }
            else{
                this.qtyChange.emit(sValue); //-- RECENTLY ADDED
              this.quantity.setValue(sValue);
            }
            //-- End
            //this.quantity.setValue(this.multiples);
            this.onBlur();
            this.updatingField=false
          }
          else{ // There was no else part previously
              this.qtyChange.emit(1); //-- RECENTLY ADDED
            this.quantity.setValue(1);
            this.onBlur();
            this.updatingField=false
            }
          }
        }

        //-- Stock validation and resetting
        if (this.stock && value[1]>this.stock && !this.backOrderPermission) {
          let stockEq=this.quantity.value - this.stock;
          let x=this.quantity.value-stockEq;
          let m=x % this.multiples
          let sValue=x-m;
          if (sValue<=this.stock && sValue>0) { // && m===0
            this.qtyChange.emit(sValue); 
            this.quantity.setValue(sValue);
          }
          
          this.onBlur();
          this.updatingField=false          
        }
        //-- End stock Validation        
      });
      
    this.quantity.setValue(this.qty);
    if (this.quantity.value !== null
      && this.quantity.value !== undefined
      && this.quantity.value !== '') {
      this.quantity.markAsDirty();
    }

    if (this.isCatalog ) {
      this.quantity.setValue(this.multiples);
      if (this.multiples===0) {
        this.quantity.setValue(1);
      }
      let x=typeof(this.multiples);
    }
    if (this.isQOrder ) {
      // this.quantity.setValue(1);
      // if (this.qty!=='') {
      //   this.quantity.setValue(this.qty);
      // }
    }
  }
  onBlur() {
        this.qtyBlur.emit(this.qty);  
  }
  ngOnChanges(changes:any){

    if (this.byScan==true) {
      if (changes.multiples && changes.multiples.previousValue===0) {
        this.multiples=changes.multiples.currentValue;
        this.quantity.setValue(changes.multiples.currentValue);
        this.qtyChange.emit(this.quantity.value);
      }
    }
    else{
      if (changes.multiples) {
        this.multiples=changes.multiples.currentValue;
          this.qtyChange.emit(changes.multiples.currentValue);  
        
      }
    }
    if (changes.qty) {
      //this.quantity.setValue(changes.qty.currentValue);
      if (this.multiples===0) {
        this.multiples=1;
      }
      if (this.quantity && this.quantity.value%this.multiples!==0) {
        //--
            let x=this.quantity.value;
            let m=x % this.multiples
            let sValue=x-m;
            this.quantity.setValue(sValue);
            if (x<this.multiples) {
              this.quantity.setValue(this.multiples);
            }
        //--
        //this.quantity.setValue(this.multiples);
      }
    }
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
  increment(quantity:any,multiples:any){
    console.log("qty "+parseInt(this.quantity.value));
    let multi=multiples;
    this.multiples;
    let temp=parseInt(quantity.value);
    temp=isNaN(temp)?0:temp;  //temp=isNaN(temp)?0:temp;
    // this.qty=temp++;

    //this.quantity.setValue(temp+multi); - OLD 
    
    if (this.quantity.valid) {
      this.quantity.setValue(temp+multi); //this.quantity.setValue(temp++);    
    }
    else{ 
      this.quantity.setValue(this.multiples); 
    }
   
  }
  decrement(quantity:any,multiples:any){

    let multi=multiples; //let multi=12;
    let temp=parseInt(quantity.value);  
    temp=isNaN(temp)?this.multiples:temp;  //temp=isNaN(temp)?0:temp;
    temp < this.multiples ? temp = this.multiples : ''; //temp < 1 ? temp = 1 : '';
    // this.qty=temp--;
    let x=temp-multi;

      if(x>=this.multiples && x>0 ){
        this.quantity.setValue(temp-multi);  
      }

      
  }

  getMultiples(sku:string){

    let params: any = {
      page: "1",
      pageSize: "1",
      searchTerm: sku,
      categorySelected:"",
      sortOrder: 'sku_desc'
    };
    
    this.catalogService.getProducts(params)
    .pipe()
    .subscribe(data=>{
      this.multiples=data.products[0].multiples;
      if (this.multiples==0) {
        this.multiples=1;
      }
        this.quantity.setValidators([multiplesValidator(this.multiples),Validators.required,
          Validators.min(this.multiples)])
        console.log(sku+':'+this.multiples)

    });
  }

  onKeydown(event:any) {
    if (event.key === "Enter") {
      this.enterEvent.emit(event);
    }
  }
  selectText($event:any){
    $event.target.select();
  }
  
}
