<mat-card class="example-card">
    <mat-card-header *ngIf='data.type=="wish_list"'>
        <mat-card-title>Wishlist items</mat-card-title>
        <mat-card-subtitle>There are <span *ngIf="data.item.length>0">{{(data.item.length/2)}}</span> <span
                *ngIf="data.item.length==0">no</span> items in your wishlist</mat-card-subtitle>
        <span class="example-spacer"></span>
        <div mat-dialog-actions class="text-right">
            <button mat-mini-fab (click)="closeDialog()" color="warn"><i class="material-icons">close</i></button>
        </div>
    </mat-card-header>
    <mat-card-header *ngIf='data.type=="import_Items"'>
        <mat-card-title>Your order contains back-order items</mat-card-title>
        <mat-card-subtitle>Below items in your order are exceeding the available stock </mat-card-subtitle>
        <span class="example-spacer"></span>
        <div mat-dialog-actions class="text-right">
            <button mat-mini-fab (click)="closeDialog()" color="warn"><i class="material-icons">close</i></button>
        </div>
    </mat-card-header>
    <mat-card-header *ngIf='data.type=="leadSelect"'>
        <mat-card-title style="text-transform: uppercase;"><i class="now-ui-icons business_badge"></i> Select a Sales
            Lead </mat-card-title>
        <!-- <mat-card-subtitle>Below items in your order are exceeding the available stock </mat-card-subtitle> -->
        <span class="example-spacer"></span>
        <div mat-dialog-actions class="text-right">
            <button mat-mini-fab (click)="closeDialog()" color="warn"><i class="material-icons">close</i></button>
        </div>
    </mat-card-header>
    <mat-card-header *ngIf='data.type=="priceGroupSelect"'>
        <mat-card-title>Please select customer price group</mat-card-title>
        <!-- <mat-card-subtitle>Below items in your order are exceeding the available stock </mat-card-subtitle> -->
        <span class="example-spacer"></span>
        <div mat-dialog-actions class="text-right">
            <button mat-mini-fab (click)="closeDialog()" color="warn"><i class="material-icons">close</i></button>
        </div>
    </mat-card-header>
    <mat-card-header *ngIf='data.type=="product_list"'>
        <!-- <div mat-card-avatar class="example-header-image">
        <img mat-card-image src="{{imageUrl}}" alt="{{data.item.description}}"> 
      </div> -->
        <mat-card-title>{{data.item.description}}</mat-card-title>
        <mat-card-subtitle>{{data.item.no}}</mat-card-subtitle>
        <span class="example-spacer"></span>
        <div mat-dialog-actions class="text-right">
            <button mat-mini-fab (click)="closeDialog()" color="warn"><i class="material-icons">close</i></button>
        </div>

    </mat-card-header>
    <mat-card-header *ngIf='data.type=="order"' class="text-right">
        <span class="example-spacer"></span>
        <button mat-mini-fab (click)="closeDialog()" color="warn"><i class="material-icons">close</i></button>
    </mat-card-header>
    <mat-card-header *ngIf="data.type=='order_success'">
        <mat-card-title>Order number {{data.order}} is created</mat-card-title>
        <span class="example-spacer"></span>
        <div mat-dialog-actions class="text-right">
            <button mat-mini-fab (click)="closeDialog()" color="warn"><i class="material-icons">close</i></button>
        </div>
    </mat-card-header>
    <mat-card-header *ngIf="data.type=='order_failure'">
        <mat-card-title>Failed to create order</mat-card-title>
        <span class="example-spacer"></span>
        <div mat-dialog-actions class="text-right">
            <button mat-mini-fab (click)="closeDialog()" color="warn"><i class="material-icons">close</i></button>
        </div>
    </mat-card-header>
    <mat-card-header *ngIf="data.type=='email_notify'">
        <mat-card-title>Send order notifications</mat-card-title>
        <span class="example-spacer"></span>
        <div mat-dialog-actions class="text-right">
            <button mat-mini-fab (click)="closeDialog()" color="warn"><i class="material-icons">close</i></button>
        </div>
    </mat-card-header>
    <mat-card-header *ngIf="data.type=='invoice_view'">

        <mat-card-title>Invoice View </mat-card-title>
        <span class="example-spacer"></span>
        <div mat-dialog-actions class="text-right">
            <button mat-mini-fab (click)="closeDialog()" color="warn"><i class="material-icons">close</i></button>
        </div>
    </mat-card-header>
    <mat-card-header *ngIf="data.type=='view_offers'">
        <mat-card-title>Offers for you</mat-card-title>
        <span class="example-spacer"></span>
        <div mat-dialog-actions class="text-right">
            <button mat-mini-fab (click)="closeDialog()" color="warn"><i class="material-icons">close</i></button>
        </div>
    </mat-card-header>
    <mat-card-header *ngIf="data.type=='addLocation'">
        <!-- <h3><b>Request new shipping address</b></h3> -->
        <span class="example-spacer"></span>
        <div mat-dialog-actions class="text-right">
            <button mat-mini-fab (click)="closeDialog()" color="warn"><i class="material-icons">close</i></button>
        </div>
    </mat-card-header>
    <mat-card-content>
        <!-- <mat-divider></mat-divider> -->
        <div mat-dialog-content *ngIf='data.type=="product_list"' class="image-viewer">
            <img mat-card-image src="{{imageUrl}}" alt="{{data.item.description}}">
        </div>
        <div mat-dialog-content *ngIf='data.type=="order"'>
            <mat-toolbar color="" class="order-viewer">
                <mat-toolbar-row fxShow fxHide.xs fxLayout="column" fxLayoutGap="5px" fxLayoutAlign="start">
                    <span>Order : {{order.orderNo}} </span>
                    <div fxLayout="row" fxLayoutAlign="space-between center">
                        <span> Ref no : {{order.referenceNo}} </span>
                        <span> {{order.orderDate|date:format.dateFormat}}</span>
                    </div>
                </mat-toolbar-row>
                <div fxShow.xs fxHide  fxLayout="column" fxLayoutGap="5px" fxLayoutAlign="start">
                    <span>Order : {{order.orderNo}} </span>
                    <span style="white-space: pre-wrap;"> Ref no : {{order.referenceNo}} </span>
                    <span> {{order.orderDate|date:format.dateFormat}}</span>
                </div>
            </mat-toolbar>
            <mat-list class="order-viewer-list">
                <mat-list-item>
                    <h3 mat-line><b class="orange-txt text-lowercase">Comments</b></h3>
                    <p mat-line>{{order.comments}}</p>
                </mat-list-item>
                <mat-list-item *ngFor="let item of order.orderItems">
                    <mat-icon mat-list-icon>
                        <img src="{{imageConfig.lowResUri+item.itemNumber}}.jpg" [src-fallback]="imageConfig.noImageUri"
                            alt="{{item.description}}">
                    </mat-icon>

                    <h4 mat-line><b class="orange-txt text-lowercase">{{item.description}}</b>({{item.quantity}} * {{
                        item.unitPrice|currency :format.currencyCode :format.symbol :format.numberFormat }} ) <span
                            class="text-right">{{ ((item.unitPrice*item.quantity)-item.lineDiscountAmt)|currency
                            :format.currencyCode :format.symbol :format.numberFormat}}</span></h4>
                    <p mat-line>SKU : {{item.itemNumber}}, Discount: <b>{{item.lineDiscount}}%</b> </p>

                    <mat-divider></mat-divider>
                </mat-list-item>
                <mat-list-item>
                    <h4 mat-line><b><span class="text-right">
                                <!-- {{12.59 | number:'1.0-1' |currency :format.currencyCode :format.symbol :format.numberFormat}} -->
                                {{ order.total|currency :format.currencyCode :format.symbol :format.numberFormat }}
                            </span></b></h4>
                </mat-list-item>
            </mat-list>
            <mat-toolbar color="">
                <mat-toolbar-row>
                    <span>Address</span>
                </mat-toolbar-row>
            </mat-toolbar>
            <mat-card>
                <mat-card-content>
                    <address *ngIf="order.shippingAddress.address" class="text-lowercase"><i
                            class="material-icons">location_on</i> <b>{{order.shippingAddress.name}}</b>,
                        {{order.shippingAddress.address}},{{order.shippingAddress.address2}},{{order.shippingAddress.city}}
                        - {{order.shippingAddress.postCode}}, {{order.shippingAddress.phoneNo}}</address>
                </mat-card-content>
                <mat-card-content>
                    <!-- <button mat-raised-button (click)="onPrint()" color="warn"><i class="material-icons">print</i> Print</button> -->
                </mat-card-content>
            </mat-card>
        </div>
        <div mat-dialog-content *ngIf="data.type=='wish_list'">
            <mat-list class="order-viewer-list">
                <div *ngFor="let item of data.item;let i = index">
                    <mat-list-item *ngIf="i%2!=0">
                        <mat-icon mat-list-icon *ngIf="i%2!=0">
                            <img src="{{imageConfig.lowResUri+item.no}}.jpg" [src-fallback]="imageConfig.noImageUri"
                                alt="{{item.description}}">
                        </mat-icon>
                        <h4 mat-line *ngIf="i%2!=0"><b class="orange-txt text-lowercase">{{item.description}}</b></h4>
                        <p mat-line *ngIf="i%2!=0">SKU : {{item.no}}</p>
                        <mat-divider *ngIf="i%2!=0"></mat-divider>
                    </mat-list-item>
                </div>
            </mat-list>
        </div>
        <div mat-dialog-content *ngIf="data.type=='order_success'">
            <mat-list class="Terms-conditions">
                <mat-list-item *ngFor="let Terms of configurations.alert.orderSuccessMessage">{{Terms.message}}</mat-list-item>
            </mat-list>
            <!-- <mat-list class="Terms-conditions">
                <mat-list-item *ngIf="localeCode=='au'"> Please note your order has not been lodged.</mat-list-item>

                <mat-list-item *ngIf="localeCode=='au'"><span class="orange-txt">To access your draft order, please head
                        to manage and click on orders.</span></mat-list-item>
                <mat-list-item *ngIf="localeCode=='au'">Any order that has been flagged with “pending” has been saved as
                    a draft order.</mat-list-item>
                <mat-list-item *ngIf="localeCode=='au'">Please email&nbsp; <a href="mailto:orders@wildrepublic.com.au">
                        orders@wildrepublic.com.au</a>&nbsp; if you have any further queries.</mat-list-item>

                <mat-list-item *ngIf="localeCode!='au'"> Any Tax, Shipping and Discounts will be calculated by the
                    Customer Representative and will be included in the Invoice</mat-list-item>
                <mat-list-item *ngIf="localeCode=='us' || localeCode=='ca'"> Orders generally take between 4 to 5
                    working days to process and dispatch from our warehouse, however, in our peak seasons the turnaround
                    time can vary. We do endeavour to have your order picked, packed and dispatched in a timely manner
                    on all occasions</mat-list-item>
                <mat-list-item *ngIf="configurations.Alert."> Orders generally take between 2 to 3 working days to process
                    and dispatch from our warehouse, however, in our peak seasons the turnaround time can vary. We do
                    endeavour to have your order picked, packed and dispatched in a timely manner on all occasions
                </mat-list-item>
                <mat-list-item *ngIf="!data.approved && localeCode!='au'"><span class="orange-txt"> “Order saved, please
                        navigate to “Manage > Orders” to approve the order.</span></mat-list-item>
            </mat-list> -->
        </div>
        <div mat-dialog-content *ngIf="data.type=='order_failure'">
            <mat-list class="Terms-conditions">
                <mat-list-item>Minimum order value must be {{data.order |currency :format.currencyCode
                    :format.symbol:format.numberFormat}}</mat-list-item>
            </mat-list>
        </div>
        <div mat-dialog-content *ngIf="data.type=='email_notify'">
            <app-email-notify (mailEvent)="recieveMail()" [orderno]="data.order.orderNo"></app-email-notify>
        </div>
        <div mat-dialog-content *ngIf="data.type=='invoice_view'">

            <mat-toolbar color="" class="order-viewer">
                <mat-toolbar-row>
                    <span>Invoice:{{data.invoice.invoiceNo}}</span>
                    <span class="example-spacer"></span>
                    <span> {{data.invoice.postingDate|date:format.dateFormat}}</span>
                </mat-toolbar-row>
            </mat-toolbar>
            <div class="invoice-details">
                <mat-grid-list cols="3" rowHeight="5:1">
                    <mat-grid-tile *ngIf="data.invoice.trackingNumber!=''">Tracking Number : <span
                            class="orange-txt">{{data.invoice.trackingNumber}}</span></mat-grid-tile>
                    <mat-grid-tile>Ref no: {{data.invoice.referenceNo}}</mat-grid-tile>
                </mat-grid-list>
            </div>
            <mat-list class="order-viewer-list invoice_view">
                <mat-list-item *ngFor="let item of data.invoice.invoiceItems">
                    <h4 mat-line><b class="orange-txt text-lowercase">{{item.description}}</b> ({{item.quantity}} units
                        * {{ item.unitPrice|currency :format.currencyCode :format.symbol :format.numberFormat }} ) <span
                            class="text-right">{{ ((item.unitPrice*item.quantity)-item.lineDiscountAmt)|currency
                            :format.currencyCode :format.symbol :format.numberFormat}}</span></h4>
                    <p mat-line>SKU : {{item.itemNumber}}, Discount: <b>{{item.lineDiscount}}%</b> </p>
                    <mat-divider></mat-divider>
                </mat-list-item>
            </mat-list>
            <mat-list>
                <mat-list-item>
                    <h4 mat-line><b><span class="text-right">{{ data.invoice.invoicedAmount|currency
                                :format.currencyCode :format.symbol :format.numberFormat }}</span></b></h4>
                </mat-list-item>
            </mat-list>
            <mat-toolbar color="">
                <mat-toolbar-row>
                    <span>Address</span>
                </mat-toolbar-row>
            </mat-toolbar>
            <mat-card>
                <mat-card-content>
                    <address *ngIf="data.invoice.shippingAddress.address" class="text-lowercase"><i
                            class="material-icons">location_on</i> <b>{{data.invoice.shippingAddress.name}}</b>,
                        {{data.invoice.shippingAddress.address}},{{data.invoice.shippingAddress.address2}},{{data.invoice.shippingAddress.city}}
                        - {{data.invoice.shippingAddress.postCode}}, {{data.invoice.shippingAddress.phoneNo}}</address>
                </mat-card-content>
                <mat-card-content>
                    <!-- <button mat-raised-button (click)="onPrint()" color="warn"><i class="material-icons">print</i> Print</button> -->
                </mat-card-content>
            </mat-card>

        </div>
        <div mat-dialog-content *ngIf="data.type=='view_offers'">
            <mat-list class="order-viewer-list invoice_view">
                <mat-list-item *ngFor="let item of data.order | async">
                    <h4 mat-line><b class="orange-txt text-lowercase">{{item.minOrderAmount |currency
                            :format.currencyCode :format.symbol :format.numberFormat }}</b></h4>
                    <p mat-line><label class="promo">{{item.promoNo}}</label> pre-applied on order worth
                        {{item.minOrderAmount |currency :format.currencyCode :format.symbol :format.numberFormat }} </p>
                    <mat-divider></mat-divider>
                </mat-list-item>
            </mat-list>
        </div>
        <div mat-dialog-content *ngIf='data.type=="import_Items"'>
            <div class="col-md-12">
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col">#SKU</th>
                            <th scope="col" class="text-right">{{('quickOrder.STOCKCHECK'|translate)}}</th>
                            <th scope="col" class="text-right">Back order Qty</th>
                            <th scope="col" *ngIf="configurations.alert.showETA" class="text-right">ETA</th>
                            <th scope="col" *ngIf="configurations.alert.showCartonQTY" class="text-right">
                                {{('catalog.CARTONQTY'|translate)}}</th>
                            <th scope="col" *ngIf="configurations.alert.showInnerPack" class="text-right">Inner pack</th>
                            <th scope="col" class="text-right">
                                <mat-checkbox [checked]='isChecked' (change)="isChecked=!isChecked;selectAllItems()"
                                    ngbTooltip="Allow all as back-order items"></mat-checkbox>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of importedItems">
                            <ng-container *ngIf="item.quantity>item.stock && item.isNotValid==false">
                                <th scope="row">{{item.sku}}</th>
                                <td class="text-right">{{item.stock}}</td>
                                <td class="text-right">{{item.quantity-item.stock}}</td>
                                <td *ngIf="configurations.alert.showETA" class="text-right">{{item.eta | date:format.dateFormat}}
                                    <span *ngIf="item.eta===null">NA</span>
                                </td>
                                <td *ngIf="configurations.alert.showCartonQTY" class="text-right"><span
                                        *ngIf="item.packQty!==0">{{item.packQty}}</span><span
                                        *ngIf="item.packQty===0">1</span> </td>
                                <td *ngIf="configurations.alert.showInnerPack" class="text-right"><span
                                        *ngIf="item.multiples>0">{{item.multiples}}</span><span
                                        *ngIf="item.multiples<1">1</span> </td>
                                <td class="text-center">
                                    <mat-checkbox [checked]='item.allowBackOrder==true'
                                        ngbTooltip="{{('quickOrder.ALLOWBACKORDER'|translate)}}"
                                        (change)="item.allowBackOrder=!item.allowBackOrder"></mat-checkbox>
                                </td>
                            </ng-container>

                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="col-md-12">
                <button mat-raised-button class="pull-right" (click)="closeDialog()" color="warn">Continue <i
                        class="material-icons">keyboard_arrow_right</i></button>
            </div>
        </div>
        <div mat-dialog-content *ngIf="data.type=='addLocation'">
            <form style="width: 100%;" [formGroup]="addressForm" (ngSubmit)="addAddress()">
                <mat-form-field appearance="outline" class="col-md-6 col-xs-12">
                    <mat-label>Name</mat-label>
                    <span matPrefix><i class="material-icons">location_on</i></span>
                    <input type="text" matInput placeholder="" formControlName="name">
                </mat-form-field>
                <mat-form-field appearance="outline" class="col-md-6 col-xs-12">
                    <mat-label>Name 2</mat-label>
                    <span matPrefix><i class="material-icons">location_on</i></span>
                    <input type="text" matInput placeholder="" formControlName="name2">
                </mat-form-field>
                <mat-form-field appearance="outline" class="col-md-6 col-xs-12">
                    <mat-label>Address</mat-label>
                    <span matPrefix><i class="material-icons">location_on</i></span>
                    <input type="text" matInput placeholder="" formControlName="address">
                </mat-form-field>
                <mat-form-field appearance="outline" class="col-md-6 col-xs-12">
                    <mat-label>Address 2</mat-label>
                    <span matPrefix><i class="material-icons">location_on</i></span>
                    <input type="text" matInput placeholder="" formControlName="address2">
                </mat-form-field>
                <mat-form-field appearance="outline" class="col-md-6 col-xs-12">
                    <mat-label>City</mat-label>
                    <span matPrefix><i class="material-icons">location_city</i></span>
                    <input type="text" matInput placeholder="city" formControlName="city">
                </mat-form-field>
                <mat-form-field appearance="outline" class="col-md-6 col-xs-12">
                    <mat-label>{{('basket.COUNTYSTATE'|translate)}}</mat-label>
                    <span matPrefix><i class="material-icons">location_on</i></span>
                    <input type="text" matInput placeholder="" formControlName="county">
                </mat-form-field>
                <mat-form-field appearance="outline" class="col-md-6 col-xs-12">
                    <mat-label>Zip Code</mat-label>
                    <span matPrefix><i class="material-icons">location_on</i></span>
                    <input type="text" matInput placeholder="" formControlName="post_Code">
                </mat-form-field>
                <!--formControlName="code"-->
                <!-- <mat-form-field appearance="outline" class="col-md-6 col-xs-12">
                    <mat-label>Code</mat-label>
                    <span matPrefix><i class="material-icons">location_searching</i></span>
                    <input type="text" matInput placeholder="code" disabled="true" >
                </mat-form-field> -->
                <mat-form-field appearance="outline" class="col-md-6 col-xs-12">
                    <mat-label>Contact Number</mat-label>
                    <span matPrefix><i class="material-icons">phone</i> <span>+1 &nbsp;</span></span>
                    <input type="text" matInput placeholder="5555551234" formControlName="contact">
                </mat-form-field>
                <!-- <mat-form-field appearance="outline" class="col-md-6 col-xs-12">
                    <mat-label>Phone No</mat-label>
                    <span matPrefix><i class="material-icons">phone</i></span>
                    <input type="text" matInput placeholder="" formControlName="phoneNo">
                </mat-form-field> -->
                <mat-form-field appearance="outline" class="col-md-6 col-xs-12">
                    <mat-label>e-Mail</mat-label>
                    <span matPrefix><i class="material-icons">email</i></span>
                    <input type="text" matInput placeholder="" formControlName="eMail">
                </mat-form-field>
                <!-- <mat-form-field appearance="outline" class="col-md-6 col-xs-12">
                    <mat-label>Fax</mat-label>
                    <span matPrefix><i class="material-icons">print</i></span>
                    <input type="text" matInput placeholder="" formControlName="fax_No">
                </mat-form-field> -->
                <mat-form-field appearance="outline" class="col-md-6 col-xs-12">
                    <mat-label>Country / RegionCode</mat-label>
                    <span matPrefix><i class="material-icons">account_balance</i></span>
                    <input type="text" matInput placeholder="" formControlName="countryRegionCode">
                </mat-form-field>
                <!-- <mat-form-field appearance="outline" class="col-md-6 col-xs-12">
                    <mat-label>Customer Code</mat-label>
                    <input type="text" matInput placeholder="XY00232" formControlName="customerNo">
                </mat-form-field> -->
                <mat-form-field appearance="outline" class="col-md-12 col-xs-12">
                    <mat-label>Comments</mat-label>
                    <input type="text" matInput placeholder="Comments" formControlName="comments">
                </mat-form-field>
                <!--[disabled]="!addressForm.valid"-->
                <button type="submit" mat-raised-button color="warn">Submit</button>
            </form>
        </div>
        <div mat-dialog-content *ngIf="data.type=='leadSelect'">
            <div style="width: 100%;">
                <mat-form-field class="example-full-width">
                    <mat-label>Search</mat-label>
                    <input matInput type="text" [(ngModel)]="leadFilterString" (input)="leadFilter()">
                    <button *ngIf="leadFilterString" matSuffix mat-icon-button aria-label="Clear">
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>
            </div>
            <mat-list class="">
                <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
                    [(ngModel)]="selectedLead">
                    <mat-list class="order-viewer-list invoice_view">
                        <mat-list-item *ngFor="let item of leadList">
                            <mat-radio-button class="example-radio-button" [value]="item.code"
                                style="text-transform: uppercase;">{{item.description}}</mat-radio-button>
                        </mat-list-item>
                    </mat-list>
                </mat-radio-group>
            </mat-list>
            <div class="col-xs-12 text-right">
                <button *ngIf="selectedLead" mat-raised-button color="primary" (click)="closeDialog()">Ok</button>
            </div>
        </div>
        <div mat-dialog-content *ngIf="data.type=='priceGroupSelect'">
            <!-- <div style="width: 100%;">
                <mat-form-field class="example-full-width">
                    <mat-label>Search</mat-label>
                    <input matInput type="text" [(ngModel)]="leadFilterString" (input)="leadFilter()">
                    <button *ngIf="leadFilterString" matSuffix mat-icon-button aria-label="Clear" >
                      <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>
            </div> -->
            <mat-list class="">
                <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
                    [(ngModel)]="selectedPriceGroup">
                    <mat-list class="order-viewer-list invoice_view">
                        <mat-list-item *ngFor="let item of custPriceGroupList">
                            <mat-radio-button class="example-radio-button" [value]="item"
                                style="text-transform: uppercase;">{{item}}</mat-radio-button>
                        </mat-list-item>
                    </mat-list>
                </mat-radio-group>
            </mat-list>
            <div class="col-xs-12 text-right">
                <button *ngIf="selectedPriceGroup" mat-raised-button color="primary" (click)="closeDialog()">Ok</button>
            </div>
        </div>
    </mat-card-content>
</mat-card>