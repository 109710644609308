import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { ApiConfigService } from 'src/app/core/services/api-config.service';
import { SecurityService } from 'src/app/core/services/security.service';
import { Observable } from 'rxjs';
import { Customer } from 'src/app/model/customer';

@Injectable()
export class CustomerService {

  constructor(private http: HttpClient,
    private apiSvc: ApiConfigService,
    private securityService: SecurityService) {
  }

  public getCustomer(params:any): Observable<Customer[]> {
    let url = this.apiSvc.formatUrl(this.apiSvc.apiConfig.customer.customerGetByPageApi);
    let httpParam: HttpParams = new HttpParams()
    .set('pageNumber', params.page)
    .set('pageSize', params.pageSize)     
    .set('filter', encodeURIComponent(params.filter))
    .set('registerStatus', params.registerStatus)
    .set('salesRepCode',params.custCode)
    .set('includeBlocked',params.blockStatus)
  return this.http.get<Customer[]>(url, {
    headers: this.getHeader(),
    params: httpParam
  });
  }

  getLeadList():Observable<any[]>{
    let url = this.apiSvc.formatUrl(this.apiSvc.apiConfig.customer.leadList);
    let httpParam: HttpParams = new HttpParams();
    return this.http.get<any[]>(url, {
      headers: this.getHeader(),
      params: httpParam
    });
  }


  getpriceGroup():Observable<any>{
    let url=this.apiSvc.formatUrl(this.apiSvc.apiConfig.customer.priceGroup);
    let httpParam:HttpParams=new HttpParams();
    return this.http.get<any[]>(url, {
      headers: this.getHeader(),
      params: httpParam
    });
  }

  selectPriceGroup(custPriceGroup:string):Observable<any>{
    let url=this.apiSvc.formatUrl(this.apiSvc.apiConfig.customer.leadList);
    let httpParam:HttpParams=new HttpParams();
    return this.http.post(url,{selectedPriceGroup:custPriceGroup});
  }

  getSalesPersonCode():Observable<any>{
    let url = this.apiSvc.formatUrl(this.apiSvc.apiConfig.customer.salesPerson);
    let httpParam: HttpParams = new HttpParams();
    return this.http.get<any[]>(url, {
      headers: this.getHeader(),
      params: httpParam
    });
  }

  getHeader(): HttpHeaders {
    return this.securityService.formatRequestHeader()
  }

  getIsAvailInvoiceCustomer():Observable<any>{
    let url = this.apiSvc.formatUrl(this.apiSvc.apiConfig.customer.getIsAvaiInvoiceCustomer);
    let httpParam: HttpParams = new HttpParams();
    return this.http.get<any[]>(url, {
      headers: this.getHeader(),
      params: httpParam
    });
  }

  public getCustomerAddressByInvoiceCustomerCode() {
    let url = this.apiSvc.formatUrl(this.apiSvc.apiConfig.customer.getCustomerAddressByInvoiceCustomerCode);
    let httpParam: HttpParams = new HttpParams();
    return this.http.get<any[]>(url, {
      headers: this.getHeader(),
      params: httpParam
    });
  }

}
