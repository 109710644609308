<div *ngIf="updatingField"><p> <span style="width: 50px;position: absolute;"><mat-spinner [color]="clr" [diameter]="dia"></mat-spinner></span> <span style="padding-left: 20px;">Validating quantity.</span></p></div>
<div class="quantity-box">
    <!--[disabled]="qtyDisable"-->
    
    <mat-form-field>
        <button *ngIf="multiples>0 && !getOrder" mat-stroked-button mat-icon-button matPrefix  (click)="decrement(quantity,multiples)" color="accent"><i class="material-icons">remove</i></button>
        <button *ngIf="multiples<1" mat-stroked-button mat-icon-button matPrefix  (click)="decrement(quantity,1)" color="accent"><i class="material-icons">remove</i></button>
        <button *ngIf="getOrder && multiples>0" mat-stroked-button mat-icon-button matPrefix  (click)="decrement(quantity,multiples)" color="accent"><i class="material-icons">remove</i></button>
        
        <input matInput class="text-center" min="0"
          type="number" 
          [formControl]="quantity" 
          (focus)="selectText($event)"
          [ngStyle]="{'text-align':'right'}"
          (keydown)="onKeydown($event)"
          >
          <!--(input)="onBlur()" (blur)="onBlur()"-->
      <button *ngIf="multiples>0 && !getOrder" mat-stroked-button mat-icon-button matSuffix  (click)="increment(quantity,multiples)" color="primary"><i class="material-icons">add</i></button>  
      <button *ngIf="multiples<1" mat-stroked-button mat-icon-button matSuffix  (click)="increment(quantity,1)" color="primary"><i class="material-icons">add</i></button>  
      <button *ngIf="getOrder && multiples>0" mat-stroked-button mat-icon-button matSuffix  (click)="increment(quantity,multiples)" color="primary"><i class="material-icons">add</i></button>  

          <mat-error *ngIf="quantity.errors?.multiples">
              Enter in multiples of {{multiples}}.
          </mat-error>
          <mat-error *ngIf="quantity.errors?.required">
              Qty required and Should be Numeric.
          </mat-error>
    </mat-form-field>
      
      <small *ngIf="quantity.invalid 
    && (quantity.dirty || quantity.touched)" class="text-danger">
    </small>
</div>



