<p *ngIf="message!=''" class="orange-txt"><i class="material-icons">check_circle_outline</i> {{message}}</p>
<form [formGroup]="emailForm">
    <label>Order number : <span class="orange-txt">{{orderno}}</span> </label>
    <mat-form-field>
      <mat-label>Enter email id's as comma separated values </mat-label>
        <mat-chip-list #chipList aria-label="Fruit selection">
          <mat-chip *ngFor="let email of emails" color="accent" [selectable]="selectable"
                   [removable]="removable" (removed)="remove(email)">
            {{email}}
            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
          </mat-chip>
          <input formControlName="emails" placeholder=""
                 [matChipInputFor]="chipList"
                 [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                 [matChipInputAddOnBlur]="addOnBlur"
                 (matChipInputTokenEnd)="add($event)">
        </mat-chip-list>
      </mat-form-field>
    <!-- <mat-form-field class="example-full-width">
        <textarea matInput formControlName="emails" placeholder="Emails"></textarea>
    </mat-form-field> -->
    <button mat-flat-button (click)="sendMail()" color="accent">Send</button>
</form>