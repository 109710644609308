import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WishlistService {
  wishlistItems:any[]=[];
  private wishlistSubject=new BehaviorSubject(this.wishlistItems);
  wishlist=this.wishlistSubject.asObservable();
  
  constructor() {
    // this.wishlistSubject.subscribe(data=>{
    //   console.log(data);
    // })
   }
  addItemToWishlist(itemNumber:any){
      this.wishlistItems.push(itemNumber);  
  }
  changeWishlist(wishlist:any){
    this.wishlistSubject.next(wishlist);
  }
  getAllItems(){
    return this.wishlistItems;
  }
  removeWishlistItem(itemNumber:any){
    this.wishlistItems.splice(1,this.wishlistItems.indexOf(itemNumber));
  }
  removeAllWishlistItems(){
    this.wishlistItems=[];
    this.changeWishlist(this.wishlistItems);
  }
}
