<div class="row panel-row" #showroomPanelContainer>
    <div class="panel-container" #showroomPanel>
      <div class="frame-headers">
        <button mat-mini-fab color="primary" [matMenuTriggerFor]="menu" aria-label="Example icon button with a menu icon">
          <mat-icon>more_horiz</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <a class="nav-link" (click)="btnExitFullScreen()" [routerLink]="['/home']">Home</a>
          <a class="nav-link"  (click)="btnExitFullScreen()" [routerLink]="['/catalog']">Catalog</a>
          <a class="nav-link"  (click)="btnExitFullScreen()" [routerLink]="['/basket']">Basket</a>
        </mat-menu>
        <button mat-mini-fab color="primary" (click)="btnFullScreen()" aria-label="Example icon button with a menu icon">
          <mat-icon>fullscreen</mat-icon>
        </button>
        <button mat-mini-fab color="warn" (click)="btnExitFullScreen()" aria-label="Example icon button with a menu icon">
          <mat-icon>clear</mat-icon>
        </button>
      </div>
        <iframe style="width: 100%;height:100vh;border:0px;" src="https://players.cupix.com/p/7YEgdxY1">
            <p>Your browser does not support iframes.</p>
        </iframe>
    </div>
</div>