import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html'

})
export class AddressComponent implements OnInit {
  @Input() myform!: FormGroup;
  constructor() { }

  ngOnInit() {
  }

  ReturnFormControl(pControl:string)
  {
    return (<FormControl>(this.myform).controls[pControl]);
  }
}
