import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild, Router } from '@angular/router';

import { SecurityService } from './core/services/security.service';
import { Observable } from 'rxjs';
import { BasketService } from './core/services/basket.service';
import { LocaleService } from './core/services/locale.service';
import { SettingsService } from './core/services/settings.service';

@Injectable()
export class RoutingGuard implements CanActivate, CanActivateChild {

  empRoles=['site_admin','rep_admin','rep_user'];
  public showOffers:boolean | any=false;

  constructor(private securityService: SecurityService,
    private basketService:BasketService,
    private router: Router,
    private settingsService:SettingsService) {
      this.settingsService.settings.subscribe(data=>{
        this.showOffers=data.SHOWOFFER
      })
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    return this.isLoggedIn(childRoute, state);
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.isLoggedIn(next, state);
  }

  canLoad(){
    let canload=false;

    return canload=true;
  }

  async getSettings(){
    let load:any=false;
    let canLoad= new Promise(()=>{
      load =this.settingsService.getSettings().SHOWOFFER;
    });

    await canLoad.then(()=>{return load})
  }

  async isLoggedIn(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    let retValue: boolean = false;
    //-- New code
    let promis=this.securityService.getUser();
    let user= await promis;
    if (user) {
            if(this.empRoles.length>0 && 
                this.basketService.getCustomer()==null)
            {            
              this.router.navigate(['/customer']);
              retValue= false;
            }
            else{
            retValue = true;
            }
          }
          else {
            this.router.navigate(['/access']);
            retValue = false;
          }
    //-- End

    // await this.securityService.getUser()
    //   .then((user) => {
    //     if (user) {
    //       if(this.empRoles.includes(this.securityService.userRole) && 
    //           this.basketService.getCustomer()==null)
    //       {            
    //         this.router.navigate(['/customer']);
    //         retValue= false;
    //       }
    //       else{
    //       retValue = true;
    //       }
    //     }
    //     else {
    //       this.router.navigate(['/access']);
    //       retValue = false;

    //     }
    //   })
    //   .catch((err) => {
    //     this.router.navigate(['/access']);
    //     retValue = false;
    //   });


    return retValue;
  }

}
