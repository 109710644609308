import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IConfigurations, SettingsModel } from '../models/settings.model';
import { ApiConfigService } from './api-config.service';
import { HttpParams, HttpClient, HttpHeaders } from '@angular/common/http';
import { SecurityService } from './security.service';
import { BasketService } from './basket.service';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  private settingsBehaviorSubject:BehaviorSubject<SettingsModel>= new BehaviorSubject(this.initSettings())
  settings:Observable<SettingsModel>=this.settingsBehaviorSubject.asObservable();

  constructor(public apiSvc:ApiConfigService,public http:HttpClient,
    public securityService:SecurityService,public basketService:BasketService) { }
  initSettings():SettingsModel{
    let initialSettings={
                      DEFAULTSORTORDER:"",
                      MINCARTVALUE:"",
                      NOOFFERPRICEGROUP:"",
                      SENDEMAILTOREP:"",
                      SHOWOFFER:false,
                      ZEROVALUECATEGORY:"",
                      SHOWDISCOUNTONCART:false,
                      ISSUBSCRIBED:false
                  }
    return initialSettings;
  }

  setSettings(Settings?:SettingsModel){
    this.settingsBehaviorSubject.next(Settings!)
  }
  private getHeader(): HttpHeaders {
    return this.securityService.formatRequestHeader(this.basketService.getCustomerCode(),this.basketService.getPriceGroup());  
  }
  getAppSettings(params:any,tenant:any):Observable<SettingsModel>{
    let customer=this.basketService.getCustomerCode();
    let settingURL=this.apiSvc.formatUrl(this.apiSvc.apiConfig.appSettings);
    let httpParams:HttpParams=new HttpParams();
    // .set('tenant',tenant);
    let requestHeader=this.getHeader();
    if (customer) {
      requestHeader=this.getHeader().append('CustomerCode', customer);
    }
    
    return this.http.get(settingURL,{headers:requestHeader,params:httpParams});
  }
  getSettings():SettingsModel{
    return this.settingsBehaviorSubject.getValue();
  }
  updateSettings(updateable?:any){
    let settings=this.getSettings();
    settings.ISSUBSCRIBED=updateable.ISSUBSCRIBED;
    this.setSettings(settings);
  }

  getAppConfigurations(url:string): Observable<any> {
    return this.http.get<any>(url, { headers: {}, params: {} });
  }
 
}
