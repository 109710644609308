import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-business-ref',
  templateUrl: './business-ref.component.html'
})
export class BusinessRefComponent implements OnInit {
  @Input() myform!: FormGroup;
  constructor() { }

  ngOnInit() {
  }

  ReturnFormControl(pControl:string)
  {
    return (<FormControl>(this.myform).controls[pControl]);
  }

}
