import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { LoaderserviceService } from 'src/app/core/services/loaderservice.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {
  public isLoading!:Observable<boolean>;
  constructor(public loaderService:LoaderserviceService) { }

  ngOnInit() {
    this.isLoading=this.loaderService.isLoading
  }

}
