<div class="{{localeService.code}}-footer">   
                <div class="container">
                        <mat-toolbar color="" class="footer-bar">
                                <mat-toolbar-row>
                                          <mat-card class="example-card" *ngIf="localeService.localeConfig && localeService.localeConfig.address.line_1!=''">
                                            <mat-card-header>
                                                <mat-card-title><h4>OUR LOCATION</h4></mat-card-title>
                                            </mat-card-header>                                              
                                            <mat-card-content>
                                                <mat-list  role="list" *ngIf="localeService.localeConfig">
                                                    <mat-list-item role="listitem">{{localeService.localeConfig.address.line_1}}</mat-list-item>
                                                    <mat-list-item  role="listitem">{{localeService.localeConfig.address.line_2}}</mat-list-item>
                                                    <mat-list-item  role="listitem">{{localeService.localeConfig.address.line_3}}</mat-list-item>
                                                </mat-list>
                                            </mat-card-content>
                                           </mat-card>
                                           <mat-card class="example-card">
                                                <mat-card-header>
                                                <mat-card-title><h4>CONTACT</h4></mat-card-title>
                                                </mat-card-header>
                                                <mat-card-content>
                                                    <mat-list role="list" *ngIf="localeService.localeConfig">
                                                        <mat-list-item role="listitem" *ngIf="localeService.localeConfig.contact.phone1!=''"><i class="material-icons">call</i>&nbsp; {{localeService.localeConfig.contact.phone1}} <span class="orange-txt" *ngIf="configurations.footer.showTollFreeandLocalText">&nbsp; (Toll-Free)</span></mat-list-item>
                                                        <mat-list-item role="listitem" *ngIf="localeService.localeConfig.contact.phone2!=''"><i class="material-icons">call</i>&nbsp; {{localeService.localeConfig.contact.phone2}} <span class="orange-txt" *ngIf="configurations.footer.showTollFreeandLocalText"> &nbsp;(Local)</span></mat-list-item>
                                                        <mat-list-item role="listitem" *ngIf="localeService.localeConfig.contact.fax!=''"><i class="fa fa-paper-plane"></i> {{localeService.localeConfig.contact.fax}} <span class="orange-txt">&nbsp; (Fax)</span></mat-list-item>
                                                        <mat-list-item role="listitem" *ngIf="localeService.localeConfig.contact.email!=''"><i class="fa fa-envelope"></i> {{localeService.localeConfig.contact.email}} </mat-list-item>
                                                    </mat-list>                                                        
                                                </mat-card-content>
                                           </mat-card>
                                           <span class="example-spacer"></span>
                                           <mat-card class="example-card">
                                             <img mat-card-image src="assets/img/logo.png" />
                                             <mat-card-actions *ngIf="localeService.localeConfig">
                                               <a target="blank" *ngIf="localeService.localeConfig.social.facebook!=''" href="{{localeService.localeConfig.social.facebook}}" mat-icon-button color=""><i class="fa fa-facebook"></i></a>
                                               <span class="example-spacer" *ngIf="localeService.localeConfig.social.pinterest!=''"></span>
                                               <a target="blank" *ngIf="localeService.localeConfig.social.pinterest!=''" href="{{localeService.localeConfig.social.pinterest}}" mat-icon-button color=""><i class="fa fa-pinterest"></i></a>
                                               <span class="example-spacer" *ngIf="localeService.localeConfig.social.pinterest!=''"></span>
                                               <a target="blank" *ngIf="localeService.localeConfig.social.youtube!=''" href="{{localeService.localeConfig.social.youtube}}" mat-icon-button color=""><i class="fa fa-youtube"></i></a>
                                               <span class="example-spacer" *ngIf="localeService.localeConfig.social.youtube!=''"></span>
                                               <a target="blank" *ngIf="localeService.localeConfig.social.insta!=''" href="{{localeService.localeConfig.social.insta}}" mat-icon-button color=""><i class="fa fa-instagram"></i></a>
                                             </mat-card-actions>
                                           </mat-card>
                               </mat-toolbar-row>
                               <mat-toolbar-row class="partners">
                                  <span class="example-spacer"></span>
                                  <mat-card class="example-card" *ngIf="localeService.localeConfig && localeService.localeConfig.others.partners==true">
                                      <mat-card-header>
                                        <mat-card-title><h4>OUR PARTNERS</h4></mat-card-title>
                                      </mat-card-header>                                 
                                      <mat-card-content>
                                        <img src="assets/img/footerlogo-1.jpg" alt="">
                                        <img src="assets/img/footerlogo-2.jpg" alt="">
                                        <img src="assets/img/footerlogo-4.jpg" alt="">
                                        <img *ngIf="configurations.footer.showRSPBImg" src="assets/img/rspb.jpg" alt="" style="max-width: 120px;">
                                      </mat-card-content>
                                    </mat-card>
                               </mat-toolbar-row>
                             </mat-toolbar>
                </div>
            <div [ngClass]="{'footer-bottom':configurations.footer.showRightReservedtext}" class="text-cente">
                    © {{data | date: 'yyyy'}}, Wild Republic. All rights reserved.
            </div>
            <div [ngClass]="{'best-viewed':configurations.footer.showBrowserSupportInfoText}" class="text-center">
                <label><span style="text-decoration: underline;">The site is best viewed in:</span> Microsoft Edge, Internet Explorer v11, Safari v10 and above, Firefox and Chrome</label>
            </div>
        </div>
     
      