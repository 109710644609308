import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-ghost-list',
  templateUrl: './ghost-list.component.html',
  styleUrls: ['./ghost-list.component.scss']
})
export class GhostListComponent implements OnInit {
  @Input() ghosts!: any[];
  constructor() { }

  ngOnInit() {
    //this.ghosts=new Array(10);
  }

}
