<div fxLayout="column" fxLayoutGap="10px" [ngClass]="toastPackage.title == 'Customer'?'customer-toastr':'toastr'">
    <div fxLayout="row" *ngIf="toastPackage.title != '' && toastPackage.title != 'Customer'">
        <b [ngClass]="toastPackage.toastType == 'info' ? 'info-txt': toastPackage.toastType == 'danger' ? 'error-txt': toastPackage.toastType == 'warning' ? 'warning-txt': toastPackage.toastType == 'success' ? 'success-txt': 'default-txt'">{{toastPackage.title}}</b>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between center" class="white-txt">
        <mat-icon *ngIf="toastPackage.title == 'Customer'" class="customer-icon">info</mat-icon>
        <span [ngClass]="toastPackage.title == 'Customer'?'customer-txt':'white-txt'" >{{toastPackage.message}}</span>
        <mat-icon *ngIf="toastPackage.title == 'Customer'"  class="customer-icon" (click)="onClose(toastPackage.toastId)">close</mat-icon>
    </div>
    <div fxLayout="row" fxLayoutAlign="flex-end" *ngIf="options && toastPackage.title != 'Customer'">
        <div *ngFor="let btn of buttons?.buttons">
            <button mat-button (click)="onClose(toastPackage.toastId)" class="btn-color">{{btn.name}}</button>
        </div>        
    </div>
</div>