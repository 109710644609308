<nav class="navbar navbar-expand-lg bg-white fixed-top navbar-transparent" color-on-scroll="500">
    <!-- <span *ngFor="let likedProduct of likedProducts;let i = index"><span *ngIf="i%2!=0" style="color:black">{{likedProduct.no}} {{likedProduct.description}}</span></span> -->
    <div class="container">
        <div class="navbar-translate">
            <a class="navbar-brand" placement="bottom" ngbTooltip="Wild Republic">
                <img class="img-fluid" src="assets/img/logo.png" />
            </a>
            <!-- <img class="years img-max-height"
                src="https://cdn.shopify.com/s/files/1/0115/4891/7819/files/Asset_1.png?15017172963985687810" alt=""> -->
            <span class="example-spacer"></span>
            <button class="hidden-lg visible-xs" mat-button color="" [matMenuTriggerFor]="menu"
                aria-label="Example icon-button with a menu">
                <mat-icon class="favorite" matBadge="{{getImageListCount()|async}}" matBadgeSize="large"
                    matBadgePosition="below after" matBadgeColor="warn" color="warn">photo_camera</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="onDownloadImages()">
                    <mat-icon>save_alt</mat-icon>
                    <span>Download</span>
                </button>
                <button mat-menu-item (click)="onImageListClear()">
                    <mat-icon>clear</mat-icon>
                    <span>Clear</span>
                </button>
                <!-- <button mat-menu-item (click)="onViewList()">
                <mat-icon>remove_red_eye</mat-icon>
                <span>View all</span>
            </button> -->
            </mat-menu>
            <a mat-button class="visible-xs hidden-lg" [routerLink]="['/basket']">
                <!-- <svg version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 489 489" style="enable-background:new 0 0 489 489;" xml:space="preserve" width="50px" height="32px" class=""><g transform="matrix(0.94148 0 0 0.94148 14.3081 14.3081)"><g>
                        <path d="M440.1,422.7l-28-315.3c-0.6-7-6.5-12.3-13.4-12.3h-57.6C340.3,42.5,297.3,0,244.5,0s-95.8,42.5-96.6,95.1H90.3   c-7,0-12.8,5.3-13.4,12.3l-28,315.3c0,0.4-0.1,0.8-0.1,1.2c0,35.9,32.9,65.1,73.4,65.1h244.6c40.5,0,73.4-29.2,73.4-65.1   C440.2,423.5,440.2,423.1,440.1,422.7z M244.5,27c37.9,0,68.8,30.4,69.6,68.1H174.9C175.7,57.4,206.6,27,244.5,27z M366.8,462   H122.2c-25.4,0-46-16.8-46.4-37.5l26.8-302.3h45.2v41c0,7.5,6,13.5,13.5,13.5s13.5-6,13.5-13.5v-41h139.3v41   c0,7.5,6,13.5,13.5,13.5s13.5-6,13.5-13.5v-41h45.2l26.9,302.3C412.8,445.2,392.1,462,366.8,462z" data-original="#000000" class="active-path" data-old_color="#fa4c06" fill="#fa4c06"/>
                    </g></g> </svg> -->
                <mat-icon class="favorite" matBadge="{{getBasketCount()|async}}" matBadgePosition="below after"
                    matBadgeSize="large" matBadgeSize="large" matBadgeColor="warn" color="warn">shopping_cart</mat-icon>
            </a>
            <button mat-icon-button class="navbar-toggler menu-nav-margin" type="button" data-toggle="collapse"
                data-target="#navbarToggler" aria-controls="navbarTogglerDemo02" aria-expanded="false"
                aria-label="Toggle navigation" (click)="sidebarToggle()">
                <span class="navbar-toggler-bar bar1"></span>
                <span class="navbar-toggler-bar bar2"></span>
                <span class="navbar-toggler-bar bar3"></span>
            </button>
        </div>
        <span class="example-spacer hidden-xs visible-lg"></span>
        <div class="collapse navbar-collapse" style="display:block !important"
            data-nav-image="assets/img/blurred-image-1.jpg" data-color="orange">
            <div>
                <ul style="margin: 0;padding: 0px 63px;">
                    <li *ngIf="configurations.navBar.showVRRoomGif" class="nav-item">
                        <a style="padding: 0;" class="nav-link" [routerLink]="['/showroom']">
                            <img src="assets/img/vs.gif" alt="">
                        </a>
                    </li>
                </ul>
            </div>
            <div class="navbar-nav">
                <span class="example-spacer hidden-xs visible-lg"></span>
                <ul class="navbar-nav">
                    <!--ml-auto-->
                    <li class="nav-item" *ngIf="configurations.navBar.showSafetyRecall">
                        <a class="nav-link impNotice" href="https://www.wildrepublic.com/important-notice/"
                            target="_blank">
                            <p>Safety Recall</p>
                        </a>
                    </li>
                    <!-- <li class="nav-item" *ngIf="localeService.code=='au'">
                    <a class="nav-link impNotice" href="http://www.wildrepublic.com.au/competition/" target="_blank">
                        <p>Halloween Competition</p>
                    </a>
                </li> -->
                    <li class="nav-item">
                        <a [ngClass]="{'active-menu':router.url == '/home'}" class="nav-link"
                            [routerLink]="['/home']">
                            <p>Home</p>
                        </a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" [ngClass]="{'active-menu':router.url=='/tradeshow'}"
                          [routerLink]="['/tradeshow']">
                          <p>Trade Show</p>
                      </a>
                  </li>
                    <li class="nav-item" *ngIf="IsAvailInvoiceCustomer">
                        <a class="nav-link"
                            [ngClass]="{'active-menu':router.url=='/customer/invoiceaccount'}"
                            [routerLink]="['/customer/','invoiceaccount']">
                            <p>Invoice Account</p>
                        </a>
                    </li>
                    <a ngbDropdown *ngIf="configurations.navBar.showQuickOrder" class="nav-item dropdown">
                        <div [ngClass]="{'active-menu':(router.url =='/quickorder/byentry' || router.url == '/quickorder/byimport')}" class="nav-link dropdown-toggle"
                            ngbDropdownToggle>
                            <p>Quick Order</p>
                        </div>
                        <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right">
                            <a class="dropdown-item"
                                *ngIf="localeService.localeConfig && localeService.localeConfig.others.showQuickOrder==true"
                                [routerLink]="['/quickorder/byentry']">
                                <i class="now-ui-icons shopping_box"></i>By Entry </a>
                            <a class="dropdown-item"
                                [routerLink]="['/quickorder/byimport']">
                                <i class="now-ui-icons ui-2_settings-90"></i> By Import</a>
                            <a class="dropdown-item" [routerLink]="['/quickorder/byscan']">
                                <i class="now-ui-icons ui-2_settings-90"></i> By Scan / Entry</a>
                        </div>
                    </a>
                    <li class="nav-item">

                        <a [ngClass]="{'active-menu':router.url == '/catalog'}" class="nav-link"
                            [routerLink]="['/catalog']">
                            <!-- <i class="now-ui-icons design_app"></i> -->
                            <p *ngIf="localeService.localeConfig">{{localeService.localeConfig.menu.catalog}}</p>
                        </a>
                    </li>
                    <li class="nav-item" *ngIf="showOffer">
                        <a [ngClass]="{'active-menu':router.url == '/offers'}" [routerLink]="['/offers']" class="nav-link"
                            style="position: relative;">
                            <img src="assets/img/new.png" class="hidden-xs"
                                style="position: absolute;width: 30px;right: 2px;top: -8px;" alt="">
                            <p *ngIf="localeService.localeConfig">{{localeService.localeConfig.menu.offers}}</p>
                        </a>
                    </li>
                    <a ngbDropdown class="nav-item dropdown">
                        <div [ngClass]="{'active-menu':(router.url == '/manage/order' || router.url == '/manage/invoice')}" class="nav-link dropdown-toggle"
                            ngbDropdownToggle>
                            <!-- <i class="now-ui-icons files_paper" aria-hidden="true"></i> -->
                            <p>Manage</p>
                        </div>
                        <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right">
                            <a class="dropdown-item" [routerLink]="['/manage/order']">
                                <i class="now-ui-icons shopping_box"></i>Orders</a>
                            <a class="dropdown-item" [routerLink]="['/manage/invoice']">
                                <i class="now-ui-icons ui-2_settings-90"></i>Invoice</a>
                        </div>
                    </a>

                </ul>
                <!-- <span class="example-spacer hidden-xs visible-lg"></span> -->
                <ul class="nav navbar-nav navbar-right">
                    <a ngbDropdown class="nav-item dropdown hidden-xs visible-lg">
                        <div class="nav-link dropdown-toggle text-primary" ngbDropdownToggle>
                            <mat-icon class="favorite pull-left" matBadge="{{getImageListCount()|async}}"
                                matBadgeSize="large" matBadgePosition="below after" matBadgeColor="warn"
                                color="primary">photo_camera</mat-icon><span
                                class="hidden-lg visible-xs pull-left">({{getImageListCount()|async}}) Image List</span>
                        </div>
                        <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right">
                            <a class="dropdown-item" (click)="onDownloadImages()">
                                <i class="fa fa-download"></i>Download</a>
                            <!-- <a class="dropdown-item" (click)="onViewList()">
                                    <i class="fa fa-eraser"></i>View</a>                                     -->
                            <a class="dropdown-item" (click)="onImageListClear()">
                                <i class="fa fa-eraser"></i>Clear</a>
                        </div>
                    </a>

                    <a ngbDropdown class="nav-item dropdown" *ngIf="isLoggedIn()">
                        <div class="nav-link dropdown-toggle user-actions" ngbDropdownToggle>
                            <!-- <i class="now-ui-icons users_single-02" aria-hidden="true"></i> -->
                            <p><i class="material-icons">person_outline</i> {{getUserName()}}</p>
                        </div>
                        <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right">
                            <a class="dropdown-item" (click)="onMyAccount()">
                                <i class="now-ui-icons business_badge"></i>My Account</a>
                            <a class="dropdown-item" (click)="onManageUsers()">
                                <i class="fa fa-users"></i>Manage Users</a>
                            <a class="dropdown-item" (click)="onLogout()">
                                <i class="fa fa-sign-out"></i>Log Out</a>
                        </div>
                    </a>
                    <li class="nav-item visible-lg hidden-xs cart-animate">
                        <a class="nav-link" [routerLink]="['/basket']">
                            <!-- <svg version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 489 489" style="enable-background:new 0 0 489 489;" xml:space="preserve" width="50px" height="32px" class=""><g transform="matrix(0.94148 0 0 0.94148 14.3081 14.3081)"><g>
                                <path d="M440.1,422.7l-28-315.3c-0.6-7-6.5-12.3-13.4-12.3h-57.6C340.3,42.5,297.3,0,244.5,0s-95.8,42.5-96.6,95.1H90.3   c-7,0-12.8,5.3-13.4,12.3l-28,315.3c0,0.4-0.1,0.8-0.1,1.2c0,35.9,32.9,65.1,73.4,65.1h244.6c40.5,0,73.4-29.2,73.4-65.1   C440.2,423.5,440.2,423.1,440.1,422.7z M244.5,27c37.9,0,68.8,30.4,69.6,68.1H174.9C175.7,57.4,206.6,27,244.5,27z M366.8,462   H122.2c-25.4,0-46-16.8-46.4-37.5l26.8-302.3h45.2v41c0,7.5,6,13.5,13.5,13.5s13.5-6,13.5-13.5v-41h139.3v41   c0,7.5,6,13.5,13.5,13.5s13.5-6,13.5-13.5v-41h45.2l26.9,302.3C412.8,445.2,392.1,462,366.8,462z" data-original="#000000" class="active-path" data-old_color="#ffa500" fill="#ffa500"/>
                            </g></g> </svg> -->
                            <!-- <i class="material-icons" style="color:#fa4c06">shopping_cart</i>
                            <i class="cart-icon icon fa-2x"><span>{{getBasketCount()|async}}</span></i> -->
                            <mat-icon class="favorite" matBadgePosition="below after" matBadgeSize="large"
                                color="primary" matBadge="{{getBasketCount()|async}}" matBadgeColor="warn">shopping_cart
                            </mat-icon>
                            <!-- <i class="icon fa fa-shopping-cart fa-2x">&nbsp;&nbsp;<span>{{getBasketCount()|async}}</span></i> -->
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</nav>
