<mat-toolbar *ngIf="this.currentPage!='/access'" color="" class="landing-header">
  <mat-toolbar-row class="container">
          <!-- <img class="years" src="assets/img/Asset_1.png" alt=""> -->
          <!-- <span class="example-spacer"></span> -->
          <img  class="img-fluid" src="assets/img/logo.png" />
          <span class="example-spacer"></span>
  </mat-toolbar-row>
  <mat-toolbar-row class="">
    <span class="example-spacer"></span>
    <!-- <a mat-button color="primary" [routerLink]="['/retailer']"><i class="material-icons">work_outline</i> <span class="hidden-xs"> Retailer request</span></a> -->
    <button mat-button (click)="onHome()" ><span class="visible-xs hidden-lg"><i class="material-icons">home</i></span><span class="hidden-xs"> Home</span></button>
    <span class="example-spacer visible-xs hidden-lg"></span>
    <button mat-button  (click)="onRetailRequest()" ><span class="visible-xs hidden-lg"><i class="material-icons">work_outline</i></span> <span class="hidden-xs"> Retailer request</span></button>
    <span class="example-spacer visible-xs hidden-lg"></span>
    <button mat-button  (click)="onRegister()"><span class="visible-xs hidden-lg"><i class="material-icons">work_outline</i></span> <span class="hidden-xs">Register</span></button>
    <span class="example-spacer visible-xs hidden-lg"></span>
    <button mat-button (click)="onLogin()"><span class="visible-xs hidden-lg"><i class="material-icons">person</i></span><span class="hidden-xs">Login</span></button>
    <span class="example-spacer visible-xs hidden-lg"></span>
    <button mat-button *ngIf="configurations.layoutOppBuy.showHelpButton" (click)="onHelp()"><span class="visible-xs hidden-lg"><i class="material-icons">person</i></span><span class="hidden-xs">Help</span></button>
    <!-- <button mat-button *ngIf="localeCode=='au'" (click)="onNews()"><span class="visible-xs hidden-lg"><i class="material-icons">notifications</i></span><span class="hidden-xs">Newsletter</span></button> -->
    <!-- <button mat-button color="warn"  (click)="onOppBuy()" ><i class="material-icons">star_border</i> <span class="hidden-xs"> Opportunity Buy</span></button>
    <span class="example-spacer visible-xs hidden-lg"></span> -->
    <span class="example-spacer"></span>
  </mat-toolbar-row>
</mat-toolbar>
<div class="oppbuy-outer">
  <app-loader></app-loader>
  <router-outlet></router-outlet>
</div>

