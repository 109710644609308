<div [formGroup]="myform" class="row">

    <div class="col-sm-6">
        <div class="form-group">
            <label>Account Payable Name</label>
            <div>
                <input class="form-control" type="text" [formControl]="ReturnFormControl('accountPayableName')" />
                <span *ngIf="ReturnFormControl('accountPayableName').hasError('required')
                                && ReturnFormControl('accountPayableName').touched"
                    class="text-danger">
                    You must include Account Payable Name.
                </span>
            </div>
        </div>
        <div class="form-group">
            <label>phone</label>
            <div>
                <input class="form-control" type="text" [formControl]="ReturnFormControl('phone')" />
                <span *ngIf="ReturnFormControl('phone').hasError('required')
                              && ReturnFormControl('phone').touched"
                    class="text-danger">
                    You must include phone.
                </span>
            </div>
        </div>

    </div>
    <div class="col-sm-6">
        <div class="form-group">
            <label>fax</label>
            <div>
                <input class="form-control" type="text" [formControl]="ReturnFormControl('fax')" />
                <span *ngIf="ReturnFormControl('fax').hasError('required')
                                    && ReturnFormControl('fax').touched"
                    class="text-danger">
                    You must include fax.
                </span>
            </div>
        </div>
        <div class="form-group">
            <label>email</label>
            <div>
                <input class="form-control" type="text" [formControl]="ReturnFormControl('email')" />
                <span *ngIf="ReturnFormControl('email').hasError('required')
                                          && ReturnFormControl('email').touched"
                    class="text-danger">
                    You must include email.
                </span>
                <span *ngIf="ReturnFormControl('email').hasError('pattern')" class="text-danger">
                    Enter a Valid Email.
                </span>
            </div>
        </div>
    </div>


    <div class="col-sm-6">

        <div class="form-group">
            <label>bankName</label>
            <div>
                <input class="form-control" type="text" [formControl]="ReturnFormControl('bankName')" />
                <span *ngIf="ReturnFormControl('bankName').hasError('required')
                                          && ReturnFormControl('bankName').touched"
                    class="text-danger">
                    You must include bankName.
                </span>
            </div>
        </div>

        <div class="form-group">
            <label>savingAccountNumber</label>
            <div>
                <input class="form-control" type="text" [formControl]="ReturnFormControl('savingAccountNumber')" />
                <span *ngIf="ReturnFormControl('savingAccountNumber').hasError('required')
                                          && ReturnFormControl('savingAccountNumber').touched"
                    class="text-danger">
                    You must include savingAccountNumber.
                </span>
            </div>
        </div>
        <div class="form-group">
            <label>checkingAccountNumber</label>
            <div>
                <input class="form-control" type="text" [formControl]="ReturnFormControl('checkingAccountNumber')" />
                <span *ngIf="ReturnFormControl('checkingAccountNumber').hasError('required')
                                          && ReturnFormControl('checkingAccountNumber').touched"
                    class="text-danger">
                    You must include checkingAccountNumber.
                </span>
            </div>
        </div>
        <div class="form-group">
            <label>otherAccountNumber</label>
            <div>
                <input class="form-control" type="text" [formControl]="ReturnFormControl('otherAccountNumber')" />
                <span *ngIf="ReturnFormControl('otherAccountNumber').hasError('required')
                                          && ReturnFormControl('otherAccountNumber')!.touched"
                    class="text-danger">
                    You must include otherAccountNumber.
                </span>
            </div>
        </div>

    </div>
    <app-validation-summary [form]="myform"></app-validation-summary>
    <div class="col-sm-6">
        <app-address [myform]="ReturnMyFormWithControl('bankAddress')"></app-address>
    </div>

</div>