import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ITradeShow } from 'src/app/model/tradeshow';
import { ApiConfigService } from './api-config.service';
import { BasketService } from './basket.service';
import { SecurityService } from './security.service';

@Injectable({
  providedIn: 'root'
})
export class TradeshowService {

  constructor(private http: HttpClient,
    private apiSvc: ApiConfigService,
    private securityService: SecurityService,
    private basketService:BasketService) {

  }

  tradeShowSchdule() {
    let url = this.apiSvc.formatUrl(this.apiSvc.apiConfig.tradeShow);
    return this.http.get<ITradeShow[]>(url, {
      headers: this.getHeader()
    });
  }

  private getHeader(): HttpHeaders {
    return this.securityService.formatRequestHeader(this.basketService.getCustomerCode(),this.basketService.getPriceGroup());
  }
}
