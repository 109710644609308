<block-ui>
<!-- <mat-toolbar color="" class="landing-header">
        <mat-toolbar-row class="container">
                <img class="years" src="assets/img/Asset_1.png" alt="">
                <span class="example-spacer"></span>
                <img  class="img-fluid" src="assets/img/logo.png" />
                <span class="example-spacer"></span>
        </mat-toolbar-row>
        <mat-toolbar-row class="">
          <span class="example-spacer"></span>
          <button mat-button (click)="onHome()" ><span class="visible-xs hidden-lg"><i class="material-icons">home</i></span><span class="hidden-xs"> Home</span></button>
          <span class="example-spacer visible-xs hidden-lg"></span>
          <button mat-button  (click)="onRetailRequest()" ><span class="visible-xs hidden-lg"><i class="material-icons">work_outline</i></span> <span class="hidden-xs"> Retailer request</span></button>
          <span class="example-spacer visible-xs hidden-lg"></span>
          <button mat-button  (click)="onRegister()"><span class="visible-xs hidden-lg"><i class="material-icons">edit</i></span> <span class="hidden-xs">Register</span></button>
          <span class="example-spacer visible-xs hidden-lg"></span>
          <button mat-button (click)="onLogin()"><span class="visible-xs hidden-lg"><i class="material-icons">person</i></span><span class="hidden-xs">Login</span></button>
          <span class="example-spacer visible-xs hidden-lg"></span>
          <button mat-button color="warn" (click)="onOppBuy()" ><i class="material-icons">star_border</i><span class="hidden-xs"> Opportunity Buy</span></button>
          <span class="example-spacer visible-xs hidden-lg"></span>
          <span class="example-spacer"></span>
        </mat-toolbar-row>
</mat-toolbar> -->
<!-- <a mat-button color="primary" [routerLink]="['/retailer']"><i class="material-icons">work_outline</i> <span class="hidden-xs"> Retailer request</span></a> -->
<!--<app-retailer *ngIf="pageView=='retailer'"></app-retailer>
<div *ngIf="code=='au'" class="col-md-12 text-center sale-time">
    <img src="assets/img/sale-time.jpg" style="max-width: 400px;">
</div>    
<div *ngIf="pageView==''" class="landing-page-actions">
    <div class="landing-action text-center">
            <div class="access-spinner" >
                    <div class="access-spinner-holder">
                         <div class="login">
                                    <h1 class="orange-txt"><i class="material-icons">how_to_reg</i></h1>
                                    <h4><b>Login 
                                    </b></h4>
                                    <p >If you already have an account, please login here</p>
                                    <button mat-raised-button color="warn" (click)="onLogin()"> Login</button>
                        </div>   
                    </div>
                </div>
    </div>
    <div class="landing-action text-center">
            <div class="access-spinner" >
                    <div class="access-spinner-holder">
                            <div>
                                    <h1 class=""><i class="material-icons">person_add</i></h1>
                                    <h4><b>Want to become a Wild Republic retailer</b></h4>
                                    <p>If you want to become a retailer, please make a request here</p>
                                    <button mat-raised-button color="warn" (click)="onRetailRequest()"> Request </button>
                            </div>
                    </div>
                </div>
    </div>
</div>
<div *ngIf="pageView==''" class="retailer-request">
        <div class="request-content text-center">
                <div>
                        <h4><i class="material-icons">edit</i> <b>Register here</b></h4>
                        <p>If you are a Wildrepublic existing retailer and do not have an account, please register here</p>
                        <button mat-raised-button color="warn" (click)="onRegister()"> Register here </button>
                </div>
        </div>
</div>
<div class="" *ngIf="code=='us'" class="text-center">
    <img src="assets/img/stg.jpg" alt="" class="col-md-6">
</div>
<div class="row about">
    <div class="container text-center">
        <h3><b>We create toys that are naturally fun!</b></h3>
        <p>
                For nearly four decades, Wild Republic’s realistic stuffed animals and toys have helped children explore the beauty of nature.
                Our mission is to create toys that foster curiosity about wildlife and educate children about
                the wonders of nature. Wild Republic is the natural choice for young explorers everywhere.
        </p>
        <img  class="img-fluid" src="assets/img/logo.png" />
    </div>
</div>
<footer [ngClass]="'footer'" data-background-color="black">
        <app-footer></app-footer>
      </footer>-->
<div class="page-header section-image" style="max-height:none">
    <div class="page-header-image" style="background-image:url(assets/img/bg18.jpg)"></div>
    <div class="content" style="margin-top:0;margin-bottom: 0;">
        <div class="">
            <!--<div class="row">
                <div class="col-md-12 text-center">
                    <a class="navbar-brand" placement="bottom" ngbTooltip="Wild Republic">                
                        <img  class="img-fluid" src="assets/img/logo.png" />
                    </a>
                    <h4 class="description">Business to Business</h4>                    
                </div>
            </div>-->
            <div class="row row-hero" style="background: #ccf7c2;">
                <div class="col-md-56 ml-auto mr-auto d-none d-md-block">
                    <!-- [ngStyle]="{'background-size': configurations.login.access.showBgImg ? '100% auto' : 'cover','background-image':configurations.login.access.showBgImg? 'url(assets/img/bg-sale-time.jpg)':'linear-gradient(rgba(0,0,0,0.7),rgba(0,0,0,0.7)),url(assets/img/bg-us.jpg)',height:configurations.login.access.showBgImg?'100%': '70%' }" -->
                    <div class="d-none d-md-block img-block"><!--'background-position-y' :  code=='au' ? 'top' : 'center'-->
                        <img src="assets/img/TempbgImg.jpg" alt="" *ngIf="configurations.login.access.bgImg == ''" class="bg-img">
                        <img [src]="bgImageUrl" alt=""  *ngIf="configurations.login.access.bgImg != ''" class="bg-img">
                    </div>
                    <div class="col-md-12 d-none d-md-block text-left" [ngStyle]="{'visibility': configurations.login.access.showWildRepublicComments_AU ? 'hidden' :'visible' }" style="height: 30%;">
                        <h3 class="title col-md-12"> We create toys that are naturally fun!</h3>
                        <p class="description col-md-12">
                            For nearly four decades, Wild Republic’s realistic stuffed animals and toys have helped children explore the beauty of nature.
                            Our mission is to create toys that foster curiosity about wildlife and educate children about
                            the wonders of nature. Wild Republic is the natural choice for young explorers everywhere.
                        </p>
                    </div>
                </div>
                <div class="col-md-5  action-block"><!--mr-auto -->
                    <div class="info-horizontal">
                        <div class="text-center">
                            <img  class="img-fluid" src="assets/img/logo.png" />
                        </div>
                        
                    </div>
                    <div class="info info-horizontal">
                        <div class="icon icon-primary">
                            <i class="now-ui-icons business_badge"></i>
                        </div>
                        <div class="description">
                            <h5 class="info-title">Login</h5>
                            <p class="description">
                                If you already have an account, please login here
                            </p>
                            <a  class="btn btn-primary" (click)="onLogin()">Login</a>
                        </div>
                    </div>
                    <div class="info info-horizontal" *ngIf="configurations.login.access.showRegisterButton">
                        <div class="icon icon-primary">
                            <i class="now-ui-icons business_badge"></i>
                        </div>
                        <div class="description">
                            <h5 class="info-title">Register</h5>
                            <p class="description">
                                If you are a Wild Republic existing retailer and do not have an account, please register here
                            </p>
                            <a class="btn btn-primary" (click)="onRegister()">Register</a>
                        </div>
                    </div>
                    <div class="info info-horizontal" *ngIf="configurations.login.access.showRetailerButton">
                        <div class="icon icon-info">
                            <i class="now-ui-icons ui-1_simple-add"></i>
                        </div>
                        <div class="description">
                            <h5 class="info-title">Retailer</h5>
                            <p class="description">
                                Want to be Wild Republic retailer, please make a request here
                            </p>
                            <a  class="btn btn-primary" [routerLink]="['/retailer']" style="color:inherit">Request</a>
                        </div>
                    </div>
                    <div *ngIf="configurations.login.access.showHelpButton" class="info info-horizontal">
                        <div class="icon icon-info">
                            <i class="now-ui-icons business_badge"></i>
                        </div>
                        <div class="description">
                            <h5 class="info-title"><a [routerLink]="['/help']">Help</a> </h5>
                            <p class="description">
                                
                            </p>
                            <a  class="btn btn-primary" [routerLink]="['/help']">Help</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row about d-block d-sm-none" [ngClass]="{'d-md-block':configurations.login.access.showWildRepublicComments_AU}">
                <div class="container text-center">
                    <h3><b>We create toys that are naturally fun!</b></h3>
                    <p>
                            For nearly four decades, Wild Republic’s realistic stuffed animals and toys have helped children explore the beauty of nature.
                            Our mission is to create toys that foster curiosity about wildlife and educate children about
                            the wonders of nature. Wild Republic is the natural choice for young explorers everywhere.
                    </p>
                </div>
            </div>
        </div>
    </div>
    <footer [ngClass]="'footer'" [ngStyle]="{'background-color':configurations.login.access.showFooterBgcolorWhite?'white':'auto'}">
    <app-footer></app-footer>
    </footer>
</div>
<!-- You can use owl-carousel selector to include its component -->
</block-ui>
