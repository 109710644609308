import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';

import { NotificationComponent } from './component/notification/notification.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { GlobalNoteComponent } from './component/global-note/global-note.component';
import { TranslateModule } from '@ngx-translate/core';
import { ImgFallbackDirective } from './directives/imagefallback.directive';
import { ModalPopupComponent } from './component/modal-popup/modal-popup.component';
import { NumericComponent } from './component/numeric/numeric.component';
import { ReactiveFormsModule } from '@angular/forms';
import { EmptyContentComponent } from './component/empty-content/empty-content.component';
import { ValidationSummaryComponent } from './component/validation-summary/validation-summary.component';
import { HasRoleDirective } from 'src/app/shared/directives/has-role.directive';
import { OrderQuantityComponent } from './component/order-quantity/order-quantity.component';
import { AlertComponent } from './component/alert/alert.component';
import { MaterialModule } from 'src/app/material.module';
import { CatalogService } from 'src/app/catalog/services/catalog.service';
import { SlideShowComponent } from './component/slide-show/slide-show.component';
import { OwlModule } from 'ngx-owl-carousel';
import { ProductSearchComponent } from './component/product-search/product-search.component';
import { GhostListComponent } from './component/ghost-list/ghost-list.component';
// import { NgxImageZoomModule } from 'ngx-image-zoom';
import { FastOrderComponent } from './component/fast-order/fast-order.component';
import { FormsModule } from '@angular/forms';
import { EmailNotifyComponent } from './component/email-notify/email-notify.component';
import { MorderService } from 'src/app/manage/services/morder.service';
import { OrderService } from 'src/app/core/services/order.service';
import { SecurityService } from 'src/app/core/services/security.service';
import { HaspermissionsDirective } from './directives/haspermissions.directive';
import { SelectOnClickDirective } from './directives/select-on-click.directive';
import { ImagePreloadDirDirective } from './directives/image-preload-dir.directive';
import { LoaderComponent } from './component/loader/loader.component';
import { CustomerService } from 'src/app/customer/services/customer.service';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NotificationsnackbarComponent } from './component/notificationsnackbar/notificationsnackbar.component';
import { CarouselModule } from 'ngx-owl-carousel-o';


@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    ReactiveFormsModule,
    MaterialModule,
    OwlModule,
    TranslateModule.forChild(),
    // NgxImageZoomModule,
    FormsModule,
    FlexLayoutModule,
    CarouselModule
  ],
  declarations: [
    NotificationComponent,
    GlobalNoteComponent,
    ImgFallbackDirective,
    ModalPopupComponent,
    NumericComponent,
    EmptyContentComponent,
    ValidationSummaryComponent,
    HasRoleDirective,
    OrderQuantityComponent,
    AlertComponent,
    SlideShowComponent,
    ProductSearchComponent,
    GhostListComponent,
    FastOrderComponent,
    EmailNotifyComponent,
    HaspermissionsDirective,
    SelectOnClickDirective,
    ImagePreloadDirDirective,
    LoaderComponent,
    NotificationsnackbarComponent
  ],
  exports: [
    NotificationComponent,
    GlobalNoteComponent,
    ImgFallbackDirective,
    ModalPopupComponent,
    NumericComponent,
    ValidationSummaryComponent,
    EmptyContentComponent,
    HasRoleDirective,
    OrderQuantityComponent,
    AlertComponent,
    SlideShowComponent,
    GhostListComponent,
    FastOrderComponent,
    HaspermissionsDirective,
    ImagePreloadDirDirective,
    LoaderComponent
  ],
  entryComponents: [AlertComponent, GhostListComponent],
  providers: [CatalogService, MorderService, OrderService, SecurityService, CurrencyPipe, CustomerService],
})

export class SharedModule {
  static forRoot(): ModuleWithProviders<any> {
    return {
        ngModule: SharedModule
    };
  }
}
