import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { OfferItem } from 'src/app/offers/model/offer-item-model';
import { ApiConfigService } from './api-config.service';
import { HttpParams, HttpClient, HttpHeaders } from '@angular/common/http';
import { SecurityService } from './security.service';
import { BasketService } from './basket.service';
import { switchMap } from 'rxjs/operators';
import { ImageConfig } from 'src/app/model/api-config';

@Injectable({
  providedIn: 'root'
})
export class OffersService {
  private OffersBehaviorSubject: BehaviorSubject<OfferItem[]> = new BehaviorSubject(this.initOffers())
  Offers: Observable<OfferItem[]> = this.OffersBehaviorSubject.asObservable();

  private offerSettingBehaviorSubject: BehaviorSubject<string> = new BehaviorSubject("");
  offerSettings: Observable<string> = this.offerSettingBehaviorSubject.asObservable();

  private applicableOffersBehaviorSubject: BehaviorSubject<OfferItem[]> = new BehaviorSubject(this.initApplicableOffer());
  applicableOffers: Observable<OfferItem[]> = this.applicableOffersBehaviorSubject.asObservable();

  private nearestOffersBehaviorSubject: BehaviorSubject<OfferItem[]> = new BehaviorSubject(this.initNearestOffers())
  nearestOffers: Observable<OfferItem[]> = this.nearestOffersBehaviorSubject.asObservable();

  private promoOffersBehaviorSubject: BehaviorSubject<OfferItem[]> = new BehaviorSubject(this.initPromoOffers());
  promoOffers: Observable<OfferItem[]> = this.promoOffersBehaviorSubject.asObservable();

  private groupDiscountTotalBehaviorSubject: BehaviorSubject<number> = new BehaviorSubject(0);
  public groupDiscountTotal: Observable<number> = this.groupDiscountTotalBehaviorSubject.asObservable();

  private cartTotal: any;
  imageConfig!: ImageConfig;

  public applicableGrpDiscount!: OfferItem;

  constructor(public apiSvc: ApiConfigService, public securityService: SecurityService, public http: HttpClient, public basketService: BasketService) {
    this.basketService.basketTotal$.subscribe(data => {
      this.cartTotal = data;
      let basketItems = this.basketService.getBasketData().items;
      //-- Applicable offers
      this.applicableOffers = this.Offers.pipe(
        switchMap(offers => {
          let offern = offers.filter(ofr => {
            return ofr.minOrderAmount! <= data
          })
          //Find the maximum in applicable offers
          offern = offern.sort((a, b) => {
            if (a.minOrderAmount! < b.minOrderAmount!) return 1;
            if (b.minOrderAmount! < a.minOrderAmount!) return -1;
            return 0;
          })
          //-- Discount group 
          let exists = {};

          offern.forEach((offr, inx) => {
            if (offr.productGroupCode != "") {
              let index = inx;
              //--New

              let grpProdTotal = 0;
              let groupProdItems = basketItems.filter(item => {
                if (offr.productGroupCode == item.productGrpcode) {
                  grpProdTotal = grpProdTotal + (item.unitPrice! * item.quantity!)
                }
                return offr.productGroupCode == item.productGrpcode
              })
              let groupProdOffer = {}
              if (groupProdItems.length > 0) {
                if (grpProdTotal < offr.minOrderAmount!) {
                  offern.splice(inx, 1)
                }
                else {
                  this.applicableGrpDiscount = offern[inx]
                }
              }
              else {
                offern.splice(inx, 1)
              }
              offern
              //-- end New
              //-- Old discount group logic
              // exists=this.basketService.getBasketData().items.some((itm,indx)=>{
              //   return offr.productGroupCode==itm.productGrpcode && ((itm.unitPrice*itm.quantity)>=offr.minOrderAmount)
              // })
              // if (!exists) {
              //   offern.splice(inx,1)
              // }
              //-- /End old logic
            }
          })
          //-- end  
          return of(offern)
        })
      )
      //-- End 
      //-- Nearest offers
      this.nearestOffers = this.Offers.pipe(
        switchMap(offers => {
          let promos = offers.filter(ofr => { return ofr.minOrderAmount! > data })
          let nearest = promos.sort((a, b) => {
            if (a.minOrderAmount! > b.minOrderAmount!) return 1;
            if (b.minOrderAmount! > a.minOrderAmount!) return -1;
            return 0
          })
          //-- Independent calculation for discount group items
          if (nearest[0] && nearest[0].productGroupCode != '') {
            let nearestGrpOffer = nearest[0];

            let cartHasgroupItem = basketItems.some(item => { return item.productGrpcode == nearestGrpOffer.productGroupCode })
            let groupItemsTotal = 0;
            if (cartHasgroupItem) {
              let groupitems = basketItems.filter(item => {
                return item.productGrpcode == nearestGrpOffer.productGroupCode
              }).map(item => {
                return item.quantity! * item.unitPrice!
              })
              groupItemsTotal = groupitems.reduce((a, b) => { return a + b })
              this.groupDiscountTotalBehaviorSubject.next(groupItemsTotal)
            }
            else {
              this.groupDiscountTotalBehaviorSubject.next(0)
            }
          }
          //--
          return of(nearest)
        }
        )
      )
      //--
      //-- Promo offers
      this.promoOffers = this.Offers.pipe(
        switchMap(offers => {
          let promos = offers.filter(promo => {
            promo.OfferImgLowRes = this.imageConfig.lowResUri + 'promo-' + promo.promoNo + '.jpg';
            return promo.minOrderAmount! > this.cartTotal
          })
          return of(promos)
        })
      )
      //--
    })
  }
  getAllOffers() {
    return this.OffersBehaviorSubject.getValue();
  }
  setOffers(Offers?: OfferItem[]) {
    this.OffersBehaviorSubject.next(Offers ? Offers : [])
  }
  initOffers(): OfferItem[] {
    let dummyImage = "/assets/img/Audubon-Birds-High.jpg";
    let lowResdummy = "/assets/img/Audubon-Birds-Low.jpg";
    let offers: any = [
      // {
      //   OfferCode:"NET 30 - 5% OFF",
      //   OfferImage:dummyImage,
      //   OfferImgLowRes:lowResdummy,
      //   offerminOrderVal:500,
      //   OfferPercentage:5
      // },
      // {
      //   OfferCode:"NET 60 - 15% OFF",
      //   OfferImage:dummyImage,
      //   OfferImgLowRes:lowResdummy,
      //   offerminOrderVal:1000,
      //   OfferPercentage:15
      // },
      // {
      //   OfferCode:"NET 30 - 10% OFF",
      //   OfferImage:dummyImage,
      //   OfferImgLowRes:lowResdummy,
      //   offerminOrderVal:750,
      //   OfferPercentage:10
      // },
      // {
      //   OfferCode:"NET 90- 20% OFF",
      //   OfferImage:dummyImage,
      //   OfferImgLowRes:lowResdummy,
      //   offerminOrderVal:1500,
      //   OfferPercentage:20
      // },
      // {
      //   OfferCode:"NET 90- 25% OFF",
      //   OfferImage:dummyImage,
      //   OfferImgLowRes:lowResdummy,
      //   offerminOrderVal:2500,
      //   OfferPercentage:25
      // }
    ]
    return offers
  }
  private getHeader(): HttpHeaders {
    return this.securityService.formatRequestHeader(this.basketService.getCustomerCode(), this.basketService.getPriceGroup());
  }
  getLatestOffers() {
    let settingURL = this.apiSvc.formatUrl(this.apiSvc.apiConfig.offers);
    let httpParams: HttpParams = new HttpParams();
    // .set('tenant',tenant);
    return this.http.get<any>(settingURL, { headers: this.getHeader(), params: httpParams });
  }
  getImageConfig() {
    return this.apiSvc.apiConfig.image;
  }

  setOfferSetting(setting?: any) {
    this.offerSettingBehaviorSubject.next(setting);
  }
  initApplicableOffer(): OfferItem[] {
    let applicableOffer: any = []
    return applicableOffer;
  }
  initNearestOffers(): OfferItem[] {
    let nearestOffers: any = [];
    return nearestOffers;
  }
  initPromoOffers(): OfferItem[] {
    let promoOffers: any = [];
    return promoOffers;

  }
  setNextOffer() {
    this.applicableOffersBehaviorSubject.next([])
  }

  getEstimatedOffer(amountToCart: any, itms: any) {
    let estimatedOffer = this.Offers.pipe(
      switchMap(offers => {
        let offern = offers.filter(ofr => { return ofr.minOrderAmount! <= amountToCart })
        //Find the maximum in applicable offers
        offern = offern.sort((a, b) => {
          if (a.minOrderAmount! < b.minOrderAmount!) return 1;
          if (b.minOrderAmount! < a.minOrderAmount!) return -1;
          return 0;
        })
        //-- Discount group 
        let exists = {};
        offern.forEach((offr, inx) => {
          if (offr.productGroupCode != "") {
            let index = inx;
            //--
            let basketItems = itms;
            let grpProdTotal = 0;
            let groupProdItems = basketItems.filter((item: any) => {
              if (offr.productGroupCode == item.productGrpcode) {
                grpProdTotal = grpProdTotal + (item.unitPrice * item.quantity)
              }
              return offr.productGroupCode == item.productGrpcode
            })
            let groupProdOffer = {}
            if (groupProdItems.length > 0) {
              if (grpProdTotal < offr.minOrderAmount!) {
                offern.splice(inx, 1)
              }
              else {
                this.applicableGrpDiscount = offern[inx]
              }
            }
            else {
              offern.splice(inx, 1)
            }
            //--

            //--Old 
            // exists=itms.some((itm,indx)=>{
            //   return offr.productGroupCode==itm.productGrpcode && ((itm.unitPrice*itm.quantity)>=offr.minOrderAmount)
            // })
            // if (!exists) {
            //   offern.splice(inx,1)
            // }
            //--End 
          }
        })
        //-- end  
        return of(offern)
      })
    )
    return estimatedOffer
  }

}
