<app-global-note></app-global-note>
<app-notification></app-notification>

<div class="row">
  <div class="container">
      <mat-card class="list">
          <mat-card-header class="no-margin">
              <mat-card-title class="no-margin">
                  <h3 class="no-margin"><b>Become a Retailer</b></h3></mat-card-title>
            </mat-card-header>

            <mat-card-content>
                <p>
                    Wild Republic has strong partnerships with retailers from around the world to help get our nature-inspired products into the hands of children everywhere.
                    We strive to adhere to our core values in everything we do, so it’s important to us to make a positive impact on our customers, community and planet. Accordingly, we place people first and are driven by excellence in customer service. We take great pride in supporting our retail partners and offering them high quality products at reasonable prices.
                    If you are a retailer interested in carrying our products, just fill out the form below to begin the partnership process.
                </p>
              </mat-card-content>
      </mat-card>
  </div>
</div>

<div class="row" *ngIf="!isCustomerReqDisabled">

  <div class="container">
      <form role="form" [formGroup]="myform" (ngSubmit)="onSendRequest()">

      <mat-horizontal-stepper linear="true" #stepper class="retailer-form">
          <mat-step [stepControl]="ReturnFormGroupWithControl('businessContactInfo')">
                <ng-template matStepLabel>Business contact</ng-template>
                <app-business-contact-info [myform]="ReturnFormGroupWithControl('businessContactInfo')" [localeCode]="localeService.code" ></app-business-contact-info>
                <div class="location-forms">
                  <div class="form">
                    <h4>{{('retailer.OWNERINFO'|translate)}}</h4>
                    <mat-form-field class="col-sm">
                            <mat-label>{{('retailer.NAME'|translate)}}</mat-label>
                            <input matInput [formControl]="RetuFormControlWithOwnerInfoControl('name')">
                            <mat-error> You must include {{('retailer.NAME'|translate)}}.</mat-error>
                    </mat-form-field>
                    <mat-form-field class="col-sm">
                            <mat-label>{{('retailer.PHONE'|translate)}}</mat-label>
                            <input matInput [formControl]="RetuFormControlWithOwnerInfoControl('phone')">
                            <mat-error> You must include {{('retailer.NAME'|translate)}}.</mat-error>
                    </mat-form-field>
                    <mat-form-field class="col-sm">
                            <mat-label>{{('retailer.ADDRESS'|translate)}}</mat-label>
                            <input matInput [formControl]="RetuFormControlWithOwnerInfoControl('address')">
                            <mat-error> You must include {{('retailer.NAME'|translate)}}.</mat-error>
                    </mat-form-field>
                    <mat-form-field class="col-sm">
                            <mat-label>{{('retailer.LICENCENO'|translate)}}</mat-label>
                            <input matInput [formControl]="RetuFormControlWithOwnerInfoControl('licenceNo')">
                            <mat-error> You must include {{('retailer.NAME'|translate)}}.</mat-error>
                    </mat-form-field>
                    <div class="col-sm-6">
                      <mat-form-field class="col-sm">
                        <mat-label>{{('retailer.BUSINESSTYPE'|translate)}}</mat-label>
                        <mat-select [formControl]="RetuFormControlWithOwnerInfoControl('businessType')" ><!--multiple-->
                          <mat-option *ngFor="let bType of businessTypes" [value]="bType">{{bType}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                      <mat-form-field class="col-sm">
                        <mat-label>{{('retailer.CURRENTLEASE'|translate)}}</mat-label>
                        <mat-select [formControl]="RetuFormControlWithOwnerInfoControl('currentBLease')" ><!--multiple-->
                          <mat-option *ngFor="let bLease of businessLease" [value]="bLease">{{bLease}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>




                  </div>
                </div>
                <div class="text-right">
                  <button mat-mini-fab [disabled]="!ReturnMyFormControlWithValidorNot('businessContactInfo') || !ReturnMyFormControlWithValidorNot('OwnerInfo')" color="warn"  matStepperNext><mat-icon>arrow_forward</mat-icon></button>
                </div>
          </mat-step>
          <mat-step [stepControl]="ReturnFormGroupWithControl('buyerContactInfo')">
                <ng-template matStepLabel>Account info</ng-template>
                <div class="col-md-6" style="float:left">
                  <h4>{{('retailer.SALESDEPTDETAILS'|translate)}}</h4>
                    <app-buyer-account-receivable-contact-info [myform]="ReturnFormGroupWithControl('buyerContactInfo')"></app-buyer-account-receivable-contact-info>
                </div>
                <div class="col-md-6" style="float:left">
                  <h4>{{('retailer.ACCOUNTDEPTDETAILS'|translate)}}</h4>
                    <app-buyer-account-receivable-contact-info [myform]="ReturnFormGroupWithControl('accountReceivableContactInfo')"></app-buyer-account-receivable-contact-info>
                </div>

                <div>
                  <button mat-mini-fab class="pull-right" [disabled]="!ReturnMyFormControlWithValidorNot('accountReceivableContactInfo')" color="warn" matStepperNext><mat-icon>arrow_forward</mat-icon></button>
                </div>
          </mat-step>
          <mat-step [stepControl]="ReturnFormGroupWithControl('businessRef1')">
              <app-validation-summary [form]="myform"></app-validation-summary>
              <ng-template matStepLabel>{{('retailer.TRADEREFERENCE'|translate)}}</ng-template>
              <h5>Reference#1</h5>
              <app-business-ref [myform]="ReturnFormGroupWithControl('businessRef1')"></app-business-ref>
              <br />
              <h5>Reference#2</h5>
              <app-business-ref [myform]="ReturnFormGroupWithControl('businessRef2')"></app-business-ref>
              <br />
              <h5>Reference#3</h5>
              <app-business-ref [myform]="ReturnFormGroupWithControl('businessRef3')"></app-business-ref>
              <br>
              <mat-list role="list" *ngIf="localeService && localeService.localeConfig">
                  <h5>{{('retailer.TERMSNCONDITIONS'|translate)}}</h5>
                  <mat-list-item role="listitem" *ngFor="let terms of localeService.localeConfig.Retailer.Terms">{{terms}}</mat-list-item>
              </mat-list>
              <mat-checkbox [formControl]="ReturnFormControl('termsAgreed')">Terms Agreed</mat-checkbox>
              <div class="text-right">
                  <button mat-raised-button color="warn" type="submit" [disabled]="!ReturnMyFormValidorNot()">Send Request <mat-icon>arrow_forward</mat-icon></button>
              </div>
          </mat-step>
      </mat-horizontal-stepper>
    </form>
  </div>
</div>
<!--Old code-->
<div class="section section-tabs">
  <app-notification></app-notification>
  <div class="container">
    <!-- <h2>Retailer Info</h2> -->
   <!-- <div>
      <h4>Become a Retailer</h4>
      <blockquote class="text-center">
        <blockquote>
          Wild Republic has strong partnerships with retailers from around the world to help get our nature-inspired
          products into the hands of children everywhere.
        </blockquote>
        <blockquote>
          We strive to adhere to our core values in everything we do, so it’s important to us to make a positive impact
          on our customers, community and planet. Accordingly, we place people first and are driven by excellence in
          customer service. We take great pride in supporting our retail partners and offering them high quality products
          at reasonable prices.
        </blockquote>
        <blockquote>
          If you are a retailer interested in carrying our products, just fill out the form below to begin the
          partnership process.
        </blockquote>
      </blockquote>
    </div>
    <br />
    <div *ngIf="!isCustomerReqDisabled">
      <form role="form" [formGroup]="myform" (ngSubmit)="onSendRequest()">
        <div class="card">
          <div class="card-body">
            <h4>New Account / Credit Application Form</h4>

            <blockquote>Notice: JavaScript is required for this content.<br/>Fields marked with an * are required</blockquote>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <app-business-contact-info [myform]="myform.controls.businessContactInfo" ></app-business-contact-info>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-6">
                <h4>{{('retailer.SALESDEPTDETAILS'|translate)}}</h4>
                <app-buyer-account-receivable-contact-info [myform]="myform.controls.buyerContactInfo"></app-buyer-account-receivable-contact-info>
              </div>
              <div class="col-sm-6">
                <h4>{{('retailer.ACCOUNTDEPTDETAILS'|translate)}}</h4>
                <app-buyer-account-receivable-contact-info [myform]="myform.controls.accountReceivableContactInfo"></app-buyer-account-receivable-contact-info>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h4>{{('retailer.TRADEREFERENCE'|translate)}}</h4>

            <h5>Reference#1</h5>
            <app-business-ref [myform]="myform.controls.businessRef1"></app-business-ref>
            <br />
            <h5>Reference#2</h5>
            <app-business-ref [myform]="myform.controls.businessRef2"></app-business-ref>
            <br />
            <h5>Reference#3</h5>
            <app-business-ref [myform]="myform.controls.businessRef3"></app-business-ref>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <div class="form-group">
              <div>
                <div class="form-check">
                  <label class="form-check-label">
                    <input class="form-check-input" type="checkbox" [formControl]="myform.controls['termsAgreed']">
                    <span class="form-check-sign">Terms Agreed</span>
                  </label>

                </div>
                <br />
                <div>
                  <ul>
                    <li>All invoices are to be paid 30 days from the date of the invoice.</li>
                    <li>Claims arising from invoices must be made within seven working days.</li>
                    <li>By submitting this application, you authorize Wild Republic to make inquiries into the banking
                      and business/trade
                      references that you have supplied.</li>
                    <li>All fields must be filled out or application will be delayed in processing</li>
                  </ul>
                </div>

              </div>
              <app-validation-summary [form]="myform"></app-validation-summary>
              <div class="text-right">
                <button class="btn btn-primary" type="submit" [disabled]="!myform.valid" >Send Request</button>
              </div>
            </div>
          </div>
        </div>


      </form>
    </div> -->
    <div *ngIf="isCustomerReqDisabled">
      your request has been sent. customer representative will contact you soon.
    </div>
  </div>

</div>
<!--/Old code-->
