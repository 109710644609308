import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { ApiConfigService } from './api-config.service';
import { SecurityService } from './security.service';
import { Order } from '../../model/order';
import { ImageConfig } from '../../model/api-config';
import { BasketService } from './basket.service';

@Injectable()
export class OrderService {

 
  constructor(private http: HttpClient,
    private apiSvc: ApiConfigService,
    private securityService: SecurityService,
    private basketService:BasketService) {
    
  }

  createOrder(order: Order) {
    let url = this.apiSvc.formatUrl(this.apiSvc.apiConfig.order.orderCreateApi);
    return this.http.post<Order>(url, JSON.stringify(order), {
      headers: this.getHeader()
    });
  }
  sendEmail(params:any){
    let url = this.apiSvc.formatUrl(this.apiSvc.apiConfig.order.email!);
    return this.http.post<any>(url, JSON.stringify(params), {
      headers: this.getHeader()
    });
  }

  updateOrder(order: Order) {
    let url = this.apiSvc.formatUrl(this.apiSvc.apiConfig.order.orderUpdateApi);
    return this.http.post<Order>(url, JSON.stringify(order), {
      headers: this.getHeader()
    });
  }

  getImgeUri():ImageConfig
  {
    return this.apiSvc.apiConfig.image;
  }
  private getHeader(): HttpHeaders {
    return this.securityService.formatRequestHeader(this.basketService.getCustomerCode(),this.basketService.getPriceGroup());  
  }
  getAppSettings(params:any,tenant:any){
    let settingURL=this.apiSvc.formatUrl(this.apiSvc.apiConfig.appSettings);
    let httpParams:HttpParams=new HttpParams();
    // .set('tenant',tenant);
    return this.http.get(settingURL,{headers:this.getHeader(),params:httpParams});
  }
}
