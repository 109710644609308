import { Component, OnInit, Input } from '@angular/core';
import { IConfigurations } from 'src/app/core/models/settings.model';
import { LocaleService } from 'src/app/core/services/locale.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  data : Date = new Date();
  configurations:IConfigurations = {} as IConfigurations;

  constructor(public localeService:LocaleService) {
    this.configurations = this.localeService.GetConfigFromStore();
   }

  ngOnInit() {
  }

}
