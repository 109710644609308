import { Directive, ElementRef, Renderer2, Input } from '@angular/core';
import { ImageConfig } from 'src/app/model/api-config';
import { CatalogService } from 'src/app/catalog/services/catalog.service';

@Directive({
  selector: '[appImagePreloadDir]',
  host:{
    '(error)':'handleNoImg()'
  }
})
export class ImagePreloadDirDirective {
  imageConfig: ImageConfig;
  @Input() promoCode!:string;
  @Input() minValue!:string;
  @Input() currencyCode!:string;
  
  constructor(public el:ElementRef,public renderer:Renderer2,public catalogService:CatalogService) {
    this.imageConfig=this.catalogService.getImageConfig();
    let url=this.el.nativeElement.src;
        
   }
  handleNoImg(){
    if (!this.promoCode && !this.minValue) {
        let url=this.el.nativeElement.src;
        var filename = url.substring(url.lastIndexOf('/')+1);
        
        this.renderer.setAttribute(this.el.nativeElement,'src','/assets/img/banner-placer.jpg');
      
      }
      else{
        let div=this.renderer.createElement('div');
        this.renderer.addClass(div,'Image-banner-text');
        this.renderer.addClass(div,'text-center');
        let text=this.renderer.createText('Spend '+this.currencyCode+' and get '+ this.promoCode+ ' pre-applied');
        this.renderer.appendChild(div,text);
        let parent=this.renderer.parentNode(this.el.nativeElement)
        this.renderer.appendChild(parent,div);
  
        let codeContainer=this.renderer.createElement('p');
        this.renderer.addClass(codeContainer,'Image-banner-code');
        let code=this.renderer.createText('');//'Applied discount : '+this.promoCode
        this.renderer.appendChild(codeContainer,code);
        this.renderer.appendChild(div,codeContainer);
     
        this.renderer.setStyle(this.el.nativeElement,'display','none');
    
      }
      
      // this.renderer.setStyle(this.el.nativeElement,'display','none');
    
  }
}
