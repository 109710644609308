import { Component, OnInit } from '@angular/core';
import { ShoppingCartService } from './core/services/shopping-cart.service';
import { SecurityService } from './core/services/security.service';
import { BasketService } from './core/services/basket.service';
import { BasketData } from './model/basket-data';
import { OffersService } from './core/services/offers.service';
import { OrderService } from './core/services/order.service';
import { catchError, switchMap, share } from 'rxjs/operators';
import { of } from 'rxjs';
import { LocaleService } from './core/services/locale.service';
import { CatalogService } from './catalog/services/catalog.service';
import { SettingsService } from './core/services/settings.service';
import { IConfigurations, SettingsModel } from './core/models/settings.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router, NavigationEnd } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AlertComponent } from './shared/component/alert/alert.component';
import { ModalPopupComponent } from './shared/component/modal-popup/modal-popup.component';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import * as UserActions from 'src/app/ngrx/act.action';
import { Store } from '@ngrx/store';
import { GlobalNoteService } from './core/services/global-note.service';
import { NotificationService } from './core/services/notification.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  mySubscription: any;
  leadDialog!: MatDialogRef<AlertComponent>;
  configurations:IConfigurations = {} as IConfigurations;
  @BlockUI() blockUI!: NgBlockUI;
  showRouterOutlet:boolean = false;

  constructor(private securityService: SecurityService,
    private shoppingCartService: ShoppingCartService,
    private basketService: BasketService,
    private offerService: OffersService,
    public router: Router,
    private localeService: LocaleService,
    private settingsService: SettingsService,
    public modalService: NgbModal,
    public dialog: MatDialog,
    private http:HttpClient,
    private store:Store<IConfigurations>,
    private globalNoteService:GlobalNoteService,
    private notificationService:NotificationService) {


    this.securityService.getUser().then(data => {

      let code = this.localeService.code;
      let CurrentUrl = this.localeService.tenantLocale.filter(tenant => { return tenant.code == code }).map(item => { return item.host })
      // let userTenant=this.localeService.tenantList.findIndex(item=>{return item.id==data.profile.tenantId});
      // if((!userTenant || userTenant==-1) && CurrentUrl.length<2){
      //   window.location.href=CurrentUrl[0];
      // }
      if(data)
      {
        let userTenant = this.localeService.tenantList.filter(item => { return item.id == data!.profile.tenant });
        if (userTenant[0] !== undefined && code != userTenant[0].code) { //&& CurrentUrl.length<2 - for dev env
          window.location.href = userTenant[0].clientURI;
        }
      }

      let resetPW = localStorage.getItem("passWordReset");

      if (data && data.profile.resetPassword && data.profile.resetPassword.toLowerCase() == 'true' && resetPW === null) {
        const modalRef = this.modalService.open(ModalPopupComponent);
        modalRef.componentInstance.title = `Reset password`;
        modalRef.componentInstance.content = `Do you like to reset your account password?`;
        modalRef.componentInstance.okbutton = 'Yes';
        modalRef.componentInstance.cancelButton = 'Remind me later';
        modalRef.result.then(result => {
          localStorage.setItem("passWordReset", "true");
          if (result) {
            this.onResetPassword();
          }
        })
      }

      //-- Customer dept_code
      if(data)
      {
        this.basketService.setDeptCode(data!.profile.dept_code);
      // }

    //-- App Settings
    this.settingsService.getAppSettings({}, this.localeService.code)
    .pipe(
      switchMap((Settings:any ) => {
        if (Array.isArray(Settings)) {
          var mapSettings: SettingsModel = {
            DEFAULTSORTORDER: "",
            MINCARTVALUE: "",
            NOOFFERPRICEGROUP: "",
            SENDEMAILTOREP: "",
            SHOWOFFER: false,
            ZEROVALUECATEGORY: "",
            SHOWDISCOUNTONCART: false,
            CURRENCYCODE: "USD",
            ISSUBSCRIBED: false
          }
          Settings.map(function (Setting) {
            switch (Setting.id) {
              case "DEFAULTSORTORDER":
                mapSettings.DEFAULTSORTORDER = Setting.value
                break;
              case "MINCARTVALUE":
                mapSettings.MINCARTVALUE = Setting.value
                break;
              case "NOOFFERPRICEGROUP":
                mapSettings.NOOFFERPRICEGROUP = Setting.value
                break;
              case "SENDEMAILTOREP":
                mapSettings.SENDEMAILTOREP = Setting.value
                break;
              case "SHOWOFFER":
                mapSettings.SHOWOFFER = (Setting.value == 'true')
                break;
              case "ZEROVALUECATEGORY":
                mapSettings.ZEROVALUECATEGORY = Setting.value //'"TOY"|"RAW"'
                break;
              case "SHOWDISCOUNTONCART":
                mapSettings.SHOWDISCOUNTONCART = (Setting.value == 'true' || Setting.value == 'True')
                break;
              case "Subscription":
                mapSettings.ISSUBSCRIBED = (Setting.value == 'true' || Setting.value == 'True')
                break;
              case "CURRENCYCODE":
                mapSettings.CURRENCYCODE = Setting.value;
                //-- HANDLING EMPTY CURRENCY CODE
                if (mapSettings.CURRENCYCODE == '' || mapSettings.CURRENCYCODE === null) {

                }
                //-- END
                break;

              default:
                break;
            }
          })
          //--Rejectable price group
          let rejecttablePriceGroup = [];
          let userPriceGroup = this.basketService.getCustomer().customerPriceGroup;
          if (mapSettings.NOOFFERPRICEGROUP != '') {
            mapSettings.NOOFFERPRICEGROUP = mapSettings.NOOFFERPRICEGROUP.toLowerCase();
            // if (mapSettings.NOOFFERPRICEGROUP.includes("|")) {
            //   mapSettings.NOOFFERPRICEGROUP=mapSettings.NOOFFERPRICEGROUP.split("|")
            // }
            //-- Alternative for Includes()
            if (mapSettings.NOOFFERPRICEGROUP.indexOf("|") > 0) {
              mapSettings.NOOFFERPRICEGROUP = mapSettings.NOOFFERPRICEGROUP.split("|")
            }
            rejecttablePriceGroup.push(mapSettings.NOOFFERPRICEGROUP)
          }
          // if (rejecttablePriceGroup.includes(userPriceGroup.toLocaleLowerCase()) && mapSettings.SHOWOFFER) {
          //   mapSettings.SHOWOFFER=false;
          // }
          //-- Alternative for Includes()
          if (rejecttablePriceGroup.indexOf(userPriceGroup?.toLocaleLowerCase()) >= 0 && mapSettings.SHOWOFFER) {
            mapSettings.SHOWOFFER = false;
          }

          //--
          Settings = mapSettings;
        }
        return of(Settings)
      }),
      catchError((error:any) => {
        let settings = {
          DEFAULTSORTORDER: "",
          MINCARTVALUE: "",
          NOOFFERPRICEGROUP: "",
          SENDEMAILTOREP: "",
          SHOWOFFER: false,
          ZEROVALUECATEGORY: "",
          SHOWDISCOUNTONCART: false,
          CURRENCYCODE: "USD",
          ISSUBSCRIBED: false
        };
        return of(settings)
      })
    )
    .subscribe((settings:any) => {
      if (settings.CURRENCYCODE == '' || settings.CURRENCYCODE === null) {
        let tenant = this.localeService.code;
        let localeCurrency = this.localeService.localeFormat.currencyCode;
        switch (tenant) {
          case "us":
            settings.CURRENCYCODE = "USD";
            this.localeService.localeFormat.currencyCode = settings.CURRENCYCODE;
            break;
          case "au":
            settings.CURRENCYCODE = "AUD";
            this.localeService.localeFormat.currencyCode = "AUD";
            break;
          case "eu":
            settings.CURRENCYCODE = "DKK";
            this.localeService.localeFormat.currencyCode = "DKK";
            break;
          default:
            break;
        }
      }

      // if (localeCurrency!='') {
      //   settings.CURRENCYCODE=localeCurrency;
      //   this.localeService.localeFormat.currencyCode=localeCurrency;
      // }
      this.settingsService.setSettings(settings);
    })
  //--

      this.shoppingCartService.getShoppingCartItem().subscribe(
        res => {
          let basketData: BasketData = {
            customerInfo: { name: '', customerCode: '', customerPriceGroup: data!.profile.grp_price_code, paymentTermsCode: data!.profile.paymentTermsCode, b2BCustomerType: data!.profile.b2bCustomerType },
            comments: '',
            deliveryDate: null,
            items: res,
            orderNo: '',
            referenceNo: '',
            cancelledDate: null,
          };
          this.basketService.setBasketData(basketData);


          //Offers
          this.offerService.getLatestOffers()
            .pipe(
              share(),
              catchError((error:any) => {
                let offers: any = [];
                return of(offers)
              }))
            .subscribe((offeritems:any) => {
              //-- Remove customer group specific offers
              let customer = this.basketService.getCustomer();
              let filteredOffers = offeritems.filter((offerItem: any) => {
                //return customer.b2BCustomerType==offerItem.b2BPromoCategory || (offerItem.b2BPromoCategory=='' && customer.b2BCustomerType!='');
                // Filter ASTRA,CC,Regular customer offers
                return (customer.b2BCustomerType == '' && offerItem.b2BPromoCategory == '' && customer.paymentTermsCode != 'CC') || (customer.paymentTermsCode == 'CC' && customer.paymentTermsCode == offerItem.b2BPromoCategory) || (customer.b2BCustomerType != '' && customer.paymentTermsCode != 'CC' && (customer.b2BCustomerType == offerItem.b2BPromoCategory || offerItem.b2BPromoCategory == ''))

              })
              offeritems = filteredOffers;
              //--
              this.offerService.setOffers(offeritems)
            })
          //--

        }
      );

      this.securityService.priceGroup$.subscribe(data => {
        data;
      })
      }
    });

    this.globalNoteService.globalNotes$.subscribe(data=>{
      for(let i=0;i<data.length;i++)
      {
          // this.sliderImages.push({'src':item.id+'.jpg'});
          if(router.url != "/access" && router.url != "/")
          {
            this.notificationService.openToastr(data[i].title,data[i].message,data[i].type);
          }
      }
    });
  }
  ngOnInit() {
    this.router.events.subscribe(evt => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });

    this.initStartUpProcess()

  }
  onReloadData() {
    console.log('reninit app component');

  }

  onLeadSelection() {

    this.leadDialog = this.dialog.open(AlertComponent, {
      // minWidth:'550px',
      minHeight: '550px',
      data: { leadSelection: true, type: 'leadSelect' }
    })
    this.leadDialog.afterClosed().subscribe(data => {
      this.basketService.setSelectedLead(data.data);
    })
  }
  onResetPassword() {
    this.securityService.changePasswordUri()
      .subscribe(data =>
        window.location.href = data,
        error => {
          console.log(error)
        });
  }

  /** Get Configurations Start */

  initStartUpProcess() {
    let apiConfigFile = `/assets/config/${environment.envName}/api-config.json`;
    this.getJSONFile(apiConfigFile).then(data => {
      let aApiConfig = data;

      let aConfigURL: string = '';
      if (environment.envName == 'mock'
        || environment.envName == 'dev') {
        aConfigURL = aApiConfig.apiUri + aApiConfig.Configuration;
      }
      else {
        aConfigURL = aApiConfig.Configuration;
      }
       this.GetConfigurations(aConfigURL);
    });
  }

  private getJSONFile(url: string): Promise<any> {
    return this.http.get(url, { responseType: 'json' })
      .toPromise()
      .then(response => response)
      .catch((error: any) => console.log(error));

  }

  async GetConfigurations(pURL: string): Promise<any> {
    this.blockUI.start("Loading...");
    return this.settingsService.getAppConfigurations(pURL)
      .toPromise()
      .then((config: IConfigurations) => {
        config.apiCallNeeded = false;
        this.configurations = config;
        this.store.dispatch(new UserActions.GetConfigurations(config));
        this.salesLeadcall();
        this.blockUI.stop();
      });
  }
  /** Get Configurations End */

  salesLeadcall()
  {
    this.showRouterOutlet = true;
      //-- Select Lead
      if (this.configurations.showSalesLead) {
        this.basketService.selectedLead$.subscribe(data => {
          let userRole = this.securityService.userRole;
          if (data === null && (userRole == "site_admin" || userRole == "rep_admin" || userRole == "rep_user")) {
            this.onLeadSelection();
          }
        });
      }
  }
}
