import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';


import { BasketData, appSettings } from '../../model/basket-data';

import { Item, ShoppingCartItem, DeleteItemOnEditOrder } from '../../model/item';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { CustomerInfo } from 'src/app/model/customer-info';
import { ShoppingCartService } from './shopping-cart.service';
import { ErrorService } from 'src/app/core/services/error.service';
import { Router } from '@angular/router';
import { HttpParams, HttpHeaders, HttpClient } from '@angular/common/http';
import { ApiConfigService } from './api-config.service';
import { SecurityService } from './security.service';
import { LocaleService } from './locale.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ShippingAddress } from 'src/app/basket/model/shippingAddress';

@Injectable()
export class BasketService {

  private basketDataSubject: BehaviorSubject<BasketData> = new BehaviorSubject(this.initLocalStore());
  basketData$: Observable<BasketData> = this.basketDataSubject.asObservable();

  private itemCountSubject: BehaviorSubject<number> = new BehaviorSubject(0);
  itemCount$: Observable<number> = this.itemCountSubject.asObservable();

  private basketTotalSubject: BehaviorSubject<number> = new BehaviorSubject(0);
  basketTotal$: Observable<number> = this.basketTotalSubject.asObservable();

  public appSettingsSubject:BehaviorSubject<appSettings>=new BehaviorSubject(this.initSettings());
  appSetting$:Observable<any>=this.appSettingsSubject.asObservable();

  public selectedLeadSubject:BehaviorSubject<string>=new BehaviorSubject(this.initLeadSelection());
  selectedLead$:Observable<any>=this.selectedLeadSubject.asObservable();

  public deptCodeSubject:BehaviorSubject<string>=new BehaviorSubject('');
  deptCode$:Observable<any>=this.deptCodeSubject.asObservable();

  public isNewCustomerSubeject=new BehaviorSubject<boolean>(false);
  public isNewCustomer$:Observable<any>=this.isNewCustomerSubeject.asObservable();
  
  public priceGroupSubject:BehaviorSubject<string>=new BehaviorSubject('');
  public priceGroup$:Observable<any>=this.priceGroupSubject.asObservable();

  private renderer:Renderer2;

  constructor(
    private http: HttpClient,
    private shoppingCartService: ShoppingCartService,
    private error: ErrorService,
    public router:Router,
    private apiSvc: ApiConfigService,
    public securityService:SecurityService,
    public localeService:LocaleService,
    public modalService:NgbModal,
    public rendererFactory:RendererFactory2) {
    this.basketDataSubject.subscribe(item => {
      this.setItemCountAndPrice(item.items);
    });
    this.renderer = rendererFactory.createRenderer(null, null);
  }


  public setSelectedLead(leadTitle?:string){
    if(leadTitle!==undefined && leadTitle!==null){
      localStorage.setItem("selectedLead",leadTitle);
      this.selectedLeadSubject.next(leadTitle);
    }
  }

  public setDeptCode(deptCode?:string){
    this.deptCodeSubject.next(deptCode?deptCode:"");
  }

  public initLeadSelection():any{
    var selectedLead:any=localStorage.getItem("selectedLead");
    return selectedLead;
  }

  public setBasketData(basketData: BasketData) {
    basketData.items.forEach(item=>{
      item.introductionDate=new Date(item.introductionDate!);
    })
    this.basketDataSubject.next(basketData);
    this.animateCart();
  }

  animateCart(){
    //@ts-ignore
      var btn=$('.cart-animate')[0];
      this.renderer.addClass(btn,'shake-little');
      this.renderer.addClass(btn,'shake-constant');
      setTimeout(()=>{
        this.renderer.removeClass(btn,'shake-constant');
        this.renderer.removeClass(btn,'shake-little');
      },500)
  }

  public setAppSettingsData(settingData:appSettings){
    this.appSettingsSubject.next(settingData);
  }
  public getAppSettingsData(){
    return this.appSettingsSubject.getValue();
  }

  public addItem(item: Partial<Item>) {
    if (item != null && item.quantity! > 0) {
      let qty = item.quantity;
      let itemExists: Partial<Item>;
      let basketData:any = this.basketDataSubject.getValue();
      if (item != null && basketData.items.length > 0) {

        itemExists = basketData
          .items.find((p:any) => p.itemNumber === item.itemNumber);
        if (itemExists !== undefined) {
          qty = itemExists.quantity! + item.quantity!;
        }

      }

 
      let service = this.shoppingCartService.addShoppingCartItem(
        { itemNo: item.itemNumber, quantity: qty },
        this.getCustomerCode(),this.getPriceGroup());
      if (basketData.orderNo != '') {
        service = this.shoppingCartService.addItemOnOrderEdit({
          orderNo: basketData.orderNo,
          itemNumber: item.itemNumber!,
          quantity: item.quantity!,
          uom: item.uom!
        },this.getCustomerCode(),this.getPriceGroup());
      }
      service.subscribe((res) => {
        if (itemExists !== undefined) {
          itemExists.quantity = qty;
        }
        else {
          basketData.items.push(item);
        }
        this.basketDataSubject.next(basketData);
        
          // const modalRef = this.modalService.open(ModalPopupComponent);
          // modalRef.componentInstance.title = item.itemNumber+" added";
          // modalRef.componentInstance.content = "Item added successfully";

        // this.toastr.info('', `${item.itemNumber} - added to cart.`);
      },
        error => this.error.handleErrorWithAlert(error));

    }

  }

  public addItems(items: Partial<Item>[], customerCode?: string) {
    
    if (items != null && items.length > 0) {
      let shoppingCartItems: ShoppingCartItem[] = [];
      let existingItems:any=this.basketDataSubject.getValue().items;

      existingItems.forEach((item:any)=>{
        item.itemNo=item.itemNumber
      })
            
      items.forEach(item => {
        //-- New code
        let alreadyExists=undefined;
        if (item.itemNumber) {
          alreadyExists=existingItems.findIndex((eItem:any)=>{ return eItem.itemNumber ==item.itemNumber  })
        }
        else{
          alreadyExists=existingItems.findIndex((eItem:any)=>{ return eItem.itemNumber ==item.no  })
        }
        //-- End

        if (item.itemNumber) {
          //-- New Code, Append with existing items
            if (alreadyExists !== undefined && alreadyExists>=0 ) {
              item.quantity=item.quantity+existingItems[alreadyExists].quantity;
              existingItems[alreadyExists].itemNo=item.itemNumber;
              existingItems[alreadyExists].quantity=item.quantity;
            }
            else{
              shoppingCartItems.push({ itemNo: item.itemNumber, quantity: item.quantity });  
              item.itemNo=item.itemNumber;
              existingItems.push(item)
            }
          //--
          //--Old
            //shoppingCartItems.push({ itemNo: item.itemNumber, quantity: item.quantity });
          //--
        }
        else{
          //-- New Code, Append with existing items
            if (alreadyExists !== undefined) {
              item.quantity=item.quantity+existingItems[alreadyExists].quantity;
              existingItems[alreadyExists].quantity=item.quantity;
            }
            else{
              shoppingCartItems.push({ itemNo: item.no, quantity: item.quantity });  
              existingItems.push(item)
            }
          //--
          //--Old
            //shoppingCartItems.push({ itemNo: item.no, quantity: item.quantity });  
          //--
        }
        
      });
      this.shoppingCartService.addShoppingCartItems(existingItems,
        this.getCustomerCode(),this.getPriceGroup()).subscribe(() => {
        let basketData = this.basketDataSubject.getValue();
        
        // basketData.items = [];
        // items.forEach(item => {
        //   basketData.items.push(item);
        // });
        
        // basketData.customerInfo = { name: '', customerCode: customerCode };  //donot uncomment this line
        
        this.basketDataSubject.next(basketData);
        this.animateCart();
      },
        error => this.error.handleErrorWithAlert(error));

    }

  }

  public updateItem(item: Item) {
    if (item != null && item.quantity > 0) {
      let qty = item.tempQuantity;
      let itemExists: Partial<Item>;
      let basketData:any = this.basketDataSubject.getValue();
      if (basketData.items.length > 0) {
        itemExists = basketData
          .items.find((p:any) => p.itemNumber === item.itemNumber);

        qty = item.quantity;

      }

      let service = this.shoppingCartService.addShoppingCartItem(
        { itemNo: item.itemNumber, quantity: qty },
        this.getCustomerCode(),this.getPriceGroup())
      if (basketData.orderNo != '') {
        service = this.shoppingCartService.addItemOnOrderEdit({
          orderNo: basketData.orderNo,
          itemNumber: item.itemNumber,
          quantity: item.quantity,
          uom: item.uom
        },this.getCustomerCode(),this.getPriceGroup());
      }
      service.subscribe((res) => {
        if (itemExists !== undefined) {
          itemExists.quantity = qty;
        }

        this.basketDataSubject.next(basketData);
        this.animateCart();
        // this.toastr.info('', `${item.itemNumber} - Updated to cart.`);
      },
        error => this.error.handleErrorWithAlert(error));

    }
  }

  public removeItem(item: DeleteItemOnEditOrder) {
    let basketData = this.basketDataSubject.getValue();
    
    let service = this.shoppingCartService.deleteShoppingCartItem(item.itemNumber,this.getCustomerCode(),this.getPriceGroup());
    if (basketData.orderNo!='') {
    item.orderNo = basketData.orderNo;
      service=this.shoppingCartService.deleteItemOnOrderEdit(item, this.getCustomerCode(),this.getPriceGroup());
    }
    service.subscribe((res)=>{
      basketData.items.splice(basketData.items.indexOf(item),1);
      this.basketDataSubject.next(basketData);
      this.animateCart();
    },error=>this.error.handleErrorWithAlert(error));
    return true
    //--- OLD LOGIC
    // basketData.items.some((itm, i) => {
    //   if (itm.itemNumber === item.itemNumber) {
    //     let service = this.shoppingCartService.deleteShoppingCartItem(
    //       itm.itemNumber,
    //       this.getCustomerCode());
    //     if (basketData.orderNo != '') {
    //       item.orderNo = basketData.orderNo;
    //       service = this.shoppingCartService.deleteItemOnOrderEdit(item, this.getCustomerCode());
    //       debugger
    //     }
    //     service.subscribe((res) => {
    //       basketData.items.splice(i, 1);
    //       this.basketDataSubject.next(basketData);
    //       this.toastr.info('', `${itm.itemNumber} - Successfully Removed`);
    //     },
    //       error => this.error.handleErrorWithAlert(error));
    //     return true;

    //   }
    // });
    //---
  }

  public setItemCountAndPrice(items: Partial<Item>[]): void {
    if (items != null) {
      let count = 0;
      let total = 0;
      items.forEach((item:any) => {
        count += item.quantity!;
        total += ((item.unitPrice * item.quantity) - (item.quantity * (item.unitPrice * (item.lineDiscount / 100))));
      });
      this.itemCountSubject.next(count);
      this.basketTotalSubject.next(total);
    }

  }

  public getTotalAmount(): any {
    return this.basketDataSubject.pipe(map((basketData: BasketData) => {
      return basketData.items.reduce((prev:any, curr: any) => {
        return prev + ((curr.unitPrice * curr.quantity) - (curr.quantity * (curr.unitPrice * (curr.lineDiscount / 100))));
      }, 0);
    }));
  }
  
  public getCustomer(): CustomerInfo {
    this.basketDataSubject.getValue().customerInfo;
    return this.basketDataSubject.getValue().customerInfo;
  }

  public getCustomerCode(): string {
    let customer = this.basketDataSubject.getValue().customerInfo;

    if (customer !== null && customer.customerCode !== '') {
      return customer.customerCode;
    }
    return '';
  }

  public setCustomer(customerInfo: CustomerInfo) {
    if (customerInfo !== null && customerInfo.customerCode !== '') {
      let basketData = this.basketDataSubject.getValue();
      localStorage.setItem("selectedCustCode",customerInfo.customerCode);
      localStorage.setItem("selectedCustName",customerInfo.name);
      localStorage.setItem("selectedCustGrp",customerInfo.customerPriceGroup!);
      localStorage.setItem("b2bCustomerType",customerInfo.b2BCustomerType);
      localStorage.setItem("paymentTermsCode",customerInfo.paymentTermsCode!);
      //basketData.customerInfo = customerInfo;
      basketData.customerInfo.customerCode = localStorage.getItem("selectedCustCode");
      basketData.customerInfo.name = localStorage.getItem("selectedCustName");
      basketData.customerInfo.customerPriceGroup= localStorage.getItem("selectedCustGrp");
      basketData.customerInfo.paymentTermsCode= localStorage.getItem("paymentTermsCode");
      basketData.customerInfo.b2BCustomerType = localStorage.getItem("b2bCustomerType");

      this.basketDataSubject.next(basketData);
    }
  }


  public clear() {
    let x=this.getCustomerCode();
    let basketData:any = {
      customerInfo: this.getCustomer(),
      comments: '',
      orderNo: '',
      referenceNo: '',
      items: [],
      deliveryDate: new Date(),
      shiptoCode:null
    };
    this.shoppingCartService.deleteShoppingCart(this.getCustomerCode()).subscribe(() => {
      // this.basketDataSubject.next(this.initLocalStore());
      this.basketDataSubject.next(basketData);
    },
      error => this.error.handleErrorWithAlert(error));
  }

  private initLocalStore(): BasketData {
      let basketData:BasketData = {
        customerInfo: {
          customerCode: '',
          name: ''
        },
        comments: '',
        orderNo: '',
        referenceNo: '',
        items: [],
        deliveryDate: null,
        cancelledDate:null
        
      };
    return basketData;
  }

  public initSettings(): appSettings{
    let initSettingData={
      MINCARTVALUE:"",
      ZEROVALUECATEGORY:"",
      DEFAULTSORTORDER:"",
      SENDEMAILTOREP:"",
    }
    return initSettingData;
  }

  public getBasketData():BasketData{
    return this.basketDataSubject.getValue();
  }
  getAppSettings(params:any,tenant:any){
    let settingURL=this.apiSvc.formatUrl(this.apiSvc.apiConfig.appSettings);
    let httpParams:HttpParams=new HttpParams();
    // .set('tenant',tenant);
    return this.http.get(settingURL,{headers:this.getHeader(),params:httpParams});
  }
  private getHeader(): HttpHeaders {
    return this.securityService.formatRequestHeader(this.getCustomerCode(),this.getPriceGroup());  
  }

  requestNewAddress(address:ShippingAddress):Observable<any>{
    return this.shoppingCartService.addNewAddress(address).pipe(
      catchError(error=>{
        return of(error)
      })
    )
  }

  subscriberStatus(status?:boolean):Observable<any>{
    return this.shoppingCartService.changeSubscriberStatus(status?status:false).pipe(
      catchError(error=>{
        let status=false;
        return of(status);
      })
    )
  }

  setIsNewCustomer(isNewCustomer:boolean){
    this.isNewCustomerSubeject.next(isNewCustomer);
  }
  
  getIsNewCustomer(){
    return this.isNewCustomerSubeject.getValue();
  }

  setPriceGroup(priceGroup:string){
    this.priceGroupSubject.next(priceGroup);
  }
  
  getPriceGroup(){
    return this.priceGroupSubject.getValue();
  }

  AddMultipleItemsToShoppingCart(BasketItems:ShoppingCartItem[],Items:Partial<Item>[])
  {
    if(BasketItems.length > 0)
        {
          this.shoppingCartService.addShoppingCartItems(BasketItems,
            this.getCustomerCode(),this.getPriceGroup()).subscribe(() => {
              let basketData = this.basketDataSubject.getValue();
              basketData.items = Items;
              this.basketDataSubject.next(basketData);
              this.animateCart();
            this.router.navigate(['/basket']);
          },
            error => this.error.handleErrorWithAlert(error));
        }
  }

}
