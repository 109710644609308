import { Component, OnInit } from '@angular/core';

import {Notification} from "../../../model/notification"
import { NotificationService } from '../../../core/services/notification.service';
import { Observable } from 'rxjs';
@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {
  notifications$!: Observable<Notification[]>;

  private staticAlertClosed:boolean = false;

  constructor(private notificationService:NotificationService) {

  }

  ngOnInit() {
        this.notifications$ = this.notificationService.notifications$;
  }

    close(notification:Notification) {
      this.notificationService.close(notification);
    }
}
