import { Injectable } from '@angular/core';

import { Notification } from '../../model/notification';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiConfigService } from './api-config.service';
import { SecurityService } from './security.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class GlobalNoteService {
  private globalNoteSubject = new BehaviorSubject<Notification[]>([]);
  globalNotes$: Observable<Notification[]> = this.globalNoteSubject.asObservable();

  constructor(private http:HttpClient,
    private apiSvc:ApiConfigService,
    private securityService:SecurityService) { 
      this.getGlobalNotes();
    }
    

  error(title: string, message: string) {
    let notification: Notification[] = [{
      id:'', 
      title: title,
      message: message,
      type: "danger",
      icon: "objects_support-17",
    }];
    this.globalNoteSubject.next(this.globalNoteSubject.getValue().concat(notification));
  }

  success(title: string, message: string) {
    let notification: Notification = {
      id:'', 
      title: title,
      message: message,
      type: "success",
      icon: "ui-2_like",
    };
    this.globalNoteSubject.next(this.globalNoteSubject.getValue().concat(notification));
  }

  warning(title: string, message: string) {
    let notification: Notification = {
      id:'', 
      title: title,
      message: message,
      type: "warning",
      icon: "ui-1_bell-53",
    };
    this.globalNoteSubject.next(this.globalNoteSubject.getValue().concat(notification));
  }

  info(title: string, message: string) {
    let notification: Notification = {
      id:'', 
      title: title,
      message: message,
      type: "info",
      icon: "travel_info",
    };
    this.globalNoteSubject.next(this.globalNoteSubject.getValue().concat(notification));
  }

  close(notification: Notification) {
    let notifications = this.globalNoteSubject.getValue();
    let index = notifications.indexOf(notification);    
    notifications.splice(index, 1)
    this.globalNoteSubject.next(notifications);
   
  }

  clearAll() {
    this.globalNoteSubject.next([]);
  }


  getGlobalNotes()
  {
    let url = this.apiSvc.formatUrl(this.apiSvc.apiConfig.globalNote);                      
      this.http.get<Notification[]>(url, {
        headers: this.getHeader()
      }).subscribe(res=>{
        this.globalNoteSubject.next(res);
      });
  }

  private getHeader(): HttpHeaders { 
    return this.securityService.formatRequestHeader();  

  }
}
