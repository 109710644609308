import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-buyer-account-receivable-contact-info',
  templateUrl: './buyer-account-receivable-contact-info.component.html'
})
export class BuyerAccountReceivableContactInfoComponent implements OnInit {
  @Input() myform!: FormGroup;
  constructor() { }

  ngOnInit() {
  }

  ReturnFormControl(pControl:string)
  {
    return (<FormControl>(this.myform).controls[pControl]);
  }

}
