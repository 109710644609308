
<div [formGroup]="myform">
  <div class="form-group">
    <app-address [myform]="ReturnMyFormWithControl('address1')"></app-address>
  </div>
  <mat-form-field>
      <mat-label>Country</mat-label>
      <input matInput [formControl]="ReturnFormControl('country')">
      <mat-error> You must include Country.</mat-error>
  </mat-form-field>
  <!-- <div class="form-group">
      <label>Country *</label>
      <div>
        <select class="form-control" [formControl]="myform.controls['country']">
          <option *ngFor="let country of countries" [value]="country.name">{{country.name}}</option>
        </select>
        <span *ngIf="myform.controls['country'].hasError('required')
                              && myform.controls['country'].touched"
          class="text-danger">
          You must include Country.
        </span>
      </div>
    </div> -->
  <mat-form-field>
      <mat-label>{{('retailer.PHONE'|translate)}}</mat-label>
      <input matInput [formControl]="ReturnFormControl('primaryPhone')">
      <mat-error> You must include {{('retailer.PHONE'|translate)}}</mat-error>
  </mat-form-field>
    <!-- <div class="form-group">
      <label>Primary Phone *</label>
      <div>
        <input class="form-control" type="text" [formControl]="myform.controls['primaryPhone']" />
        <span *ngIf="myform.controls['primaryPhone'].hasError('required')
                              && myform.controls['primaryPhone'].touched"
          class="text-danger">
          You must include Primary Phone No.
        </span>
      </div>
    </div> -->
  <mat-form-field>
      <mat-label>{{('retailer.FAX'|translate)}}</mat-label>
      <input matInput [formControl]="ReturnFormControl('fax')">
      <mat-error> You must include {{('retailer.FAX'|translate)}}</mat-error>
  </mat-form-field>  
  <!-- <div class="form-group">
    <label>Fax</label>
    <div>
      <input class="form-control" type="text" [formControl]="myform.controls['fax']" />
      <span *ngIf="myform.controls['fax'].hasError('required')
                            && myform.controls['fax'].touched"
        class="text-danger">
        You must include fax.
      </span>
    </div>
  </div> -->
  <mat-form-field>
      <mat-label>{{('retailer.MOBILE'|translate)}}</mat-label>
      <input matInput [formControl]="ReturnFormControl('mobile')">
      <mat-error> You must include {{('retailer.MOBILE'|translate)}} No.</mat-error>
  </mat-form-field>
  <!-- <div class="form-group">
      <label>Mobile</label>
      <div>
        <input class="form-control" type="text" [formControl]="myform.controls['mobile']" />
        <span *ngIf="myform.controls['mobile'].hasError('required')
                              && myform.controls['mobile'].touched"
          class="text-danger">
          You must include Mobile No.
        </span>
      </div>
    </div> -->
</div>
<app-validation-summary [form]="myform"></app-validation-summary>
<app-validation-summary [form]="ReturnMyFormWithControl('address1')"></app-validation-summary>