<div [formGroup]="myform">
    <div class="">
        <div class="col-xs-12 location-forms">
            <h4> <i class="material-icons">business</i> {{('retailer.COMPANYDETAILS'|translate)}}</h4>
            <mat-form-field>
                <mat-label>{{('retailer.COMPANYNAME'|translate)}} *</mat-label>
                <input matInput [formControl]="ReturnFormControl('companyName')" placeholder="Company name">
                <mat-error>You must include Legal {{('retailer.COMPANYNAME'|translate)}}.</mat-error>
            </mat-form-field>
            <!-- <div class="form-group">
                <label>Legal Company Name *</label>
                <div>
                    <input class="form-control" type="text" [formControl]="myform.controls['companyName']" />
                    <span *ngIf="myform.controls['companyName'].hasError('required') 
                            && myform.controls['companyName'].touched  "
                        class="text-danger">
                        You must include Legal Company Name.
                    </span>
                </div>
            </div> -->
            <mat-form-field *ngIf="configurations.retailer.businessContactInfo.showDBAFormField">
                <mat-label>DBA # *</mat-label>
                <input matInput [formControl]="ReturnFormControl('dba')" placeholder="dba">
                <mat-error> You must include DBA No.</mat-error>
            </mat-form-field>
            <!-- <div class="form-group">
                <label>DBA # *</label>
                <div>
                    <input class="form-control" type="text" [formControl]="myform.controls['dba']" />
                    <span *ngIf="myform.controls['dba'].hasError('required')
                                && myform.controls['dba'].touched"
                        class="text-danger">
                        You must include DBA No.
                    </span>
                </div>
            </div> -->
            <mat-form-field>
                <mat-label>Contact name</mat-label>
                <input matInput [formControl]="ReturnFormControl('contactName')" placeholder="Contact Name ">
                <mat-error> You must include Contact Name.</mat-error>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Email</mat-label>
                <input matInput [formControl]="ReturnFormControl('email')" placeholder="email">
                <mat-error> You must include Contact Email-id.</mat-error>
            </mat-form-field>
            <mat-form-field *ngIf="configurations.retailer.businessContactInfo.showResaleCertificateFormField">
                <mat-label>Resale Certificate</mat-label>
                <input matInput [formControl]="ReturnFormControlWithTwoControl('custResaleOrTaxId','resaleCertificateNo')" placeholder="Resale Certificate">
                <mat-error> Resale Certificate</mat-error>
            </mat-form-field>
            <!-- <div class="form-group">
                <label>Resale Certificate #</label>
                <div>
                    <input class="form-control" type="text" [formControl]="myform.get('custResaleOrTaxId').get('resaleCertificateNo')" />
                    <span *ngIf="myform.get('custResaleOrTaxId').hasError('atLeastOne')
                    && (myform.get('custResaleOrTaxId').get('taxExemptId').touched || 
                        myform.get('custResaleOrTaxId').get('resaleCertificateNo').touched)" 
                        class="text-danger">
                        You must include Resale Certificate No Or Tax Exempt ID.
                    </span>
                </div>
            </div> -->
            <mat-form-field>
                <mat-label>{{('retailer.TAXEXEMPTID'|translate)}}</mat-label>
                <input matInput [formControl]="ReturnFormControlWithTwoControl('custResaleOrTaxId','taxExemptId')">
                <mat-error> You must include Resale Certificate No Or Tax Exempt ID.</mat-error>
            </mat-form-field>
            <!-- <div class="form-group">

                <label>Tax Exempt ID #</label>
                <div>
                    <input class="form-control" type="text" [formControl]="myform.get('custResaleOrTaxId').get('taxExemptId')" />
                    <span *ngIf="myform.get('custResaleOrTaxId').hasError('atLeastOne')
                    && (myform.get('custResaleOrTaxId').get('taxExemptId').touched || 
                        myform.get('custResaleOrTaxId').get('resaleCertificateNo').touched)" 
                        class="text-danger">
                        You must include Resale Certificate No Or Tax Exempt ID.
                    </span>
                </div>
                
            </div> -->
        </div>
        
    </div>
    <app-validation-summary [form]="myform"></app-validation-summary>
    
    
    <div class="row location-forms">
        
        <div class="col-sm-6">
            
            <h4> <i class="material-icons">location_on</i> {{('retailer.TRADINGADDRESS'|translate)}}</h4>
            <div class="form">
                    <app-business-address [myform]="ReturnFormGroupWithControl('billingAddress')"></app-business-address>
            </div>
            <!-- <app-address [myform]="myform.controls.billingAddress"></app-address> -->
            
        </div>
        <div class="col-sm-6">
            
            <h4><i class="material-icons">location_on</i> {{('retailer.SHIPPINGADDRESS'|translate)}} </h4>
            <div class="form">
                    <app-business-address [myform]="ReturnFormGroupWithControl('shippingAddress')"></app-business-address>
            </div>
            
            
        </div>
    </div>
    <!-- <app-validation-summary [form]="myform"></app-validation-summary> -->
</div>