import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, ValidatorFn, ValidationErrors, FormControl } from '@angular/forms';
import { ErrorService } from '../../core/services/error.service';
import { ApiConfigService } from '../../core/services/api-config.service';
import { BusinessContactInfo, Address, BusinessCreditInfo, BusinessReference, RetailerReq, ContactInfo, BusinessAddress, OwnerInfo } from './model/retailer.model';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { SecurityService } from '../../core/services/security.service';
import { LocaleService } from 'src/app/core/services/locale.service';

//Group validation
export const atLeastOne = (validator: ValidatorFn) => (
  group: FormGroup,
  ): ValidationErrors | null => {
   const hasAtLeastOne = group && group.controls && Object.keys(group.controls)
     .some(k => !validator(group.controls[k]));

    return hasAtLeastOne ? null : {
      atLeastOne: true,
    };
  };

@Component({
  selector: 'app-retailer',
  templateUrl: './retailer.component.html',
  styleUrls: ['./retailer.component.scss']
})
export class RetailerComponent implements OnInit {
  firstFormGroup!: FormGroup;
  secondFormGroup!: FormGroup;
  thirdFormGroup!:FormGroup;
  isOptional = false;
  businessTypes=['Sole Proprietor','Company','Company','Trust'];
  businessLease=['Casual','6 Monthly','Annually'];

  isCustomerReqDisabled: boolean = false;
  public myform!: FormGroup;

  constructor(private fb: FormBuilder,
    private apiSvc: ApiConfigService,
    private http: HttpClient,
    private errorService: ErrorService,
    private securityService: SecurityService,
    public localeService:LocaleService) {

  }


  ngOnInit() {
    let retailer = this.getData();
    this.myform = this.fb.group({
      businessContactInfo: this.addBCI(retailer.businessContactInfo),
      //businessCreditInfo: this.addBCreditInfo(retailer.businessCreditInfo),
      buyerContactInfo: this.addContactInfo(retailer.buyerContactInfo),
      accountReceivableContactInfo:this.addContactInfo(retailer.accountReceivableContactInfo),
      termsAgreed: [retailer.termsAgreed, Validators.requiredTrue],
      OwnerInfo:this.addOwnInfo(retailer.OwnerInfo),
      businessRef1: this.addBRef(retailer.businessRef1),
      businessRef2: this.addBRef(retailer.businessRef2),
      businessRef3: this.addBRef(retailer.businessRef3),
    });
  }

  addContactInfo(data: ContactInfo) {
    return this.fb.group({
      contactName: [data.contactName, Validators.required],
      primaryPhone: [data.primaryPhone, Validators.required],
      fax: [data.fax],
      mobile: [data.mobile],
      email: [data.email, [Validators.required,Validators.pattern(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/)]]
    })
  }

  addBCI(data: BusinessContactInfo) {
    let BCI=this.fb.group({
      companyName: [data.companyName, Validators.required],
      dba: [data.dba, Validators.required],
      email:[data.email,[Validators.required,Validators.pattern(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/)]],
      contactName:[data.contactName,Validators.required],
      billingAddress: this.addBusinessAddress(data.billingAddress),
      shippingAddress: this.addBusinessAddress(data.shippingAddress),
      custResaleOrTaxId: this.addCustResaleOrTaxId(data)
    });
    if(this.localeService.code=='au'){
      BCI=this.fb.group({
        companyName: [data.companyName, Validators.required],
        dba: [data.dba],
        contactName:[data.contactName,Validators.required],
        email:[data.email],
        billingAddress: this.addBusinessAddress(data.billingAddress),
        shippingAddress: this.addBusinessAddress(data.shippingAddress),
        custResaleOrTaxId: this.addCustResaleOrTaxId(data)
      });
    }
    return BCI;
  }

  addCustResaleOrTaxId(data:BusinessContactInfo){
    return this.fb.group({
      resaleCertificateNo: [data.resaleCertificateNo],
      taxExemptId: [data.taxExemptId]
    },{validator:atLeastOne(Validators.required)})
  }

  addBusinessAddress(data: BusinessAddress) {
    return this.fb.group({
      address1: this.addAddress(data.address),
      primaryPhone: [data.primaryPhone, Validators.required],
      fax: [data.fax],
      mobile: [data.mobile],
      country: [data.country, Validators.required]
    });
  }
  addAddress(data: Address) {
    return this.fb.group({
      address1: [data.address1, Validators.required],
      city: [data.city, Validators.required],
      state: [data.state, Validators.required],
      zipCode: [data.zipCode, Validators.required]
    });
  }

  addBCreditInfo(data: BusinessCreditInfo) {
    return this.fb.group({
      accountPayableName: [data.accountPayableName, Validators.required],
      phone: [data.phone, Validators.required],
      fax: [data.fax, Validators.required],
      email: [data.email, [Validators.required, Validators.pattern(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/)]],
      bankName: [data.bankName, Validators.required],
      bankAddress:this.addAddress(data.bankAddress),
      savingAccountNumber: [data.savingAccountNumber, Validators.required],
      checkingAccountNumber: [data.checkingAccountNumber, Validators.required],
      otherAccountNumber: [data.otherAccountNumber, Validators.required]
    });
  }

  addBRef(data: BusinessReference) {
    return this.fb.group({
      name: [data.name, Validators.required],
      phone: [data.phone, Validators.required],
      companyName: [data.companyName, Validators.required],
      email: [data.email, [Validators.required, Validators.pattern(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/)]]
    });
  }
  addOwnInfo(data:OwnerInfo){
    return this.fb.group({
      name:[data.name,Validators.required],
      phone:[data.phone, Validators.required],
      address:[data.address, Validators.required],
      licenceNo:[data.licenceNo, Validators.required],
      businessType:[data.businessType],
      currentBLease:[data.currentBLease]
    })
  }

  private getData(): RetailerReq {
    let retailer: RetailerReq = {

      businessContactInfo: {
        companyName: '',
        dba: '',
        email:'',
        contactName:'',
        billingAddress: {
          address: {
            address1: '',
            city: '',
            state: '',
            zipCode: ''
          },
          primaryPhone: '',
          country: '',
          mobile: '',
          fax: ''
        },
        shippingAddress: {
          address: {
            address1: '',
            city: '',
            state: '',
            zipCode: ''
          },
          primaryPhone: '',
          country: '',
          mobile: '',
          fax: ''
        },
        resaleCertificateNo: '',
        taxExemptId: ''
      },
      businessCreditInfo: {
        accountPayableName: '',
        phone: '',
        fax: '',
        email: '',

        bankName: '',
        bankAddress: {
          address1: '',
          city: '',
          state: '',
          zipCode: '',
        },
        savingAccountNumber: '',
        checkingAccountNumber: '',
        otherAccountNumber: '',
      },
      buyerContactInfo:{
        contactName:'',
        primaryPhone:'',
        fax:'',
        mobile:'',
        email:''
      },
      accountReceivableContactInfo:{
        contactName:'',
        primaryPhone:'',
        fax:'',
        mobile:'',
        email:''
      },
      OwnerInfo:{
        name:'',
        phone:'',
        address:'',
        licenceNo:'',
        businessType:this.businessTypes,
        currentBLease:this.businessLease
      },
      termsAgreed: false,
      businessRef1: {
        name: '',
        phone: '',
        companyName:'',
        email: ''
      },
      businessRef2: {
        name: '',
        phone: '',
        companyName:'',
        email: ''
      },
      businessRef3: {
        name: '',
        phone: '',
        companyName:'',
        email: ''
      }
    };

    return retailer;
  }

  onSendRequest() {
    let url = this.apiSvc.formatUrl(this.apiSvc.apiConfig.customerNewReqApi);
    let termsAgreed = this.myform.controls['termsAgreed'].value;
    if (this.myform.valid && termsAgreed) {
      this.http.post(url, this.myform.value, {
        headers: this.getHeader()
      }).subscribe(
        data => {
          this.isCustomerReqDisabled = true;
        },
        (err: HttpErrorResponse) => {
          this.errorService.handleErrorWithAlert(err);
        });
    }
  }

  private getHeader(): HttpHeaders {
    return this.securityService.formatRequestHeader();
  }

  ReturnFormGroupWithControl(pControl:string)
  {
    return <FormGroup>this.myform.controls[pControl];
  }

  ReturnFormControl(pControl:string)
  {
    return (<FormControl>(this.myform).controls[pControl]);
  }

  RetuFormControlWithOwnerInfoControl(pControl:string)
  {
    return (<FormControl>(<FormGroup>this.myform.controls.OwnerInfo).controls[pControl]);
  }

  ReturnMyFormControlWithValidorNot(pControl:string)
  {
    return this.myform.controls[pControl].valid
  }

  ReturnMyFormValidorNot()
  {
    return this.myform.valid
  }

}
