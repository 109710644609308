import { Component, OnInit, Input, OnChanges, ViewChild, AfterViewInit } from '@angular/core';
import * as Immutable from 'immutable';
import { OwlCarousel } from 'ngx-owl-carousel';
import { ImageConfig } from 'src/app/model/api-config';
import { CatalogService } from 'src/app/catalog/services/catalog.service';
import { CurrencyPipe } from '@angular/common';
import { LocaleService } from 'src/app/core/services/locale.service';
import { LocaleFormat } from 'src/app/model/locale-format';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-slide-show',
  templateUrl: './slide-show.component.html',
  styleUrls: ['./slide-show.component.scss']
})
export class SlideShowComponent implements OnInit,OnChanges,AfterViewInit {

  @Input() options!:any;
  @Input() items!:any;
  @ViewChild('owlElement',{static:false}) owlElement!: OwlCarousel;
  Sharedoptions!:any;
  public imageConfig!:ImageConfig;
  images!:any;
  currencyFormat!:any;
  format!:LocaleFormat;
  hide=false;

  customOptions: OwlOptions = {
    loop: true,
    dots: false,
    nav: true,
    items:1,
    autoplay:true,
    autoplayTimeout:10000,
    autoplaySpeed:4000
  }

  constructor(public catalogService:CatalogService,public currencyPipe:CurrencyPipe,public localeService:LocaleService) { }

  ngOnInit() {
    this.Sharedoptions = Immutable.Map(this.options);
    this.imageConfig=this.catalogService.getImageConfig();
    this.format=this.localeService.localeFormat;
  }
  ngOnChanges(changes:any){
    this.hide=true;
    setTimeout(()=>{this.hide=false},0)
  }
  ngAfterViewInit(){

  }

}
