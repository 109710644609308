import { Injectable } from '@angular/core';
import { LoaderserviceService } from './loaderservice.service';
import { HttpInterceptor, HttpRequest, HttpEvent, HttpHandler, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpinterceptorserviceService implements HttpInterceptor {
  private request:HttpRequest<any>[]=[]
  constructor(private loaderService:LoaderserviceService) { }

  intercept(req: HttpRequest<any>,next: HttpHandler) : Observable<HttpEvent<any>>{
    this.request.push(req);
    this.loaderService.setState(true);
    return Observable.create((observer:any) => {
      const subscription = next.handle(req)
          .subscribe(
              event => {
                  if (event instanceof HttpResponse) {
                      this.removeRequest(req);
                      observer.next(event);
                  }
              },
              err => {

                  this.removeRequest(req);
                  observer.error(err);
              },
              () => {
                  this.removeRequest(req);
                  observer.complete();
              });
      // remove request from queue when cancelled
      return () => {
          this.removeRequest(req);
          subscription.unsubscribe();
      };
  });
  }
  removeRequest(req: HttpRequest<any>) {
    const i = this.request.indexOf(req);
    if (i >= 0) {
        this.request.splice(i, 1);
    }
    this.loaderService.setState(this.request.length > 0)
}

}
