import { Component, OnInit, ElementRef, Renderer2, RendererFactory2, Input } from '@angular/core';
import { Location } from '@angular/common';
import { BasketService } from '../../core/services/basket.service';

import { SecurityService } from '../../core/services/security.service';
import { ErrorService } from '../../core/services/error.service';
import { ImageDownloadService } from '../../core/services/image-download.service';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { WishlistService } from 'src/app/core/services/wishlist.service';
import { MatDialog } from '@angular/material/dialog';
import { AlertComponent } from 'src/app/shared/component/alert/alert.component';
import { LocaleService } from 'src/app/core/services/locale.service';
import { OrderService } from 'src/app/core/services/order.service';
import { OffersService } from 'src/app/core/services/offers.service';
import { CatalogService } from 'src/app/catalog/services/catalog.service';
import { SettingsService } from 'src/app/core/services/settings.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalPopupComponent } from 'src/app/shared/component/modal-popup/modal-popup.component';
import { IConfigurations } from 'src/app/core/models/settings.model';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
    private toggleButton: any;
    private sidebarVisible: boolean;
    public likedProducts:any[]=[];
    public showOffer!:boolean | any;
    public downloadImageCount:number=0;
    private renderer!:Renderer2;

    @Input("IsAvailInvoiceCustomer") IsAvailInvoiceCustomer:boolean = false;
    configurations:IConfigurations = {} as IConfigurations;

    constructor(public location: Location,
        private element : ElementRef,
        private basketService:BasketService,
        private securityService:SecurityService,
        private error:ErrorService,
        public router:Router,
        public wishlist:WishlistService,
        public dialog:MatDialog,
        private imageService:ImageDownloadService,
        public localeService:LocaleService,
        public orderService:OrderService,
        public offerService:OffersService,
        public catalogService:CatalogService,
        public settingsService:SettingsService,
        public modalService:NgbModal,
        public rendererFactory:RendererFactory2
        ) {
        this.sidebarVisible = false;
        this.configurations = this.localeService.GetConfigFromStore();
    }

    ngOnInit() {
        const navbar: HTMLElement = this.element.nativeElement;
        this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];
        this.wishlist.wishlist.subscribe(data=>{
            this.likedProducts=data;
        })
        this.imageService.getImageListCount().subscribe(count=>{
            this.downloadImageCount=count
        })
        //-- NEW SETTINGS CODE
        let showOffer=false;
        this.showOffer=false;
        let rejecttablePriceGroup:any=[];
        let userPriceGroup= this.securityService.userPriceGrp;
        this.settingsService.settings.subscribe(setting=>{
            this.showOffer=setting.SHOWOFFER;
            //-- Rejectable user price group
            setting.NOOFFERPRICEGROUP=setting.NOOFFERPRICEGROUP.toLowerCase();
                    if (String.prototype.includes!) {
                        if (setting.NOOFFERPRICEGROUP.includes("|")) {
                            setting.NOOFFERPRICEGROUP=setting.NOOFFERPRICEGROUP.split("|");
                        }
                    }
                    rejecttablePriceGroup.push(setting.NOOFFERPRICEGROUP);
            if (rejecttablePriceGroup.indexOf(userPriceGroup.toLowerCase())>0 && this.showOffer) {
                this.showOffer=false;
                let resetOffer={SHOWOFFER:false}
                this.settingsService.setSettings(resetOffer);
                }
        })

    }
    sidebarOpen() {
        const toggleButton = this.toggleButton;
        const html = document.getElementsByTagName('html')[0];
        setTimeout(function(){
            toggleButton.classList.add('toggled');
        }, 500);
        html.classList.add('nav-open');

        this.sidebarVisible = true;
    };
    sidebarClose() {
        const html = document.getElementsByTagName('html')[0];
        this.toggleButton.classList.remove('toggled');
        this.sidebarVisible = false;
        html.classList.remove('nav-open');
    };
    sidebarToggle() {
        // const toggleButton = this.toggleButton;
        // const body = document.getElementsByTagName('body')[0];
        if (this.sidebarVisible === false) {
            this.sidebarOpen();
        } else {
            this.sidebarClose();
        }
    };
    isHome() {
        var titlee = this.location.prepareExternalUrl(this.location.path());

        if( titlee === '/home' ) {
            return true;
        }
        else {
            return false;
        }
    }
    isDocumentation() {
        var titlee = this.location.prepareExternalUrl(this.location.path());
        if( titlee === '/documentation' ) {
            return true;
        }
        else {
            return false;
        }
    }

    getBasketCount():Observable<number>
    {
        return this.basketService.itemCount$;
    }

    isLoggedIn():boolean
    {
         return this.securityService.isloggedIn();
    }

    getUserName():string
    {
        return this.securityService.getClaims()?.name;
    }

    showAdminMenu():boolean
    {
        let flag=false;
        switch(this.securityService.userRole)
        {
            case 'admin':
            case 'appadmin':
            case 'appuser':
                            flag=true;
                            break;

        }
        return flag;
    }

    onLogout() {
        this.securityService.startSignoutMainWindow();
    }

    onManageUsers()
    {
         this.securityService.manageUsersUri()
         .subscribe(data => window.location.href = data,
            error=>this.error.handleErrorWithAlert(error));
    }

    onMyAccount()
    {
        this.securityService.myAccountUri()
        .subscribe(data=>window.location.href = data,
             error=>this.error.handleErrorWithAlert(error));

    }

    onDownloadImages()
    {
        if (this.downloadImageCount<=0) {
            const modalRef = this.modalService.open(ModalPopupComponent);
            modalRef.componentInstance.title = "No image found";
            modalRef.componentInstance.content = "There are no images in your download list.";
            return
        }
        this.imageService.downloadImages();
    }

    getImageListCount():Observable<number>
    {
       return this.imageService.getImageListCount();
    }

    onImageListClear()
    {
        this.wishlist.removeAllWishlistItems();
        this.imageService.clear();
        this.wishlist.removeAllWishlistItems();
    }
    onViewList(){
        this.dialog.open(AlertComponent,{data:{item: this.likedProducts,type:'wish_list'}});
    }

}
