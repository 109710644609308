import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { LocaleFormat } from '../../model/locale-format';
import { IConfigurations } from '../models/settings.model';
import { select, Store } from '@ngrx/store';

@Injectable()
export class LocaleService {

  tenantLocale!: any[];
  code!: string;
  cultureCode!: string;
  tenantList!: any[];

  localeFormat: LocaleFormat = {
    cultureCode: '',
    currencyCode: '',
    symbol: '',
    dateFormat: 'mediumDate',
    dateInputFormat: '',
    numberFormat: '',
  };
  localeConfig: any;

  private defaultLanguage: string = 'en';
  private availablelangs = [
    { code: 'en', text: 'English' },
    { code: 'us', text: 'US-English' },
    { code: 'au', text: 'AU-English' },
    { code: 'eu', text: 'EU-English' }
  ];

  constructor(private http: HttpClient, public translate: TranslateService,
    private store:Store<IConfigurations>) {

  }
  loadTenantLocale() {
    let tenantLocaleFile = `/assets/config/${environment.envName}/tenant-locale.json`;

    return this.getJSON(tenantLocaleFile).then(data => {
      this.tenantLocale = data;

      if (this.tenantLocale != null) {
        const host: string = window.location.protocol + '//'
          + window.location.hostname
          + (window.location.port ? ':'
            + window.location.port : '');
        // const host: string ='https://b2b-aus-testsite.azurewebsites.net' ;
        // const host: string ='https://b2b-us-testsite.azurewebsites.net' ;
        let locale = this.tenantLocale.filter(x => x.host == host)[0];
        this.code = locale.code;
        this.localeFormat.cultureCode = locale.cultureCode;
        this.localeFormat.currencyCode = locale.currencyCode;
        this.localeFormat.numberFormat = locale.numberFormat;
        this.localeFormat.dateFormat = locale.dateFormat;
        this.localeFormat.dateInputFormat = locale.dateInputFormat;
        this.localeFormat.symbol = locale.symbol;
        //let language = this.cultureCode.split('-')[0]; 
        this.useLanguage(this.code);
        this.loadLocaleConfig(this.code);
      }
      return true;
    });
  }

  loadLocaleConfig(localeCode: any) {
    let appConfigFile = `/assets/config/${environment.envName}/${localeCode}.json`;
    this.getJSONFile(appConfigFile).then(data => {
      this.localeConfig = data;
    });
  }
  getTenantList() {
    let tenantFile = `/assets/config/${environment.envName}/tenant.json`;
    return this.getJSONFile(tenantFile).then(data => {
      this.tenantList = data;
      return true;
    })
  }

  setCultureCode(cultureCode: string) {
    if (cultureCode != null && cultureCode != '' && cultureCode !== 'undefined') {
      this.cultureCode = cultureCode;
      let language = this.cultureCode.split('-')[0];

      this.useLanguage(language);
    }
  }

  setCurrencyCode(currencyCode: string) {
    if (currencyCode != null
      && currencyCode != ''
      && currencyCode !== 'undefined') {
      this.localeFormat.currencyCode = currencyCode;
    }

  }

  setNumberFormat(numberFormat: string) {
    if (numberFormat != null
      && numberFormat != ''
      && numberFormat !== 'undefined') {
      this.localeFormat.numberFormat = numberFormat;
    }
  }

  useLanguage(lang: string = "") {
    this.translate.use(lang || this.translate.getDefaultLang());
  }

  private getJSON(url: string): Promise<any> {
    return this.http.get(url, { responseType: 'json' })
      .toPromise()
      .then(response => response)
      .catch((error: any) => console.log(error));
  }
  private getJSONFile(url: string): Promise<any> {
    return this.http.get(url, { responseType: 'json' })
      .toPromise()
      .then(response => response)
      .catch((error: any) => console.log(error));

  }

  GetConfigFromStore():IConfigurations
  {
    let aConfig:IConfigurations = {} as IConfigurations;
    this.store.select(c => c).subscribe((config:any) => {
      aConfig = config.configurations;      
    });
    return aConfig;
  }

}
