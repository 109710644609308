import { Directive, ElementRef, OnInit } from '@angular/core';

@Directive({
  selector: '[appSelectOnClick]'
})
export class SelectOnClickDirective implements OnInit {

  constructor(public elementRef:ElementRef) { }
  ngOnInit(){
    //window.Selection
  }
}
