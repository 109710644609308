import { Component, OnInit } from '@angular/core';
import { LocaleService } from 'src/app/core/services/locale.service';
import { IConfigurations } from '../core/models/settings.model';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit {
  public selectedIndex=0;
  public localeCode!:string;
  configurations:IConfigurations = {} as IConfigurations;

  constructor(public localeService:LocaleService ) {
    this.configurations = this.localeService.GetConfigFromStore();
   }

  ngOnInit() {
    this.localeCode=this.localeService.code;
  }
  changeIndex(number:number){
    this.selectedIndex=number;
  }

}
