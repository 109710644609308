<div [formGroup]="myform" class="row">
        <mat-form-field>
                <mat-label>{{('retailer.NAME'|translate)}}</mat-label>
                <input matInput [formControl]="ReturnFormControl('name')">
                <mat-error> You must include {{('retailer.NAME'|translate)}}.</mat-error>
        </mat-form-field>    
    <!-- <div class="col-sm-4">
        <div class="form-group">
            <label>Name *</label>
            <div>
                <input class="form-control" type="text" [formControl]="myform.controls['name']" />
                <span *ngIf="myform.controls['name'].hasError('required')
                                      && myform.controls['name'].touched"
                    class="text-danger">
                    You must include Name.
                </span>
            </div>
        </div>

    </div> -->
    <mat-form-field>
            <mat-label>{{('retailer.PHONE'|translate)}}</mat-label>
            <input matInput [formControl]="ReturnFormControl('phone')">
            <mat-error> You must include {{('retailer.PHONE'|translate)}}.</mat-error>
    </mat-form-field>
    <!-- <div class="col-sm-4">
        <div class="form-group">
            <label>Phone *</label>
            <div>
                <input class="form-control" type="text" [formControl]="myform.controls['phone']" />
                <span *ngIf="myform.controls['phone'].hasError('required')
                          && myform.controls['phone'].touched"
                    class="text-danger">
                    You must include phone.
                </span>
            </div>
        </div>
    </div> -->
    <mat-form-field>
            <mat-label>{{('retailer.COMPANYNAME'|translate)}}</mat-label>
            <input matInput [formControl]="ReturnFormControl('companyName')">
            <mat-error *ngIf="ReturnFormControl('companyName').hasError('required')
            && ReturnFormControl('companyName').touched"> You must include {{('retailer.COMPANYNAME'|translate)}}</mat-error>
    </mat-form-field>
    <mat-form-field>
            <mat-label>{{('retailer.MAIL'|translate)}}</mat-label>
            <input matInput [formControl]="ReturnFormControl('email')">
            <mat-error *ngIf="ReturnFormControl('email').hasError('required')
            && ReturnFormControl('email').touched"> You must include {{('retailer.MAIL'|translate)}}.</mat-error>
            <mat-error *ngIf="ReturnFormControl('email').hasError('pattern')">Enter a Valid {{('retailer.MAIL'|translate)}}.</mat-error>
    </mat-form-field>
    <!-- <div class="col-sm-4">
        <div class="form-group">
            <label>Email *</label>
            <div>
                <input class="form-control" type="text" [formControl]="myform.controls['email']" />
                <span *ngIf="myform.controls['email'].hasError('required')
                                && myform.controls['email'].touched"
                    class="text-danger">
                    You must include Email.
                </span>
                <span *ngIf="myform.controls['email'].hasError('pattern')"
                    class="text-danger">
                    Enter a Valid Email.
                </span>
            </div>
        </div>
    </div> -->
    <app-validation-summary [form]="myform"></app-validation-summary>
</div>



