
import { ActionReducerMap } from '@ngrx/store';
import { IConfigurations } from '../core/models/settings.model';
import { Reducers } from './red.reducer';


export const rootReducer = {};

export interface AppState {
    configurations: IConfigurations;
};


export const reducers: ActionReducerMap<AppState, any> = {
    configurations: Reducers
};