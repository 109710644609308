import { Action } from '@ngrx/store';
import { IConfigurations } from '../core/models/settings.model';


export const GetConfigurationsConst = '[Configuration] Get Configurations';

export class GetConfigurations implements Action{
    readonly type= GetConfigurationsConst;
    constructor(public configuration: IConfigurations){}    
}

export type ActionsUnion = GetConfigurations;


