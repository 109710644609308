import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { DefaultLayoutComponent } from './layout/default-layout.component';
import { FooterComponent } from './layout/footer/footer.component';
import { AccessComponent } from './access/access.component';
import { NavbarComponent } from './layout/navbar/navbar.component';
import { LoadIndicatorComponent } from './layout/load-indicator/load-indicator.component';
import { RetailerComponent } from './access/retailer/retailer.component';
import { AddressComponent } from './access/retailer/address.component';
import { BusinessContactInfoComponent } from './access/retailer/business-contact-info.component';
import { BusinessCreditInfoComponent } from './access/retailer/business-credit-info.component';
import { BusinessRefComponent } from './access/retailer/business-ref.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms/';
import { RouterModule } from '@angular/router';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { ENavbarComponent } from './layout/e-navbar/e-navbar.component';
import { CustomerInfoComponent } from './layout/customer-info/customer-info.component';
import { BuyerAccountReceivableContactInfoComponent } from './access/retailer/buyer-account-receivable-contact-info.component';
import { BusinessAddressComponent } from './access/retailer/business-address.component';
import { MaterialModule } from './material.module';
import { BlockUIModule } from 'ng-block-ui';
import { OwlModule } from 'ngx-owl-carousel';
import { SvgIconComponent } from './utils/svg-icon/svg-icon.component';
import { LayoutOppBuyComponent } from './layout-opp-buy/layout-opp-buy.component';
import { HelpComponent } from './help/help.component';
import { NewsletterComponent } from './newsletter/newsletter.component';
import { VirtualshowroomModule } from './virtualshowroom/virtualshowroom.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { StoreModule } from '@ngrx/store';
import { reducers } from './ngrx';
import { ToastrModule } from 'ngx-toastr';
import { NotificationsnackbarComponent } from './shared/component/notificationsnackbar/notificationsnackbar.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { TradeShowComponent } from './tradeshow/tradeshow.component';


// https://github.com/ocombe/ng2-translate/issues/218
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    DefaultLayoutComponent,
    FooterComponent,
    AccessComponent,
    NavbarComponent,
    LoadIndicatorComponent,
    RetailerComponent,
    AddressComponent,
    BusinessContactInfoComponent,
    BusinessCreditInfoComponent,
    BusinessRefComponent,
    ENavbarComponent,
    CustomerInfoComponent,
    BuyerAccountReceivableContactInfoComponent,
    BusinessAddressComponent,
    SvgIconComponent,
    LayoutOppBuyComponent,
    HelpComponent,
    NewsletterComponent,
    TradeShowComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MaterialModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    CoreModule,
    // OppBuyModule,
    AppRoutingModule,
    SharedModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    BlockUIModule.forRoot(),
    OwlModule,
    VirtualshowroomModule,
    FlexLayoutModule,
    StoreModule.forRoot(
      reducers,
      {
        runtimeChecks: {
          strictStateImmutability: false,
          strictActionImmutability: false,
        },
      }
    ),
    ToastrModule.forRoot({
      toastComponent:NotificationsnackbarComponent
    }),
    CarouselModule,
  ],
  providers: [
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
