import { NgModule, APP_INITIALIZER, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApiConfigService } from './services/api-config.service';
import { throwIfAlreadyLoaded } from './module-import-guard';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NotificationService } from './services/notification.service';
import { GlobalNoteService } from './services/global-note.service';
import { TranslateModule } from '@ngx-translate/core';
import { LocaleService } from './services/locale.service';
import { SecurityService } from './services/security.service';
import { BasketService } from './services/basket.service';
import { ErrorService } from './services/error.service';
import { OrderService } from './services/order.service';
import { ImageDownloadService } from './services/image-download.service';
import { ToastrModule } from 'ngx-toastr';
import { ShoppingCartService } from './services/shopping-cart.service';
import { WishlistService } from './services/wishlist.service';
import { OffersService } from './services/offers.service';
import { ModalPopupComponent } from 'src/app/shared/component/modal-popup/modal-popup.component';
import { LoaderserviceService } from './services/loaderservice.service';
import { HttpinterceptorserviceService } from './services/httpinterceptorservice.service';

export function load_apiConfig(apiConfigService: ApiConfigService) {
  return () => apiConfigService.loadConfig();
}
export function load_locale(localeService: LocaleService) {
  return () => localeService.loadTenantLocale();
}
export function load_tenants(localeService:LocaleService){
  return () => localeService.getTenantList();
}

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    TranslateModule.forRoot(),
    ToastrModule.forRoot({closeButton:true,positionClass:'toast-top-left'})
  ],
  declarations: [],
  providers: [
    NotificationService,
    GlobalNoteService,
    LocaleService,
    SecurityService,
    BasketService,
    ErrorService,
    OrderService,
    ImageDownloadService,
    LocaleService,
    OffersService,
    ApiConfigService,        
    {
      provide: APP_INITIALIZER,
      useFactory: load_apiConfig,
      deps: [ApiConfigService],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: load_locale,
      deps: [LocaleService],
      multi: true
    },
    {
      provide:APP_INITIALIZER,
      useFactory:load_tenants,
      deps:[LocaleService],
      multi:true
    },
    {
      provide:HTTP_INTERCEPTORS,
      useClass:HttpinterceptorserviceService,
      multi:true
    },
    ShoppingCartService,
    WishlistService,
    LoaderserviceService
  ],
  entryComponents:[ModalPopupComponent]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}

