
  <div class="modal-header justify-content-center">
      <button type="button" class="close" (click)="onCancel()">
          <i class="now-ui-icons ui-1_simple-remove"></i>
      </button>
      <h4 class="title title-up">{{title}}</h4>
  </div>
  <div class="modal-body" *ngIf="deliveryLocation">
      <p>Reference Number:  <span class="orange-txt">{{orderRefNo}}</span></p>
      <p>Delivery Location: {{deliveryLocation.name}}</p>
      <p>Comments: {{Comments}}</p>
  </div>
  <div class="modal-body" id="modal-body">

      <p>{{content}}</p>
  </div>
  <div class="modal-body" *ngIf="showOfferNote==true">
    <mat-accordion>
        <!-- <mat-expansion-panel (opened)="panelOpenState = true"
                       (closed)="panelOpenState = false"> -->
        <mat-expansion-panel>
            <mat-expansion-panel-header>
            <mat-panel-title>
                <span class="orange-txt">View Offer Notes</span>
            </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-list role="list" *ngIf="localeService.localeConfig && localeService.localeConfig.Offers && localeService.localeConfig.Offers.TermsConditions">
                <mat-list-item style="height: auto;" role="listitem">{{localeService.localeConfig.Offers.TermsConditions.line_1}}</mat-list-item>
                <mat-list-item style="height: auto;" role="listitem">{{localeService.localeConfig.Offers.TermsConditions.line_2}}</mat-list-item>
                <mat-list-item style="height: auto;" role="listitem">{{localeService.localeConfig.Offers.TermsConditions.line_3}}</mat-list-item>
                <mat-list-item style="height: auto;" role="listitem">{{localeService.localeConfig.Offers.TermsConditions.line_4}}</mat-list-item>
                <mat-list-item style="height: auto;" role="listitem">{{localeService.localeConfig.Offers.TermsConditions.line_5}}</mat-list-item>
                <mat-list-item style="height: auto;" role="listitem">{{localeService.localeConfig.Offers.TermsConditions.line_6}}</mat-list-item>
            </mat-list>
        </mat-expansion-panel>
    </mat-accordion>
  </div>
  <div class="modal-footer">
      <button type="button" class="btn btn-default" [ngClass]="{'mat-raised-button mat-primary': okButton}" (click)="onConfirm()">
          <span *ngIf="okButton">{{okButton}}</span><span *ngIf="!okButton">Okay</span>
             <!-- Okay -->
            </button>
      <button type="button" class="btn btn-danger" (click)="onCancel()">
          <span *ngIf="cancelButton">{{cancelButton}}</span><span *ngIf="!cancelButton">Cancel</span> 
          <!-- Cancel -->
        </button>
  </div>