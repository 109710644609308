import { Component, AfterViewInit  } from '@angular/core';

import { Router, NavigationStart, RoutesRecognized, NavigationEnd, NavigationCancel } from '@angular/router';

import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-load-indicator',
  templateUrl: './load-indicator.component.html',
  styleUrls: ['./load-indicator.component.scss']
})
export class LoadIndicatorComponent implements AfterViewInit  {
loading$!:Observable<boolean>;
  constructor(private router:Router) { }

  ngAfterViewInit() {
    this.loading$=this.router.events.pipe(map(event=>{

      let flag=false;
      if(event instanceof NavigationStart|| event instanceof RoutesRecognized) {
        flag = true;
    }
    else if (
        event instanceof NavigationEnd || 
        event instanceof NavigationCancel
        ) {
        flag=false;
    }   
    return flag;
    
  }
    ));
  }

}
