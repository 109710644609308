import { Injectable } from '@angular/core';
import { Notification } from '../../model/notification';
import { BehaviorSubject, Observable } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotificationsnackbarComponent } from 'src/app/shared/component/notificationsnackbar/notificationsnackbar.component';
import { ToastrService } from 'ngx-toastr';



@Injectable()
export class NotificationService {
  private notificationSubject = new BehaviorSubject<Notification[]>([]);
  notifications$: Observable<Notification[]> = this.notificationSubject.asObservable();

  toastbuttons:any=[{
      id:1,
      name:"Ok Got It"
  }]
  constructor(private snackBar: MatSnackBar,
    private toastr:ToastrService) {      
  }

  error(title:string,message:string) {      
    setTimeout(() => {this.clearAll()}, 2000);
      let notification:Notification={
        id:'', 
        title:title,
        message:message,
         type:"danger",
         icon:"objects_support-17",
     }
      let exists=this.notificationSubject.getValue().filter(x=>x.message=notification.message);
      if(exists!=null && exists.length>0)
      {
          return;
      }
      this.notificationSubject.next(this.notificationSubject.getValue().concat(notification));
      
  }

  success(title:string,message:string) { 
    setTimeout(() => {this.clearAll()}, 2000);     
    let notification:Notification={
        id:'', 
       title:title,
       message:message,
        type:"success",
        icon:"ui-2_like",
    };
    this.notificationSubject.next(this.notificationSubject.getValue().concat(notification));
}

warning(title:string,message:string) { 
    setTimeout(() => {this.clearAll()}, 2000);     
    let notification:Notification={
        id:'', 
       title:title,
       message:message,
        type:"warning",
        icon:"ui-1_bell-53",
    };
    this.notificationSubject.next(this.notificationSubject.getValue().concat(notification));
}

info(title:string,message:string) {
    setTimeout(() => {this.clearAll()}, 2000);      
    let notification:Notification={
        id:'', 
       title:title,
       message:message,
        type:"info",
        icon:"travel_info",
    };
    this.notificationSubject.next(this.notificationSubject.getValue().concat(notification));
}

close(notification:Notification) {     
    let notifications=this.notificationSubject.getValue();
    let index= notifications.indexOf(notification)  
    notifications.splice(index,1);      
}

clearAll()
{
    this.notificationSubject.next([]);   
}

openToastr(title:string,message:string,type:string)
{
    // let Data = {title:title, message:message};
    // this.snackBar.open(content,"Ok Got It",{verticalPosition:"bottom",horizontalPosition:"right",panelClass:["mysnackbar"]});
    // this.snackBar.openFromComponent(NotificationsnackbarComponent, {
    //     data: Data,
    //     panelClass: ["mysnackbar"],
    //     verticalPosition:"bottom",
    //     horizontalPosition:"right"
    //   });
    // this.toastr.show(message,title,{positionClass: 'toast-bottom-right',toastClass:"ngx-toastr"});
    if(type == "customer")
    {
        this.toastr.show(message,title,{
            positionClass:"toast-top-right",toastClass:"ngx-toastr",messageClass:"toast-message"},type);  
    }
    else{
        this.toastr.show(message,title,{
            positionClass:"toast-top-right",disableTimeOut:true,toastClass:"ngx-toastr",messageClass:"toast-message",tapToDismiss:false,
        //@ts-ignore
        buttons:this.toastbuttons},type);  
    }  
}


}
