<div *ngIf="(globalNotes$|async) as notifications"  class="fixed-top global-note-position">
    <div *ngFor="let notification of notifications">
      <ngb-alert [type]="notification.type"  [dismissible]="true" >
          <div class="container">
              <div class="alert-wrapper">
                  <button type="button" name="button" class="close" (click)="close(notification)">
                    <span aria-hidden="true">
                        <i class="now-ui-icons ui-1_simple-remove"></i>
                    </span>
                  </button>
                  <div class="message">
                      <ng-container *ngIf="notification.icon">
                        <div class="alert-icon">
                            <i class="now-ui-icons {{notification.icon}}"></i>
                        </div>
                      </ng-container>
                      <strong>{{notification.title}} </strong>{{ notification.message }}
                  </div>
              </div>
          </div>
        </ngb-alert>
        </div>
  </div>  
  
  