import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl } from '@angular/forms';
import { CatalogService } from 'src/app/catalog/services/catalog.service';
import { catchError, debounceTime } from 'rxjs/operators';
import { of } from 'rxjs';
import { ErrorService } from 'src/app/core/services/error.service';
import { BasketService } from 'src/app/core/services/basket.service';

export interface IexpressOrderItem {
  no: string;
  name: string,
  description: string,
  quantity: number
}

@Component({
  selector: 'app-fast-order',
  templateUrl: './fast-order.component.html',
  styleUrls: ['./fast-order.component.scss']
})
export class FastOrderComponent implements OnInit {
  form!: FormGroup;

  public products: any = [
    { no: 100, name: 'product 1', description: "", quantity: 0 },
    { no: 200, name: 'product 2', description: "", quantity: 0 },
    { no: 300, name: 'product 3', description: "", quantity: 0 },
    { no: 400, name: 'product 4', description: "", quantity: 0 },
    { no: 400, name: 'product 4', description: "", quantity: 0 }
  ];
  public searchTerm: string = '';
  public productQty!: number;

  public expressOrderItem:IexpressOrderItem = {} as IexpressOrderItem;

    constructor(private formBuilder: FormBuilder,
  public catalogService: CatalogService, private error: ErrorService,
  public basketService: BasketService) {

}

ngOnInit() {
  this.searcProducts();
  this.form = this.formBuilder.group({
    products: new FormArray([])
  });
  this.addCheckboxes();

}
  private addCheckboxes() {
  this.products.map((o:any, i:number) => {
    const control = new FormControl(i === 0); // if first item set to true, else false
    let productForm = new FormGroup({
      sku: new FormControl(''),
      quantity: new FormControl(''),
    });
    (this.form.controls.products as FormArray).push(control);
  });
}
makeOrders(){
  let selectedProducts = this.form.value.products
    .map((v:any, i:number) => v ? this.products[i] : null)
    .filter((v:any) => v !== null);

  //this.basketService.addItems(selectedProducts,'Ts00009');
}

searcProducts(sku?:any){
  let params: any = {
    page: 1,
    pageSize: 5,
    categorySelected: "",
    searchTerm: this.searchTerm,
    sortOrder: "sku"
  };
  if (sku != '') {
    this.catalogService.getProducts(params)
      .pipe(
        debounceTime(500),
        catchError(error => {
          this.error.handleErrorWithAlert(error);
          return of({ totalCount: 0, products: [] });
        })
      ).subscribe(data => {
        data;
        this.products = data.products;
        // this.products.push(data.products);
      });
  }

}

recieveQty($Event:any, i:number){
  this.productQty = $Event;
  i;
  this.products.
    debugger

}

}
