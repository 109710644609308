import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { ApiConfigService } from './api-config.service';
import { SecurityService } from './security.service';
import { ShoppingCartItem, AddItemOnEditOrder, DeleteItemOnEditOrder, Item } from 'src/app/model/item';
import { ShippingAddress } from 'src/app/basket/model/shippingAddress';

@Injectable()
export class ShoppingCartService {

  constructor(private http: HttpClient,
    private apiSvc: ApiConfigService,
    private securityService: SecurityService) 
    { 
    }

    addShoppingCartItem(shoppingCartItem:ShoppingCartItem,customerCode='',custPriceGroup='')
    {
      let url = this.apiSvc.formatUrl(this.apiSvc.apiConfig.shoppingCart.shoppingCartCreateApi);
      return this.http.post<any>(url, JSON.stringify(shoppingCartItem) ,{
        headers: this.getHeader(customerCode,custPriceGroup)
      });
  
    }

    addShoppingCartItems(shoppingCartItems:ShoppingCartItem[] | Partial<Item>[],customerCode='',custPriceGroup='')
    {
      let url = this.apiSvc.formatUrl(this.apiSvc.apiConfig.shoppingCart.shoppingCartCreteItemsApi);
      return this.http.post<any>(url, JSON.stringify(shoppingCartItems) ,{
        headers: this.getHeader(customerCode,custPriceGroup)
      });
  
    }

    deleteShoppingCartItem(itemNo:any,customerCode='',custPriceGroup='')
    {
      let url = this.apiSvc.formatUrl(this.apiSvc.apiConfig.shoppingCart.shoppingCartItemDeleteApi);
      return this.http.delete<any>(`${url}/${itemNo}`,{
        headers: this.getHeader(customerCode,custPriceGroup)
      });  
    }

    deleteShoppingCart(customerCode='',custPriceGroup='')
    {
      let url = this.apiSvc.formatUrl(this.apiSvc.apiConfig.shoppingCart.shoppingCartDeleteApi);
      return this.http.delete<any>(url,{
        headers: this.getHeader(customerCode,custPriceGroup)
      });  
    }

    getShoppingCartItem(customerCode='',custPriceGroup='') {
      let url = this.apiSvc.formatUrl(this.apiSvc.apiConfig.shoppingCart.shoppingCartGetApi);
        return this.http.get<any>(url, {
          headers: this.getHeader(customerCode,custPriceGroup)
        });
     
    }

    addItemOnOrderEdit(item:AddItemOnEditOrder,customerCode='',custPriceGroup='')
    {
      let url = this.apiSvc.formatUrl(this.apiSvc.apiConfig.order.addItemOnOrderEdit);
      return this.http.post<any>(url, JSON.stringify(item) ,{
        headers: this.getHeader(customerCode,custPriceGroup)
      });  
    }
    
    deleteItemOnOrderEdit(item:DeleteItemOnEditOrder,customerCode='',custPriceGroup=''){
      let options= {
        headers: this.getHeader(customerCode,custPriceGroup),
        body: JSON.stringify(item)
      };
      let url = this.apiSvc.formatUrl(this.apiSvc.apiConfig.order.deleteItemOnOrderEdit);
      return this.http.delete<any>(url,options);
    }

    private getHeader(customerCode='',custPriceGroup=''): HttpHeaders {
      return this.securityService.formatRequestHeader(customerCode,custPriceGroup);
  
    }
    addNewAddress(address:ShippingAddress,customerCode='',custPriceGroup='')
    {
      let url = this.apiSvc.formatUrl(this.apiSvc.apiConfig.customer.newShippingAddressApi);
      return this.http.post<any>(url, JSON.stringify(address) ,{
        headers: this.getHeader(customerCode,custPriceGroup)
      });
  
    }
    changeSubscriberStatus(status:boolean,customerCode='',custPriceGroup=''){
      let url=this.apiSvc.formatUrl(this.apiSvc.apiConfig.customer.subscribe);
      return this.http.post<any>(url,{status:status},{
        headers:this.getHeader(customerCode,custPriceGroup)
      });

    }

}
