import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-showroom',
  templateUrl: './showroom.component.html',
  styleUrls: ['./showroom.component.scss']
})
export class ShowroomComponent implements OnInit,AfterViewInit {
  @ViewChild('showroomPanel',{static:false}) panel!:ElementRef;
  @ViewChild('showroomPanelContainer',{static:false}) panelContainer!:ElementRef;
  constructor(public renderer:Renderer2) { }

  ngOnInit() {
    
  }
  ngAfterViewInit(){
    document.body.requestFullscreen();
  }
  btnFullScreen(){
    document.body.requestFullscreen();
    this.renderer['addClass'](this.panel.nativeElement,'panel-container');
  }
  btnExitFullScreen(){
    document.exitFullscreen();
    this.renderer['removeClass'](this.panel.nativeElement,'panel-container');
  }
}
