import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { ApiConfigService } from '../../core/services/api-config.service';

import { SecurityService } from '../../core/services/security.service';
import { Observable } from 'rxjs';
import { BasketService } from 'src/app/core/services/basket.service';

@Injectable()
export class MorderService {

  constructor(private http: HttpClient,
    private apiSvc: ApiConfigService,
    private securityService: SecurityService,
    public basketService:BasketService) { }

  getOrders(params: any): Observable<any> {
    let url = this.apiSvc.formatUrl(this.apiSvc.apiConfig.order.orderApi);
    let httpParam: HttpParams = new HttpParams()
      .set('pageNumber', params.page)
      .set('pageSize', params.pageSize)
      .set('filterBy', params.filterBy)
      .set('filter', params.filter)
      .set('myOrder', params.myOrder)
      .set('approved',params.approved);
    return this.http.get<any>(url, {
      headers: this.getHeader(),
      params: httpParam
    });
  }

  getMyLatest(params:any):Observable<any>{
    let url = this.apiSvc.formatUrl(this.apiSvc.apiConfig.order.mylatest!);
    let httpParam: HttpParams = new HttpParams()
      .set('pageNumber', params.page)
      .set('pageSize', params.pageSize)
      .set('filterBy', params.filterBy)
      .set('filter', params.filter)
      .set('myOrder', params.myOrder)
      .set('approved',params.approved);
    return this.http.get<any>(url, {
      headers: this.getHeader(),
      params: httpParam
    });
  }

  getUnApprovedOrders(params:any):Observable<any>{
    let url = this.apiSvc.formatUrl(this.apiSvc.apiConfig.order.myunapproved!);
    let httpParam: HttpParams = new HttpParams()
      .set('pageNumber', params.page)
      .set('pageSize', params.pageSize)
      .set('filterBy', params.filterBy)
      .set('filter', params.filter)
      .set('myOrder', params.myOrder)
      .set('approved',params.approved);
    return this.http.get<any>(url, {
      headers: this.getHeader(),
      params: httpParam
    });
  }

  sendEmail(params:any){
    let x=this.securityService.getClaims();
    let url = this.apiSvc.formatUrl(this.apiSvc.apiConfig.order.email!);
    let httpParam: HttpParams = new HttpParams()
      .set('defaultUser', x.email)
      .set('emails', params.emailIds)
      debugger
    return this.http.get<any>(url, {
      headers: this.getHeader(),
      params: httpParam
    });
  }

  editOrder(id: string,customerCode='') {
    let url = this.apiSvc.formatUrl(this.apiSvc.apiConfig.order.orderEditApi.replace('$ID', id));
    return this.http.get<any>(url, {
      headers: this.getHeader(customerCode)
    });
  }

  getOrder(orderNo: string,customerCode='') {
    let url = this.apiSvc.formatUrl(this.apiSvc.apiConfig.order.orderGetApi.replace('$ID', orderNo));

    return this.http.get<any>(url, {
      headers: this.getHeader(customerCode)
    });
  }

  deleteOrder(orderNo:string,customerCode=''){
    let url=  this.apiSvc.formatUrl(this.apiSvc.apiConfig.order.orderDeleteApi!.replace('$ID', orderNo));

    return this.http.delete<any>(url,{headers:this.getHeader(customerCode)});
  }

  getOrderRequested(orderNo: string,customerCode='') {
    let url = this.apiSvc.formatUrl(this.apiSvc.apiConfig.order.orderRequestedApi.replace('$ID', orderNo));

    return this.http.get<any>(url, {
      headers: this.getHeader(customerCode)
    });
  }

  getProductsFromOrder(id: string,customerCode='') {
    let url = this.apiSvc.formatUrl(this.apiSvc.apiConfig.order.productsFromOrderApi.replace('$ID', id));
    return this.http.get<any>(url, {
      headers: this.getHeader(customerCode)
    });

  }

  private getHeader(customerCode=''): HttpHeaders {
    let customer;
    if (this.basketService.getCustomerCode()!='') {
      customer=this.basketService.getCustomerCode();
    }
    else{
      customer=this.securityService.getClaims()?.name;
    }
    //return this.securityService.formatRequestHeader(customerCode);
    return this.securityService.formatRequestHeader(customer,this.basketService.getPriceGroup());
  }

}
