import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-customer-info',
  templateUrl: './customer-info.component.html',
  styleUrls: ['./customer-info.component.scss']
})
export class CustomerInfoComponent implements OnInit {
@Input() customerInfo:any;

  constructor() { }

  ngOnInit() {
  }

}
