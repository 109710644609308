import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalPopupComponent } from 'src/app/shared/component/modal-popup/modal-popup.component';

@Injectable()
export class ErrorService {

 
  constructor(
    private router: Router, 
    private modalService:NgbModal) {
  }

  public handleErrorWithAlert(err: any) {
    let errors = this.handleError(err);
    errors.forEach(element => {
      const modalRef = this.modalService.open(ModalPopupComponent);
        modalRef.componentInstance.title = `Alert`;
        modalRef.componentInstance.content =element;
        if (element=='Customer code is required.') {
          this.router.navigate(['/customer/selectcustomer']);
        }
        //this.notification.error('Error',element)
    });

  }
 

  private handleError(err: HttpErrorResponse): string[] {
    let error: string[] = [];
    if (err.status == 0) {
      error.push("net::ERR_CONNECTION_REFUSED");
    }
    else if (err.status == 422 && err.error != null) {
      error.push(`${err.error.message}`); //${err.status}-${err.error.message}

      err.error.errors.forEach((element:any) => {
        error.push(`${element.message}`) //${element.field}-${element.message}
      });     
    }
    else if (err.status == 401) {
      error.push('Auth expired please logout and login again');      
      //this.securityService.startSignoutMainWindow();                     
    }
    else if (err.status == 400 || err.status == 403) {
      error.push(`${err.error.error.message}`); //${err.status}-${err.error.error.message}

    }    
    else {     
      error.push(`${err.statusText}`);      
    }    
    return error;
  }


}
