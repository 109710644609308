import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderserviceService {
  private loadingBehaviorSubject = new BehaviorSubject<boolean>(false);
  isLoading: Observable<boolean> = this.loadingBehaviorSubject.asObservable();
  constructor() { }
  setState(state: any) {
    this.loadingBehaviorSubject.next(state)
  }
}
