import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';


@Component({
  selector: 'app-business-address',
  templateUrl: './business-address.component.html'
})
export class BusinessAddressComponent implements OnInit {
  @Input() myform!: FormGroup;
  public countries:any;
  constructor() { }

  ngOnInit() {
    this.countries= this.countries;
  }

  ReturnFormControl(pControl:string)
  {
    return (<FormControl>(this.myform).controls[pControl]);
  }

  ReturnMyFormWithControl(pControl:string) :FormGroup
  {
    return <FormGroup>this.myform.controls[pControl];
  }
}
