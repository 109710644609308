import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { IConfigurations } from 'src/app/core/models/settings.model';
import { LocaleService } from 'src/app/core/services/locale.service';

@Component({
  selector: 'app-business-contact-info',
  templateUrl: './business-contact-info.component.html'
})
export class BusinessContactInfoComponent implements OnInit {
  @Input() myform!: FormGroup;
  @Input() localeCode!:String;

  configurations:IConfigurations = {} as IConfigurations;

  constructor(private localeService:LocaleService) {
    this.configurations = this.localeService.GetConfigFromStore();
  }

  ngOnInit() {
  }

  ReturnFormControl(pControl:string)
  {
    return (<FormControl>(this.myform).controls[pControl]);
  }

  ReturnFormControlWithTwoControl(pControl1:string,pControl2:string)
  {
    return (<FormControl>(<FormGroup>this.myform.controls[pControl1]).controls[pControl2]);
  }

  ReturnFormGroupWithControl(pControl:string)
  {
    return <FormGroup>this.myform.controls[pControl];
  }
}
