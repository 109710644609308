import { Component, OnInit } from '@angular/core';
import { GlobalNoteService } from '../../../core/services/global-note.service';

import {Notification} from "../../../model/notification"
import { Observable } from 'rxjs';

@Component({
  selector: 'app-global-note',
  templateUrl: './global-note.component.html',
  styleUrls: ['./global-note.component.scss']
})
export class GlobalNoteComponent implements OnInit {

  globalNotes$!: Observable<Notification[]>;

  constructor(private globalNoteService:GlobalNoteService) {

  }

  ngOnInit() {        
       
        this.globalNotes$ = this.globalNoteService.globalNotes$;
        
  }

    close(globalNote:Notification) {
     
      this.globalNoteService.close(globalNote);
    }

}
