import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Product } from 'src/app/catalog/model/product';
import { ImageConfig } from '../../../model/api-config';
import { CatalogService } from 'src/app/catalog/services/catalog.service';
import { LocaleService } from 'src/app/core/services/locale.service';
import { Router } from '@angular/router';
import { BasketService } from 'src/app/core/services/basket.service';
import { OfferItem } from 'src/app/offers/model/offer-item-model';
import { ShippingAddress } from 'src/app/basket/model/shippingAddress';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CustomerService } from 'src/app/customer/services/customer.service';
import { MyOrder } from 'src/app/home/model/my-order';
import { IConfigurations } from 'src/app/core/models/settings.model';
export enum EType {
  'product_list',
  'order',
  'order_success',
  'view_offers',
  'import_Items'
}

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {
  public order!: any;
  private product!: Product;
  public imageConfig!: ImageConfig;
  imageUrl!: string;
  private offers!: OfferItem[];
  format!: any;
  myThumbnail!: any;
  myFullresImage!: any;
  importedItems: any[] = [];
  public leadList!: any[];
  public leadListTemp!: any[];
  public custPriceGroupList!: any[];
  isChecked: boolean = false;
  localeCode!: string;
  selectedLead!: string;
  selectedPriceGroup!: string;
  leadFilterString: string = "";

  //-- New shipping address
  shippingAddress!: ShippingAddress;
  shipToCode!: string;
  addressForm!: any;
  //-- /End
  configurations:IConfigurations = {} as IConfigurations;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialog, public catalogService: CatalogService,
    public locale: LocaleService, public router: Router, public basketService: BasketService,
    private dialogRef: MatDialogRef<AlertComponent>, public snackbar: MatSnackBar,
    private customerService: CustomerService) {
      
      this.configurations = this.locale.GetConfigFromStore();
     }

  ngOnInit() {
    this.imageConfig = this.catalogService.getImageConfig();
    this.format = this.locale.localeFormat;
    this.localeCode = this.locale.code;
    this.myThumbnail = "https://wittlock.github.io/ngx-image-zoom/assets/thumb.jpg";
    this.myFullresImage = "https://wittlock.github.io/ngx-image-zoom/assets/fullres.jpg";
    let customerCode = "";
    if (this.data.customerCode) {
      customerCode = this.data.customerCode
    }
    //-- New shipping address
    this.addressForm = new FormGroup({
      address: new FormControl('', [Validators.required]),
      address2: new FormControl(''),
      city: new FormControl('', [Validators.required]),
      code: new FormControl(''),
      contact: new FormControl('', [Validators.required]),
      countryRegionCode: new FormControl(''),
      county: new FormControl('', [Validators.required]),
      customerNo: new FormControl(),  //customerCode,[Validators.required]
      eMail: new FormControl('', [Validators.pattern('^[a-z0-9]+(\.[_a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,15})$')]),
      fax_No: new FormControl('', [Validators.pattern(/^-?(0|[1-9]\d*)?$/)]),
      name: new FormControl('', [Validators.required]),
      name2: new FormControl(''),
      phoneNo: new FormControl('', [Validators.pattern(/^-?(0|[1-9]\d*)?$/)]),//Validators.pattern('[0-9]')
      post_Code: new FormControl('', [Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)]),
      comments: new FormControl(''),
      selected: new FormControl(true)
    })
    //--
    switch (this.data.type) {
      case EType[0]:
        this.imageConfig = this.catalogService.getImageConfig();
        this.imageUrl = this.imageConfig.highResUri + this.data.item.no + '.jpg';
        break;
      case EType[1]:
        this.order = this.data.order;
        break;
      case EType[2]:
        this.order = this.data.order;
        break;
      case EType[3]:
        this.offers = this.data.applicableOffers
        break;
      case EType[4]:
        this.importedItems = this.data.items;
        break;
      default:
        break;
    }

    //-- Select Lead
    if (this.data.leadSelection !== undefined && this.data.leadSelection === true) {
      this.customerService.getLeadList().subscribe(data => {
        this.leadList = data;
        this.leadListTemp = data;
      })
    }
    //--

    //-- Select customer price group
    if (this.data.priceGroupSelection !== undefined && this.data.priceGroupSelection === true) {
      this.customerService.getpriceGroup().subscribe(data => {
        this.custPriceGroupList = data;
        //this.leadListTemp=data;
      });
    }
    //--

  }
  selectAllItems() {
    this.importedItems = this.importedItems.map((item) => {
      this.isChecked ? item.allowBackOrder = true : item.allowBackOrder = false
      return item;
    });
  }
  closeDialog() {
    // if (this.importedItems.length>0) {
    //   this.dialogRef.close(`${this.importedItems}`);
    // }
    if (this.data.type == 'import_Items') {
      this.dialogRef.close({ data: this.importedItems });
    }
    else if (this.data.type == 'leadSelect') {
      if (this.selectedLead === undefined) {
        this.selectedLead = "";
      }
      this.dialogRef.close({ data: this.selectedLead })
    }
    else if (this.data.type == 'priceGroupSelect') {
      if (this.selectedPriceGroup === undefined) {
        this.selectedPriceGroup = "";
      }
      this.dialogRef.close({ data: this.selectedPriceGroup })
    }
    else {
      this.dialogRef.close();
    }
    if (this.data.type == "order_success") {
      this.basketService.clear();
      //this.router.navigateByUrl('/catalog');
    }
  }
  recieveMail() {
    this.closeDialog();
  }
  addAddress() {
    if (this.addressForm.valid) {
      this.basketService.requestNewAddress(this.addressForm.value)
        .subscribe(data => {
          if (!data.status) {
            this.dialogRef.close(this.addressForm.value)
            this.openSnackBar('Your Request has been sent. Wild Republic will update your shipping address shortly', 'Okay')
          }
          else {
            this.openSnackBar('Failed to submit.Please try again.', 'Okay')
          }
        })
    }
  }
  openSnackBar(message: string, action: string) {
    this.snackbar.open(message, action, {
      duration: 200000000,
      panelClass: 'snack-Success'
    });
    this.snackbar._openedSnackBarRef!.afterDismissed().subscribe(data => {
      this.dialogRef.close(this.addressForm.value)
    })
  }

  leadFilter() {
    this.leadList = this.leadListTemp.filter(item => {
      return item.code.toLowerCase().includes(this.leadFilterString.toLowerCase()) || item.description.toLowerCase().includes(this.leadFilterString.toLowerCase());
    });
  }
}
