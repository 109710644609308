<div fxLayout="column">
  <span class="heading-text-20 orange-txt">Trade Shows :-</span>
  <div fxLayout="row wrap">
    <div fxLayout="row" fxFlex="48" fxFlex.lt-md="100" fxLayoutAlign="space-between center" *ngFor="let tradeShow of tradeShowList" class="tradeshow-card">
      <div fxLayout="column" fxLayoutGap="15px">
        <div class="heading-text-20 orange-txt">
          {{tradeShow.showname}}
        </div>
        <div fxLayout="row" fxLayoutGap="15px">
          <div>
            <mat-icon class="mr-3 color-green">arrow_upward</mat-icon>
            <span  class="heading-text-18 blue-txt">{{tradeShow.startdate | date:"dd-MM-yyy"}}</span>
          </div>
          <div>
            <mat-icon class="mr-3 color-red">arrow_downward</mat-icon>
            <span  class="heading-text-18 blue-txt">{{tradeShow.enddate | date:"dd-MM-yyy"}}</span>
          </div>
        </div>
        <div fxLayout="row">
          <mat-icon class="mr-3">location_on</mat-icon>
          <span class="heading-text-18 blue-txt">
            {{tradeShow.location}}
          </span>
        </div>
      </div>
      <div fxLayout="column" fxlayoutGap="10px" class="boothno-card" fxLayoutAlign="center center">
          <span class="heading-text-18 blue-txt">Booth No</span>
          <span class="heading-text-30 orange-txt">
            {{tradeShow.boothno}}
          </span>
      </div>
    </div>
  </div>
</div>

