import { Component } from '@angular/core';
import { TradeshowService } from '../core/services/tradeshow.service';
import { ITradeShow } from '../model/tradeshow';

@Component({
  selector: 'app-tradeshow',
  templateUrl: './tradeshow.component.html',
  styleUrls: ['./tradeshow.component.scss']
})
export class TradeShowComponent {

  tradeShowList:ITradeShow[] = [];

  constructor(private tradeshowService:TradeshowService) {
    this.tradeshowService.tradeShowSchdule().subscribe((schedule:ITradeShow[]) => {
      this.tradeShowList = schedule;
    });
   }
}
