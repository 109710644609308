<nav class="navbar navbar-expand-lg bg-white fixed-top navbar-transparent" color-on-scroll="500">
    <!-- Customer info <div class="col-xs-12">
        <div class="container">
            <div *ngIf="!viewedAs==''; else elseBlock" class="text-right orange-txt">
                <a mat-button [routerLink]="['/customer']" class="text-lowercase">Site Viewed As : <i class="material-icons orange-txt text-lowercase">verified_user</i> {{viewedAs}}</a>
            </div>
        </div>
        <ng-template #elseBlock>
                <div class="text-right orange-txt">
                    <a mat-button [routerLink]="['/customer']" class="text-lowercase">Site Viewed As : <i class="material-icons orange-txt text-lowercase">verified_user</i> {{getUserName()}}</a>
                </div>
        </ng-template>
  </div> -->
    <div class="container">
        <div class="navbar-translate" style="display: flex">
            <a class="navbar-brand" placement="bottom" ngbTooltip="Wild Republic">
                <img class="img-fluid" src="assets/img/logo.png" />
                <!-- <img class="img-max-height" style="max-width: 100px;margin-left: 6px;"
                    src="https://cdn.shopify.com/s/files/1/0115/4891/7819/files/Asset_1.png?15017172963985687810"
                    alt=""> -->
            </a>

            <span class="example-spacer"></span>
            <button class="hidden-lg visible-xs" mat-button color="" [matMenuTriggerFor]="menu"
                aria-label="Example icon-button with a menu">
                <mat-icon class="favorite" matBadge="{{getImageListCount()|async}}" matBadgeSize="large"
                    matBadgePosition="below after" matBadgeColor="warn" color="warn">photo_camera</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="onDownloadImages()">
                    <mat-icon>save_alt</mat-icon>
                    <span>Download</span>
                </button>
                <button mat-menu-item (click)="onImageListClear()">
                    <mat-icon>clear</mat-icon>
                    <span>Clear</span>
                </button>
                <!-- <button mat-menu-item (click)="onViewList()">
                <mat-icon>remove_red_eye</mat-icon>
                <span>View all</span>
            </button> -->
            </mat-menu>
            <a mat-button class="hidden-lg visible-xs" [routerLink]="['/basket']">
                <mat-icon class="favorite" color="warn" matBadge="{{getBasketCount()|async}}" matBadgeSize="large"
                    matBadgePosition="below after" matBadgeColor="warn">shopping_cart</mat-icon>
            </a>

            <button mat-icon-button class="navbar-toggler menu-nav-margin" type="button" data-toggle="collapse"
                data-target="#navbarToggler" aria-controls="navbarTogglerDemo02" aria-expanded="false"
                aria-label="Toggle navigation" (click)="sidebarToggle()">
                <span class="navbar-toggler-bar bar1"></span>
                <span class="navbar-toggler-bar bar2"></span>
                <span class="navbar-toggler-bar bar3"></span>
            </button>
        </div>
        <span *ngIf="showAdminMenu()">Admin menu</span>
        <span class="example-spacer"></span>
        <div class="collapse navbar-collapse" style="display: block !important;"
            data-nav-image="assets/img/blurred-image-1.jpg" data-color="orange">
            <ul class="navbar-nav">
                <li *ngIf="configurations.navBar.showVRRoomGif" class="nav-item">
                    <a class="nav-link" style="padding:0" [routerLink]="['/showroom']">
                        <!-- <i class="now-ui-icons design_app"></i> -->
                        <!-- <p>Virtual Showroom</p> -->
                        <img src="assets/img/vs.gif" alt="">
                    </a>
                </li>
            </ul>
            <ul class="navbar-nav ml-auto">
                <span class="example-spacer"></span>
                <li class="nav-item" *ngIf="configurations.navBar.showSafetyRecall">
                    <a class="nav-link impNotice" href="https://www.wildrepublic.com/important-notice/" target="_blank">
                        <p>Safety Recall</p>
                    </a>
                </li>
                <!-- <li class="nav-item" *ngIf="localeService.code=='au'">
                    <a class="nav-link impNotice" href="http://www.wildrepublic.com.au/competition/" target="_blank">
                        <p>Halloween Competition</p>
                    </a>
                </li>  -->
                <li class="nav-item">
                    <a class="nav-link" [ngClass]="{'active-menu':router.url=='/home'}" 
                        [routerLink]="['/home']">
                        <!-- <i class="now-ui-icons design_app"></i> -->
                        <p>Home</p>
                    </a>
                </li>
                <li class="nav-item" *ngIf="!configurations.navBar.showCustomerWithOptn || (!configurations.showSalesLead && configurations.navBar.showCustomerWithOptn)">
                    <a class="nav-link" [ngClass]="{'active-menu':router.url=='/customer/selectcustomer'}" 
                        [routerLink]="['/customer/selectcustomer']">
                        <!-- <i class="now-ui-icons design_app"></i> -->
                        <p>Customers</p>
                    </a>
                </li>
                <a ngbDropdown *ngIf="configurations.navBar.showCustomerWithOptn && configurations.showSalesLead" class="nav-item dropdown">
                    <div [ngClass]="{'active-menu':router.url == '/customer/selectcustomer'}" class="nav-link dropdown-toggle"
                        ngbDropdownToggle>
                        <!-- <i class="now-ui-icons files_paper" aria-hidden="true"></i> -->
                        <p>Customer</p>
                    </div>
                    <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right">
                        <a class="dropdown-item" [routerLink]="['/customer/selectcustomer']">
                            <i class="now-ui-icons users_single-02"></i>Select Customer </a>
                        <a class="dropdown-item" (click)="onLeadSelection()">
                            <i class="now-ui-icons business_badge"></i>Select Sales Lead</a>
                    </div>
                </a>
                <a ngbDropdown *ngIf="configurations.navBar.showQuickOrder" class="nav-item dropdown">
                    <div [ngClass]="{'active-menu':(router.url == '/quickorder/byentry' || router.url == '/quickorder/byimport')}" class="nav-link dropdown-toggle"
                        ngbDropdownToggle>
                        <!-- <i class="now-ui-icons files_paper" aria-hidden="true"></i> -->
                        <p>Quick Order</p>
                    </div>
                    <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right">
                        <a class="dropdown-item"
                            *ngIf="localeService.localeConfig && localeService.localeConfig.others.showQuickOrder==true"
                            [routerLink]="['/quickorder/byentry']">
                            <i class="now-ui-icons shopping_box"></i>By Entry </a>
                        <a class="dropdown-item" [routerLink]="['/quickorder/byimport']">
                            <i class="now-ui-icons ui-2_settings-90"></i> By Import</a>
                        <a class="dropdown-item" [routerLink]="['/quickorder/byscan']">
                            <i class="now-ui-icons ui-2_settings-90"></i> By Scan / Entry</a>
                    </div>
                </a>
                <li class="nav-item">
                    <a class="nav-link" [ngClass]="{'active-menu':router.url=='/catalog'}" 
                        [routerLink]="['/catalog']">
                        <!-- <i class="now-ui-icons design_app"></i> -->
                        <p *ngIf="localeService.localeConfig">{{localeService.localeConfig.menu.catalog}}</p>
                    </a>
                </li>
                <li class="nav-item" *ngIf="showOffer">
                    <a [ngClass]="{'active-menu':router.url == '/offers'}" class="nav-link" [routerLink]="['/offers']"
                        style="position: relative;">
                        <img src="assets/img/new.png" class="hidden-xs"
                            style="position: absolute;width: 30px;right: 2px;top: -8px;" alt="">
                        <p *ngIf="localeService.localeConfig">{{localeService.localeConfig.menu.offers}}</p>
                    </a>
                </li>
                <a ngbDropdown class="nav-item dropdown">
                    <div [ngClass]="{'active-menu':(router.url == '/manage/order' || router.url == '/manage/globalnote' || router.url == '/manage/invoice')}" class="nav-link dropdown-toggle"
                        ngbDropdownToggle>
                        <!-- <i class="now-ui-icons files_paper" aria-hidden="true"></i> -->
                        <p>Manage</p>
                    </div>
                    <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right">
                        <a class="dropdown-item" [routerLink]="['/manage/order']">
                            <i class="now-ui-icons shopping_box"></i>
                            Orders</a>
                        <a class="dropdown-item" [routerLink]="['/manage/invoice']">
                            <i class="now-ui-icons ui-2_settings-90"></i>Invoice</a>
                        <a class="dropdown-item" [routerLink]="['/manage/globalnote']">
                            <i class="now-ui-icons files_paper"></i>Global Note</a>
                        <a class="dropdown-item" (click)="onRestoreCartClick()">
                            <i class="now-ui-icons shopping_cart-simple"></i>Restore Cart</a>
                    </div>
                </a>
                <a ngbDropdown class="nav-item dropdown hidden-xs visible-lg navbar-right">
                    <div class="nav-link dropdown-toggle text-primary" ngbDropdownToggle>
                        <!-- <i class="now-ui-icons design_image" aria-hidden="true"></i>
                      <p>{{getImageListCount()|async}}</p> -->
                        <mat-icon class="favorite" color="primary" matBadge="{{getImageListCount()|async}}"
                            matBadgeSize="large" matBadgePosition="below after" matBadgeColor="warn">photo_camera
                        </mat-icon>
                    </div>
                    <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right">
                        <a class="dropdown-item" (click)="onDownloadImages()">
                            <i class="fa fa-download"></i>Download</a>
                        <a class="dropdown-item" (click)="onImageListClear()">
                            <i class="fa fa-eraser"></i>Clear</a>
                    </div>
                </a>

                <a ngbDropdown class="nav-item dropdown" *ngIf="isLoggedIn()">
                    <div class="nav-link" ngbDropdownToggle>
                        <!--class="nav-link dropdown-toggle"-->
                        <button mat-icon-button class="hidden-xs visible-lg">
                            <!-- <i class="material-icons favorite">perm_identity</i> -->
                            <i class="material-icons favorite">person_outline</i> <span
                                class="hidden-lg visible-xs">{{getUserName()}}</span>
                            <!-- <i class="now-ui-icons users_single-02" aria-hidden="true"></i> -->
                            <!-- <p>{{getUserName()}}</p>  -->
                        </button>
                        <button mat-button class="user-actions visible-xs hidden-lg pull-left">
                            <mat-icon>person_outline</mat-icon> {{getUserName()}}
                        </button>

                    </div>
                    <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right">
                        <a class="dropdown-item" (click)="onMyAccount()">
                            <i class="now-ui-icons business_badge"></i>My Account</a>
                        <a class="dropdown-item" (click)="onManageUsers()">
                            <i class="fa fa-users"></i>Manage Users</a>
                        <a class="dropdown-item" (click)="onLogout()">
                            <i class="fa fa-sign-out"></i>Log Out</a>
                    </div>
                </a>

            </ul>
        </div>
        <div class="nav-item visible-lg hidden-xs cart-animate">
            <a class="nav-link" [routerLink]="['/basket']">
                <!-- <i class="icon fa fa-shopping-cart fa-2x"></i> -->
                <mat-icon class="favorite" color="primary" matBadge="{{getBasketCount()|async}}" matBadgeSize="large"
                    matBadgePosition="below after" matBadgeColor="warn">shopping_cart</mat-icon>
            </a>
        </div>
    </div>
</nav>