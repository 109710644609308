import { Injectable } from '@angular/core';
import { UserManager, UserManagerSettings, User } from 'oidc-client';
import { ApiConfigService } from './api-config.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import jwt_decode from "jwt-decode";
import { LocaleService } from './locale.service';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';


@Injectable()
export class SecurityService {

  private manager!: UserManager;
  private user: User = {} as User;
  public userRole='';

  public permissions:any;
  public appSettings:any;
  public userPriceGrp!:string;
  public userPaymentTerms!:string;

  public priceGroupSubject:BehaviorSubject<string>=new BehaviorSubject(this.initPriceGroup());
  public priceGroup$:Observable<any>=this.priceGroupSubject.asObservable();

  constructor(private configService: ApiConfigService,
    private http:HttpClient,
    private localeService:LocaleService,public router:Router) {
    this.onInit();
  }

  onInit() {
    let settings: UserManagerSettings = {
      authority: this.configService.apiConfig.security.authority,
      client_id: this.configService.apiConfig.security.client_id,
      redirect_uri: this.configService.apiConfig.security.redirect_uri,
      post_logout_redirect_uri: this.configService.apiConfig.security.post_logout_redirect_uri,
      response_type: 'id_token token',
      scope: 'openid profile email cust_profile b2bapi',
      silent_redirect_uri: this.configService.apiConfig.security.silent_redirect_uri,
      automaticSilentRenew: true,
      //silentRequestTimeout:10000,
      //accessTokenExpiringNotificationTime:120,
      filterProtocolClaims: true,
      loadUserInfo: true,
      acr_values: '',
      //acr_values:'tenant:'+(window.location.pathname.split('/')[1] || '')
      popup_redirect_uri:this.configService.apiConfig.security.popup_redirect_uri
    };
    this.manager = new UserManager(settings);
    this.manager.getUser().then((user:any) => {
    this.user = user;
    if(this.user!=null)
    {
    this.userPriceGrp=this.user.profile.grp_price_code;
    this.permissions=this.user.profile.Permissions.split('},');
    this.userPaymentTerms=this.user.profile.paymentTermsCode      

    let accessToken: any = jwt_decode(this.user.access_token);
    this.userRole=accessToken.role;
    this.localeService.setCurrencyCode(accessToken.curr_code);

    }
    });
    
  }

  getUser()  {
    return this.manager.getUser();
  }

  isloggedIn()
  {   
    if(this.user.expired)
      this.startSignoutMainWindow();

    return this.user != null;
  }

  getClaims(): any {
    return this.user.profile;

  }

  private getAuthorizationHeaderValue(): string {   
    if(this.user!=null)
    { 
    return `${this.user.token_type} ${this.user.access_token}`;
    }
    return '';
  }

  startAuthentication(){
    return this.manager.signinRedirect();
  }
  async startSigninPopup(item?:any):Promise<any>{
    // this.manager.signinPopup().then(function(data){
    //   console.log(data);
    //   this.router.navigate(['/home']);
    // });
    let x=this.manager.signinPopup().catch(error=>{
      console.log('U must enter your user id add item to cart ',error);
    })
    let result=await x;
    let userRes:any= await this.getUser();
    if (this.user===null) {
      this.user = userRes;
      this.userPriceGrp=this.user.profile.grp_price_code;
      this.permissions=this.user.profile.Permissions.split('},');
      this.userPaymentTerms=this.user.profile.paymentTermsCode
      
      if (this.user!=null) {
        let accessToken: any = jwt_decode(this.user.access_token);
        this.userRole=accessToken.role;
        this.localeService.setCurrencyCode(accessToken.curr_code);  
      }
      
    }
    else{
      
    }
    // window.location.href='/index.html'
    return userRes;
  }

  startSignoutMainWindow() {
    this.manager.signoutRedirect().then(function (resp) {
      console.log('signed out');
     /* setTimeout(5000, () => {
        console.log('testing to see if fired...');

      });*/
    }).catch(function (err) {
      console.log(err);
    });
  };

  completeAuthentication(): Promise<void> {
    return this.manager.signinRedirectCallback().then(user => {
      localStorage.setItem('User','user data stored');
      this.user = user;
    });
  }

  manageUsersUri():Observable<string>
    {
       return this.http.get(
            this.configService.apiConfig.security.authority+'/.well-known/openid-configuration')
            .pipe(map(data =>window.location.href = this.configService.apiConfig.security.manageUserURI)); 
    }
  
  changePasswordUri():Observable<any>{
    return this.http.get(
      this.configService.apiConfig.security.authority+'/.well-known/openid-configuration')
      .pipe(map(data =>window.location.href = this.configService.apiConfig.security.changePassword)); 
  }    

  myAccountUri():Observable<string>
    {
      return this.http.get(
        this.configService.apiConfig.security.authority+'/.well-known/openid-configuration')
        .pipe(map(data =>window.location.href = this.configService.apiConfig.security.myAccountURI)); 
       
    }
  
    register() :Observable<string>
    {  
      return this.http.get(
        this.configService.apiConfig.security.authority+'/.well-known/openid-configuration')
        .pipe(map(data => window.location.href = this.configService.apiConfig.security.register_uri));
          
           
    }
    

    formatRequestHeader(customerCode='',custPriceGroup?:string): HttpHeaders {

      if(custPriceGroup===undefined){
        custPriceGroup=this.priceGroupSubject.getValue();
      }

      let authHeader= this.getAuthorizationHeaderValue();
      customerCode=encodeURIComponent(customerCode);
      if (authHeader != null || authHeader != '') {
        const httpHeader = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8')
          .set('Accepts','application/json')
          .set('Authorization', authHeader)
          //.set('Authorization','Bearer eyJhbGciOiJSUzI1NiIsImtpZCI6Ijc5MTkwNzcyNWUyMzViNTdiMWI2ZDQ3NmJlNTcxNGM2IiwidHlwIjoiSldUIn0.eyJuYmYiOjE1ODMyMTMwNTUsImV4cCI6MTU4MzIxNjY1NSwiaXNzIjoiaHR0cDovLzE5Mi4xNjguMTY4LjE3OTo3MjcyIiwiYXVkIjpbImh0dHA6Ly8xOTIuMTY4LjE2OC4xNzk6NzI3Mi9yZXNvdXJjZXMiLCJiMmJhcGkiXSwiY2xpZW50X2lkIjoiaHR0cDovLzE5Mi4xNjguMTY4LjIzMTo2MjAwLyIsInN1YiI6ImFkM2IzZWEzLWQzZmQtNGU0YS1hZDUxLWIxZDUwNTZmZTczYyIsImF1dGhfdGltZSI6MTU4MzIxMzA0MywiaWRwIjoibG9jYWwiLCJQZXJtaXNzaW9ucyI6IltdIiwiZ3JwX3ByaWNlX2NvZGUiOiJaT08tRU4iLCJiMmJDdXN0b21lclR5cGUiOiIiLCJwYXltZW50VGVybXNDb2RlIjoiMDIiLCJyZXNldFBhc3N3b3JkIjoiVHJ1ZSIsInJvbGUiOiJjdXN0b21lcl9hZG1pbiIsInRlbmFudCI6IjEiLCJjdXN0X2NvZGUiOiIxU0EiLCJuYW1lIjoicGFydGhpYmFuIiwiZW1haWwiOlsicGFydGhpYmFuckBrbWl0c29sdXRpb25zLmNvbSIsInBhcnRoaWJhbnJAa21pdHNvbHV0aW9ucy5jb20iXSwiY3Vycl9jb2RlIjoiIiwic2NvcGUiOlsib3BlbmlkIiwicHJvZmlsZSIsImVtYWlsIiwiY3VzdF9wcm9maWxlIiwiYjJiYXBpIl0sImFtciI6WyJwd2QiXX0.XqPdlSytbHX2mSOvFxoJJT7zFxmHhMfcZWsNaHRmbTbhMYpCfn7DXyVrSxoieAwI_0iixuV1iwZWKAwpVCqTjXZZkqXEHLvloz-IDYkvihf0BsDb1CbqAWwU-WW-M3SbHKcEeJoHya3m3pK328ogBBnwSZ_fQcGtF6cJD00r9IhpSDLbOyrPhcf2RQz4pyla5mLFuSQm2q81lwX9B4CxXXbqMM3R7PYhJOO8fYpobuj8xcixpL9z63h-bEUKvQ95TByw8S4jeGG1BtULvnubOayZ-eiZDvAKJg38RAX-yP48qR_xkSOwGBPT9EQHMWjzaBKmj1bUYscusP-eAUFgLQ')
          .set('CUST_CODE', customerCode)
          .set('CUST_PRICE_GROUP',custPriceGroup)
        return httpHeader;
      }
      else {
        
        const httpHeader = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8')
        return httpHeader;
      }
    }
    hasPermission(permissionName:any){
      let retValue=false;
      if (this.permissions) {
        this.permissions.forEach((element:any) => {
          // if (element.includes(permissionName)) {
          if (element.indexOf(permissionName)>=0) {
            retValue=true;
          }
        });  
      }
      return retValue;
    }

    setPriceGroup(priceGroup:string){
      this.priceGroupSubject.next(priceGroup);
    }
    
    getPriceGroup(){
      return this.priceGroupSubject.getValue();
    }

    initPriceGroup(){
      return '';
    }

}

