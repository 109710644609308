<div class="row">
  <div class="container">
    <mat-card class="list">
          <mat-card-header class="no-margin">
              <mat-card-title class="no-margin">
                  <h3 class="no-margin"><b>Help</b></h3></mat-card-title>
            </mat-card-header>
            
            <mat-card-content>
                <p>
                  The customer can visit <a href="https://b2b.wildrepubliccanada.com/">https://b2b.wildrepubliccanada.com/</a> to login to the site and create orders. A landing page as in the below image appears,
                  <img src="/assets/img/1.png" alt="">    
                </p>
            </mat-card-content>
            <mat-card-content>
              <div>
                <h3><b>Order creation</b></h3>
                <p>Once logged in, the customer is taken to the home page in the site where they can place their orders. The orders can be placed using one of the below mentioned 3 methods</p>
                <button mat-button color="warn" (click)="changeIndex(0)"  style="text-decoration: underline;">1. By Catalog</button><br>
                <button *ngIf="configurations.help.showByImport" mat-button color="warn" (click)="changeIndex(1)" style="text-decoration: underline;">2. By Import</button><br>
                <button *ngIf="configurations.help.showByScan" mat-button color="warn" (click)="changeIndex(2)" style="text-decoration: underline;">3. By Scan</button><br>
              </div>
              <div>
                <mat-tab-group selectedIndex="{{selectedIndex}}" class="help-comp">
                  <mat-tab  label="By Catalog">
                    <mat-card class="list">
                      <mat-card-header class="no-margin">
                          <mat-card-title class="no-margin">
                          <h5 class="no-margin"><b>Order creation using catalog</b></h5></mat-card-title>
                      </mat-card-header>
                      <mat-card-content>
                        <h5 class="tab-subheading">Step 1</h5>
                        <p>The product catalog is available at the top of the home page in the navigation bar. By clicking on the “Catalog” option the customer will be able to view all products categorized and listed.</p>
                        <img src="/assets/img/3.png" alt="">
                        <p>Each product in the list has a feature (“add to cart” button) to add the product to cart directly.</p>
                        <img src="/assets/img/4.png" alt="">
                        <p>
                          The buttons in each product are self-descriptive and display an explanation pop-up while hovering on them. This makes the purpose of each button clear. The minimum quantity of each product is auto filled in the quantity box. The item quantity can be changed by the customer using the ‘+’ or ‘-’ button or by entering the quantity in the field directly if required. The entered quantity is then validated and set to correct pack quantity multiple of that item.
                        </p>
                        <img alt="" src="/assets/img/5.png">
                        <p> The customer can browse more products in the catalog page or search for a specific product by entering the SKU/Item# of the product in the product search bar. </p>

                        <h5 class="tab-subheading">Step 2</h5>
                        <p>Once all the required products are added to cart, the total number of items are updated at the cart icon box at the top navigation bar and highlighted as in the below image.
                        </p>
                        <img alt="" src="/assets/img/6.png">
                        <h5 class="tab-subheading">Step3</h5>
                        <p> The cart icon at the navigation bar can be clicked on, to navigate to the cart page. The cart page has the list of all items added to the cart as in the image below,</p>
                        <img src="/assets/img/7.png" alt="">
                        <p> If the customer tries to approve the order when his order value is below the minimum order value, the following pop-up appears and asks him to purchase more items.</p>
                        <img src="/assets/img/8.png" alt="">
                        <h5 class="tab-subheading">Step 4</h5>
                        <p> The customer must select a shipping address from the list displayed below the ordered items in the cart page. Also select a ship date which is three days later than the order creation date. Optionally, reference number, comments can be added for the order. New Shipping address can be added using the button “Add another address”. </p>
                        <img src="/assets/img/9.png" alt="">
                        <p> If the shipping address is not in the list, then it can be added by clicking the add another address button which will open up a window as in the below image</p>
                        <img src="/assets/img/10.png" alt="">
                        <h5 class="tab-subheading">Step 5</h5>
                        <p> After verifying the ordered items the user can opt to either “Save” the order to complete later or “Approve” it to place the order. </p>
                        <img src="/assets/img/11.png" alt="">
                        <p> Save: The order can be saved to edit/approve it later. <br>Approve: The order can be approved right away which sends email notifications to the customer as well as the Wild Republic Sales Department. (Note: Approved order cannot be deleted) </p>
                        <p style="text-align: center;"><img src="/assets/img/catalog.jpg" style="max-width: 700px;" alt=""></p>

                      </mat-card-content>
                    </mat-card>
                  </mat-tab>
                  <mat-tab *ngIf="configurations.help.showByImport" label="By Import">
                    <mat-card class="list">
                      <mat-card-header class="no-margin">
                          <mat-card-title class="no-margin">
                          <h5 class="no-margin"><b>Order creation by importing items</b></h5></mat-card-title>
                      </mat-card-header>
                      <mat-card-content>
                        <p>The “Import” option enables the customer to order items in bulk.</p>
                        <h5 class="tab-subheading"><b>Step 1</b></h5>
                        <p>Navigate to Quick order -> By import</p>
                        <img src="/assets/img/12.png" alt="">
                        <h5 class="tab-subheading"><b>Step 2</b></h5>
                        <p> The import page allows only the .csv file formats. A sample .csv format file can be downloaded by clicking the download icon at the page. </p>
                        <img src="/assets/img/13.png" alt="">
                        <p> After entering the items in the given format, it can be uploaded to the page by clicking on the “Choose File” option as in the above image, </p>
                        <img src="/assets/img/14.png" alt="">
                        <p> The items in the file are populated across the form in the page as in the file imported. The customer can add these items to cart by clicking the “add to basket” button. The uploaded item file imports the items into the page as in the following image, </p>
                        <img src="/assets/img/16.png" alt="">
                        <p> Any errors in the ordered items shows a pop-up warning, allowing the customer to edit or delete the erroneous items. </p>
                        <img src="/assets/img/17.png" alt="">
                        <p> This allows the customer to verify/ edit / remove the items and then clicking the “add to basket” button will add all items to cart, and the page is navigated to the cart. </p>
                        <p style="text-align: center;"><img src="/assets/img/import.jpg" style="max-width: 700px;" alt=""></p>
                      </mat-card-content>
                    </mat-card>

                  </mat-tab>
                  <mat-tab *ngIf="configurations.help.showByScan" label="By Scan">
                    <mat-card class="list">
                      <mat-card-header class="no-margin">
                          <mat-card-title class="no-margin">
                          <h5 class="no-margin"><b>Order creation by scanning the products</b></h5></mat-card-title>
                      </mat-card-header>
                      <mat-card-content>
                        <p> The Scan page allows the customer to create orders by entering the item#/SKU or by scanning the products barcode from the physical catalog or the actual product. </p>
                        <h5 class="tab-subheading"><b>Step 1</b></h5>
                        <p>Navigate to Quick order -> Scan/ Entry </p>
                        <img src="/assets/img/18.png" alt="">
                        <h5 class="tab-subheading"><b>Step 2</b></h5>
                        <p> A page as in the below image appears making the user to be able to scan the items. </p>
                        <img src="/assets/img/20.png" alt="">
                        <p> The products can be scanned continuously. The scanned products are automatically filled in the list below without entering any data. These product details like quantity can also be edited. </p>
                        <img src="/assets/img/21.png" alt="">
                        <p> The “add to basket” button at the end of the page lets the items be added to cart. </p>
                        <p style="text-align: center;"><img src="/assets/img/scan.jpg" style="max-width: 700px;" alt=""></p>

                      </mat-card-content>
                      </mat-card>
                  </mat-tab>
                  <mat-tab *ngIf="configurations.help.showPromotions" label="Promotions">
                    
                      <mat-card class="list">
                        <mat-card-header class="no-margin">
                            <mat-card-title class="no-margin">
                            <h5 class="no-margin"><b>Promotions</b></h5></mat-card-title>
                        </mat-card-header>
                        <mat-card-content>
                          <p> Promotions in B2B are pre-applied when the order is created. The navigation has a link called ‘Offers’ to view all the available promos for the customer. </p>
                          <img src="/assets/img/22.png" alt="">
                          <p> The order value is calculated, and the applicable promo is shown at the top of the cart page. Also, the next redeemable promo and the respective order value criteria is displayed next to the applied promo.</p>
                          <p> Below is an image with promo applied at cart page, </p>
                          <img src="/assets/img/23.png" alt="">
                          <p> The applied promo code is applied to the order when it is saved/approved. The additional purchase value required to avail the next promo is also available. More offers can be viewed by clicking on View more offers. </p>
                        </mat-card-content>
                        </mat-card>
                  </mat-tab>
                  <mat-tab label="Manage order">
                    <mat-card class="list">
                      <mat-card-header class="no-margin">
                          <mat-card-title class="no-margin">
                          <h5 class="no-margin"><b>Managing orders </b></h5></mat-card-title>
                      </mat-card-header>
                      <mat-card-content>
                        <p> The orders created in B2B can be managed in manage->orders page.</p>
                        <img src="/assets/img/24.png" alt="">
                        <p> The page contains the list of all orders as in the below image,</p>
                        <img src="/assets/img/25.png" alt="">
                        <p> The list displays all order related data and provides more options to view / manage each order.</p>
                        <p> Below is the screenshot of an order in view, </p>
                        <img src="/assets/img/26.png" alt="">
                        <p> The “view” option displays all the details of an order. </p>
                        <h5 class="tab-subheading"><b>Print an order</b></h5>
                        <p>Customer can print the order created. The below is a print view of the above order.</p>
                        <img src="/assets/img/27.png" alt="">
                        <h5 class="tab-subheading"><b>Recreating orders</b></h5>
                        <p> Clicking on the ‘More’ icon will display a menu, where there is a repeat order button which will create a new order at the basket by copying the selected previous order.</p>
                        <img src="/assets/img/28.png" alt="">
                        <p> Note: Approved orders cannot be edited or deleted. </p>
                        <p>Sending email notification of the order The menu under the ‘More’ button has an “Email” option which enables the customer to send email notifications to email id’s that the customer wants in the pop-up. The Notification pop-up display is as follows.</p>
                        <img src="/assets/img/29.png" alt="">
                        <h5 class="tab-subheading"><b>Approving a saved order</b></h5>
                        <p>As described earlier an order can be saved or approved. The customer can approve an order that was created earlier by clicking on the “Edit / Approve” button. The “Approve” button first redirects the page to cart where the customer will have more options to customize the order. </p>
                        <img src="/assets/img/30.png" alt="">
                        <p> Once the order is approved or saved a pop-up message will display as in the above image confirming that the order was created. </p>

                      </mat-card-content>
                    </mat-card>  
                  </mat-tab>
                  <mat-tab label="Manage users">
                    <mat-card class="list">
                      <mat-card-header class="no-margin">
                          <mat-card-title class="no-margin">
                          <h5 class="no-margin"><b>User Dashboard</b></h5></mat-card-title>
                      </mat-card-header>
                      <mat-card-content>
                        <img src="/assets/img/31.png" alt="">
                        <p> Hovering on the sidebar of the user dashboard page, expands a menu for various purposes. </p>

                        <div>
                          <mat-tab-group>
                            <mat-tab label="Profile">
                              <mat-card class="list">
                                <mat-card-header class="no-margin">
                                    <mat-card-title class="no-margin">
                                    <h5 class="no-margin"><b>Profile </b></h5></mat-card-title>
                                </mat-card-header>
                                <mat-card-content>
                                  <p> The My Account->profile page looks like the following image and allows the customer to manage his profile data such as email Id and phone number.</p>
                                  <p style="text-align: center;"><img src="/assets/img/33.png" alt=""></p>
                                </mat-card-content>
                              </mat-card>
                            </mat-tab>
                            <mat-tab label="Change password">
                              <mat-card class="list">
                                <mat-card-header class="no-margin">
                                    <mat-card-title class="no-margin">
                                    <h5 class="no-margin"><b>Change password  </b></h5></mat-card-title>
                                </mat-card-header>
                                <mat-card-content>
                                  <p> The user dashboard page provides features to change the created password at My account -> change password page. Below is a screen shot of that page,</p>
                                  <p style="text-align: center;"><img src="/assets/img/34.png" alt=""></p>

                                </mat-card-content>
                              </mat-card>
                            </mat-tab>
                            <mat-tab label="Manage users">
                              <mat-card class="list">
                                <mat-card-header class="no-margin">
                                    <mat-card-title class="no-margin">
                                    <h5 class="no-margin"><b>Manage users  </b></h5></mat-card-title>
                                </mat-card-header>
                                <mat-card-content>
                                  <p>  Manage Users menu has options to view the b2b user list and add a new user. A typical user list looks like the following image under Manage users->List page, </p>
                                  <p style="text-align: center;"><img src="/assets/img/35.png" alt=""></p>                                  
                                </mat-card-content>
                              </mat-card>
                            </mat-tab>
                            <mat-tab label="Add user">
                              <mat-card class="list">
                                <mat-card-header class="no-margin">
                                    <mat-card-title class="no-margin">
                                    <h5 class="no-margin"><b>Add user  </b></h5></mat-card-title>
                                </mat-card-header>
                                <mat-card-content>
                                  <p>  Customer can use “Add user” page to create their own users in the B2B site under their B2B account. The page looks as in the following image,</p>
                                  <p style="text-align: center;"><img src="/assets/img/36.png" alt=""></p>                                  
                                </mat-card-content>
                              </mat-card>
                            </mat-tab>
                          </mat-tab-group>
                        </div>


                      </mat-card-content>
                    </mat-card>  
                  </mat-tab>
                </mat-tab-group>
              </div>
              </mat-card-content>
      </mat-card>
  </div>
</div>