<h3>Express Purchase</h3>
<mat-form-field class="example-full-width">
    <input matInput (input)="searcProducts(21)" [(ngModel)]="searchTerm" placeholder="Enter product name (OR) SKU">
</mat-form-field>

<form [formGroup]="form">
      <mat-list>
          <mat-list-item formArrayName="products" *ngFor="let product of products ; let i = index">
              <mat-checkbox [formControlName]="i">

                <mat-list>
                  <mat-list-item>
                    <!-- <mat-icon mat-list-icon><img src="https://cdn.shopify.com/s/files/1/0115/4891/7819/files/Asset_1.png?15017172963985687810"></mat-icon> -->
                    <h4 mat-line>{{product.description}}</h4>
                    <p mat-line> {{product.no}} </p>
                    <p mat-line>
                    </p>
                  </mat-list-item>
                </mat-list>
              </mat-checkbox>
              <span style="right:0;" >
                  <!-- <app-order-quantity [getOrder]="true" [sku]="product.no" (qtyChange)="recieveQty($event,i)"
                  ></app-order-quantity> -->
                  <input matInput placeholder="Quantity">
              </span>
          </mat-list-item>
      </mat-list>
      <button mat-raised-button (click)="makeOrders()" color="primary">Approve and send</button>
</form>
