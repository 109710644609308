<block-ui>
    <!-- <ng-container *ngIf="!configurations.apiCallNeeded">
        <router-outlet></router-outlet>
    </ng-container> -->

    <!-- <div *ngIf="configurations.apiCallNeeded">
        <ng-container *ngTemplateOutlet="template"></ng-container>
      </div>

      <div *ngIf="!configurations.apiCallNeeded">
        <ng-container *ngTemplateOutlet="template"></ng-container>
      </div>

      <ng-template #template>
        <router-outlet></router-outlet>
      </ng-template> -->

      <div *ngIf="showRouterOutlet">
        <router-outlet></router-outlet>
      </div>
</block-ui>
<!-- Need To Change  -->
