import { Component, Inject, OnInit } from '@angular/core';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-notificationsnackbar',
  templateUrl: './notificationsnackbar.component.html',
  styleUrls: ['./notificationsnackbar.component.scss']
})
export class NotificationsnackbarComponent extends Toast {

  buttons:any;
  constructor(public toastrService: ToastrService,
    public toastPackage: ToastPackage) {
      super(toastrService,toastPackage);
      this.buttons = this.options;
    }

  ngOnInit(): void {
  }

  onClose(toastId:number)
  {
    this.toastrService.clear(toastId);
  }

}
