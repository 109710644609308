import { IConfigurations } from '../core/models/settings.model';
import * as UserActions from './act.action';
const InitialState: IConfigurations = {
  "login": {
    "access": {
      "bgImg": "",
      "showRegisterButton": false,
      "showRetailerButton": false,
      "showHelpButton": false,
      "showWildRepublicComments_AU": false,
      "showFooterBgcolorWhite": false
    }
  },
  "retailer": {
    "businessContactInfo": {
      "showDBAFormField": false,
      "showResaleCertificateFormField": false
    }
  },
  "basket": {
    "showPreSaleItemText": false,
    "showPreOrderItemText": false,
    "showIntroductionDate": false,
    "showETAText": false,
    "showLocalShippingandPromo": false,
    "showDeliveryDateFormField": false,
    "showCancelDeliveryDateFormField":false,
    "showCommentsFormField": false,
    "showAdditionalHandingCheckBox": false,
    "showNewsletterCheckBox": false
  },
  "orderConfirmation": {
    "showOrderConfirmationSVG": false,
    "showLogo": false,
    "orderConfirmationMessage": "",
    "showContactInfo": false,
    "termsandConditionMessage": ""
  },
  "catalog": {
    "showPreOrderSlide": false,
    "enablelistStyleforSideBar": false,
    "preOrderBanners": [],
    "itemsPerPage": 0,
    "itemsPerPageList":[],
    "showNewArrivalCategory":false,
    "showBrandCategory":false,
    "catalogList": {
      "showCartonQTY": false,
      "showPreOrderCondition": false,
      "showPreorderTooltip_ETA":false,
      "showPreSaleCondition": false,
      "showOppBuyText": false,
      "showSellOffImg": false,
      "showAddToCartButtonTooltipMessage": false,
      "showTopSellerImg":false
    }
  },
  "help": {
    "showByImport": false,
    "showByScan": false,
    "showPromotions": false
  },
  "defaultLayout": {
    "showHomeCategoryWithImg": false,
    "showDiscounts": false,
    "showFooterColorBlack": false,
    "showHomeBanner":false,
    "homeBanners":[],
    "deptCodeBasedBanner":[],
    "discountBanner":[]
  },
  "navBar": {
    "showVRRoomGif": false,
    "showSafetyRecall": false,
    "showCustomerWithOptn": false,
    "showQuickOrder": false
  },
  "footer": {
    "showTollFreeandLocalText": false,
    "showRSPBImg": false,
    "showRightReservedtext": false,
    "showBrowserSupportInfoText": false
  },
  "layoutOppBuy": {
    "showHelpButton": false
  },
  "alert": {
    "showETA": false,
    "showCartonQTY": false,
    "showInnerPack": false,
    "orderSuccessMessage": [
      {
        "message": ""
      }
    ]
  },
  "apiCallNeeded": true,
  "showSalesLead": false,
  "showCustomerPriceGroup": false
};

export function Reducers(state: IConfigurations = InitialState, action: UserActions.ActionsUnion) {
  switch (action.type) {
    case UserActions.GetConfigurationsConst:
      state = action.configuration
      return state;
    default:
      return state;
  }
}
